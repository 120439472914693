/* eslint-disable */
import React from "react";

/* MUI */
import { Typography, Grid, Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/* Components */
import Input from "@acromove/components/form/input";
import AuthFormWrapper from "../wrapper";
import Form from "@acromove/components/form";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const ResetForm = (props) => {
	const {submit} = props
	const { t } = useTranslation("reset_forget_page");

	const schema = yup.object({
		password: yup.string().required(t("password.required")),
		confirmPassword: yup
			.string()
			.equalTo(yup.ref("password"), t("confirmPassword.equalTo"))
			.required(t("confirmPassword.required")),
	});

	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			password: "",
			confirmPassword: "",
		},
	});

	const onResetSubmit = () => {
		form.handleSubmit(submit)()
	}

	return (
		<AuthFormWrapper>
			<Form mode="anonymous" form={form} name="register" i18n="home_page">
				<Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
					<Typography fontWeight={500} fontSize={36}>
						{t("reset")}
					</Typography>
					<Typography fontWeight={300} fontSize={14} sx={{ color: "text.secondary" }}>
						{t("type_confirm")}
					</Typography>
				</Box>
				<Grid container gap={4} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
					<Grid item md={12}>
						<Form.Field name="password">
							<Input sx={{ width: "100%", mb: 1 }} size="medium" type="password" />
						</Form.Field>
					</Grid>
					<Grid item md={12}>
						<Form.Field name="confirmPassword">
							<Input sx={{ width: "100%", mb: 1 }} size="medium" type="password" />
						</Form.Field>
					</Grid>

					<Button endIcon={<ArrowForwardIcon />} variant="contained" size="medium" onClick={() => onResetSubmit()}>
						{t("submit_reset")}
					</Button>
				</Grid>
			</Form>
		</AuthFormWrapper>
	);
};

export default ResetForm;
