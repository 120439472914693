import React from "react";

import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import AcroAdminDashboardContainer from "containers/sys_admin/acro-admin-dashboard";

const  AcroAdminDashboardPage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={["acromove_admin", "acromove_admin:owner"]}>
            <AcroAdminDashboardContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default  AcroAdminDashboardPage;