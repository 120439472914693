import React from "react";
import { Checkbox } from "@mui/material";
import typePermissions from "components/content/acro-dashboard/group-types/permissions"
import DataGridIconLink from "@acromove/components/data-grid/cells/datagrid-icon-link";


export const groupTypes = [
	{
		headerName: "group-type",
		field: "name",
		minWidth: 200,
	},
	...typePermissions.map(item => ({
			headerName: item.alias,
			field: item.alias,
			minWidth: 200,
			sortable: false,
			renderCell: (params) => {
				const { row } = params;
				return (
					<Checkbox
						sx={{ cursor: "default" }}
						onClick={() => {}}
						checked={row.permissions_aliases.includes(item.alias)}
						color="success"
					/>
				);
			},
		})
	),
	{
		headerName: "url",
		field: "url",
		sortable: false,
		// flex: 0.5,
		width: 70,
		minWidth: 30,
		align: "right",
		renderCell: DataGridIconLink,
	},
];

export default { groupTypes, typePermissions };
