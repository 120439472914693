import React, { useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import Input from "@acromove/components/form/input";
import Select from "@acromove/components/form/select";
import Checkbox from "@acromove/components/form/checkbox";
import Form from "@acromove/components/form";

/* MUI */
import { Grid, Typography, Box, Alert } from "@mui/material";

/* Utils */
import { useTranslation } from "react-i18next";
import useFormContext from "@acromove/components/form/hooks/use-form-context";
import useFormNotices from "@acromove/hooks/use-form-warnings-parser";

const firefight = ["co2", "water_jets", "dust", "other", "none"];
const security = [
	"private_security",
	"fire_alarm",
	"smoke_sensor",
	"intruder_alarm",
	"pir_sensors",
	"magnetic_locks",
	"other",
];

const GeneralInfo = ({ spaceIndx }) => {
	const { t } = useTranslation("sites_create", { keyPrefix: "sites.spaces[]" });
	const {form} = useFormContext()

	const watchFireEquipmentNone = form.watch(`spaces[${spaceIndx}].firefighting_equipment.none`);
	const disableFirefightOptions = () => {
		if (watchFireEquipmentNone) {
			form.setValue(`spaces[${spaceIndx}].firefighting_equipment.co2`, false);
			form.setValue(`spaces[${spaceIndx}].firefighting_equipment.dust`, false);
			form.setValue(`spaces[${spaceIndx}].firefighting_equipment.water_jets`, false);
			form.setValue(`spaces[${spaceIndx}].firefighting_equipment.other`, false);
		}
	};

	useEffect(() => {
		disableFirefightOptions();
	}, [watchFireEquipmentNone]);

	const notices = useFormNotices(form, {
        t,
		field: `spaces[${spaceIndx}]`,
    })

	return (
		<>
			{notices.cages &&
				<Alert sx={{ mb: 2 }} severity="error">
						{notices.cages.join(" & ")}
				</Alert>
			}
			<Form.Field name={`spaces[${spaceIndx}].name`}>
				<Input size="medium" sx={{ width: "100%" }} />
			</Form.Field>
			<Grid
				container
				gap={1}
				sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", flexWrap: "nowrap", mt: 3 }}
			>
				<Grid gap={1} item md={4} sx={{ display: "flex", flexDirection: "column" }}>
					<Form.Field name={`spaces[${spaceIndx}].type`}>
						<Select
							size="medium"
							sx={{ width: "100%" }}
							options={[
								{ label: t("type.options.open.label"), value: "open" },
								{ label: t("type.options.secure.label"), value: "secure" },
								{ label: t("type.options.cabin.label"), value: "cabin" },
								{ label: t("type.options.rack.label"), value: "rack" },
							]}
						/>
					</Form.Field>
					<Form.Field name={`spaces[${spaceIndx}].type_desc`}>
						<Input type="textarea" size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
				<Grid gap={1} item md={4} sx={{ display: "flex", flexDirection: "column" }}>
					<Form.Field name={`spaces[${spaceIndx}].access_type`}>
						<Select
							size="medium"
							sx={{ width: "100%" }}
							options={[
								{ label: t("access_type.options.stairs.label"), value: "stairs" },
								{ label: t("access_type.options.commercial_elevator.label"), value: "commercial_elevator" },
								{ label: t("access_type.options.escalator.label"), value: "escalator" },
								{ label: t("access_type.options.paved_pathway.label"), value: "paved_pathway" },
								{ label: t("access_type.options.industrial_elevator.label"), value: "industrial_elevator" },
								{ label: t("access_type.options.platform.label"), value: "platform" },
								{ label: t("access_type.options.key.label"), value: "key" },
								{ label: t("access_type.options.lock.label"), value: "lock" },
								{ label: t("access_type.options.numlock.label"), value: "numlock" },
								{ label: t("access_type.options.rf_key.label"), value: "rf_key" },
								{ label: t("access_type.options.open.label"), value: "open" },
								{ label: t("access_type.options.none.label"), value: "none" },
							]}
						/>
					</Form.Field>
					<Form.Field name={`spaces[${spaceIndx}].access_type_desc`}>
						<Input type="textarea" size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
				<Grid gap={1} item md={4} sx={{ display: "flex", flexDirection: "column" }}>
					<Form.Field name={`spaces[${spaceIndx}].location`}>
						<Select
							size="medium"
							sx={{ width: "100%" }}
							options={[
								{ label: t("location.options.parking.label"), value: "parking" },
								{ label: t("location.options.basement.label"), value: "basement" },
								{ label: t("location.options.ground.label"), value: "ground" },
								{ label: t("location.options.floor_x.label"), value: "floor_x" },
								{ label: t("location.options.front_yard.label"), value: "front_yard" },
								{ label: t("location.options.back_yard.label"), value: "back_yard" },
								{ label: t("location.options.balcony.label"), value: "balcony" },
								{ label: t("location.options.roof.label"), value: "roof" },
								{ label: t("location.options.terrace.label"), value: "terrace" },
							]}
						/>
					</Form.Field>
					<Form.Field name={`spaces[${spaceIndx}].location_desc`}>
						<Input type="textarea" size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
			</Grid>
			<Box sx={{ mt: 1 }}>
				<Typography sx={{ fontWeight: 500 }}>{t("security_features.label")}</Typography>
				<Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
					{security.map((item) => (
						<Form.Field name={`spaces[${spaceIndx}].security_features.${item}`}>
							<Checkbox key={item} />
						</Form.Field>
					))}
				</Box>
			</Box>
			<Box sx={{ mt: 1 }}>
				<Typography sx={{ fontWeight: 500 }}>{t("firefighting_equipment.label")}</Typography>
				<Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
					{firefight.map((item) => (
						<Form.Field name={`spaces[${spaceIndx}].firefighting_equipment.${item}`}>
							<Checkbox disabled={watchFireEquipmentNone && item !== "none"} key={item} />
						</Form.Field>
					))}
				</Box>
			</Box>
		</>
	);
};

GeneralInfo.propTypes = {
	spaceIndx: PropTypes.number.isRequired
}

export default GeneralInfo;
