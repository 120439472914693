import React, { createContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

/* Utils */
import { useNavigate, useLocation } from "react-router-dom";
import socket from "@acromove/authentication/socket"

/* Redux */
import { useSelector, useDispatch } from "react-redux";
import { initAuth, logoutUser, setScope } from "../../redux/auth/slice";
import { useLazyLogoutQuery, useLazyMeQuery, useLoginUserMutation, useLazySelectScopeQuery } from "../../redux/auth/api";

export const AuthContext = createContext({
	isAuthenticated: false,
	isInitialized: false,
	user: null,
	platform: "JWT",
	isAllowed: () => false,
	findFiledRule: () => ([]),
	isFieldEditable: () => false,
	isFieldVisible: () => false
});


const sliceIndexes = (s) => {
    const bracketIndx = s.search(/\[\d/);
    if(bracketIndx === -1) {
        return s
    }
    const formatted = s.slice(0,bracketIndx+1) + s.slice(bracketIndx+2);
    return sliceIndexes(formatted)
}

export const AuthProvider = (props) => {
	const { children, services, onAction } = props;
	const state = useSelector((s) => s.auth);
	const dispatch = useDispatch();
	const [fetchMe] = useLazyMeQuery();
	const [fetchLoginUser] = useLoginUserMutation()
	const [fetchLogout] = useLazyLogoutQuery()
	const [fetchSelectScope] = useLazySelectScopeQuery() //eslint-disable-line

	const navigate = useNavigate();
	const location = useLocation()

	const initServices = () => {
		for (const service of services) {
			if (typeof service === "function") {
				service();
			}
		}
	};

	useEffect(() => {
		if(state.isAuthenticated){
			socket.auth = {
				scope: state.scope,
				token: localStorage.getItem("accessToken")
			}
			socket.connect()
		}
	}, [state.isAuthenticated])

	const me = async () => {
		onAction("getMe", "start");
		const meResult = await fetchMe();
		if(meResult.error){
			onAction("getMe", "end:error");
		} else {
			const {data: scopeData, error} = await fetchSelectScope({key: meResult.data.payload.scope});//eslint-disable-line
			if(!error){
				localStorage.setItem("main_group", scopeData.payload._key) //eslint-disable-line
				dispatch(setScope(scopeData.payload))
			}
			dispatch(
				initAuth({
					isAuthenticated: true,
					user: meResult.data.payload,
				})
			);
			initServices();
			onAction("getMe", "end:success");
		}
	}


	useEffect(() => {
		me()
	}, [])

	const login = async (email, password) => {
		const accessToken = await fetchLoginUser({username: email, password});
		if (!accessToken.error){
			localStorage.setItem("accessToken", accessToken.data.payload.token);
			await me()
		}
	};

	const selectScope = async (key) => {
		const {data: scopeData, error} = await fetchSelectScope({key});
		if(!error){
			localStorage.setItem("main_group", scopeData.payload._key) //eslint-disable-line
			await fetchMe()
			const nextPath = location.pathname.split("/")
			window.location.href = `/${nextPath[1]}`
		}
	}

	const logout = async () => {
		fetchLogout()
		navigate("/");
		dispatch(logoutUser());
		localStorage.removeItem("accessToken");
		localStorage.removeItem("main_group");
	};

	const isAllowed = (rule) => {
		if(typeof rule === "string"){
			const [entity, action] = rule.split(":");
			const foundRule = state?.user?.rules.find(r => r.entity === entity && r.action === action)
			return !!foundRule
		}
		if (Array.isArray(rule)) {
			for (const oneRule of rule) {
				const [entity, action] = oneRule.split(":");
				const foundRule = state?.user?.rules.find(r => r.entity === entity && r.action === action)
				if(!foundRule){
					return false
				}
			}
			return true;
		}
		return false
	};

	const findFiledRule = (entity, fieldName, action) => {
		if(action === "anonymous") {
			return ["field_edit", "field_view"]
		}
		const foundRule = state?.user?.rules.find(r => r.entity === entity && r.action === action)
		const formattedFieldName = fieldName.includes("[") ? sliceIndexes(fieldName) : fieldName
		return (foundRule?.fields[formattedFieldName] || [])
	}

	const isFieldEditable = (slug) => {
		const [entity, fieldName] = slug.split(":")
		const rule = findFiledRule(entity, fieldName, "edit")
		if(!rule.length){
			return false
		}
		if(rule.includes("field_edit")){
			return true
		}
		return false
	}

	const isFieldVisible = (slug) => {
		const [entity, fieldName] = slug.split(":")
		const rule = findFiledRule(entity, fieldName, "findOne")
		if(!rule.length){
			return false
		}
		if(rule.includes("field_view")){
			return true
		}
		return false
	}


	return (
		<AuthContext.Provider
			value={useMemo(
				() => ({
					...state,
					platform: "JWT",
					login,
					logout,
					isAllowed,
					findFiledRule,
					selectScope,
					isFieldEditable,
					isFieldVisible
				}),
				[state]
			)}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
	services: PropTypes.arrayOf(PropTypes.func),
	onAction: PropTypes.func,
};

AuthProvider.defaultProps = {
	services: [],
	onAction: () => null,
};

export const AuthConsumer = AuthContext.Consumer;
