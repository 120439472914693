import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "@acromove/utils/helpers";
import qs from "qs";

const clustersAPI = createApi({
	reducerPath: "clusters/api",
	baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("clusters")),
	keepUnusedDataFor: 30,
	tagTypes: ["ClustersFind", "ClustersFindOne"],
	endpoints: (build) => ({
		findClusters: build.query({
			query: ({ limit, offset, sortItem, sortDir, search, filters }) => ({
				url: "find",
				params: qs.stringify({
					limit,
					offset,
					sortItem,
					sortDir,
					search,
					filters,
				}),
			}),
			transformResponse: (response) => ({
				total: response.payload.total,
				items: response.payload.items.map((item) => ({
					...item,
					url: `/clusters/view/${item.id}-${item.label.replace(/ /g, "_")}`,
				})),
			}),
			providesTags: ["ClustersFind"],
		}),
		findOneCluster: build.query({
			query: ({ id }) => ({
				url: `find/${id}`,
			}),
			transformResponse: (response) => response.payload,
			providesTags: ["ClustersFindOne"],
		}),
		createCluster: build.mutation({
			query: (data) => ({
				url: "create",
				method: "POST",
				body: data,
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["ClustersFind"],
		}),
	}),
});

export const {
	useFindClustersQuery,
	useLazyFindClustersQuery,
	useFindOneClusterQuery,
	useLazyFindOneClusterQuery,
	useCreateClusterMutation,
} = clustersAPI;

export default clustersAPI;
