import React from "react";
import PropTypes from "prop-types";
import { Marker } from "react-leaflet";
import PointTooltip from "./point-tooltip";

const SimpleMarker = (props) => {
    const { position, slug, icon, tooltip, disabled, disabledTooltip, onClick} = props;
	if(disabled) return null;
	return (
		<Marker eventHandlers={{click: onClick}} key={`${slug}_${Object.values(position).join()}`} icon={icon} position={position}>
			<PointTooltip items={tooltip} disabled={disabledTooltip}/>
		</Marker>
	);
};

SimpleMarker.propTypes = {
    disabledTooltip: PropTypes.bool.isRequired,
	slug: PropTypes.string.isRequired,
	position: PropTypes.arrayOf(PropTypes.number).isRequired,
	icon: PropTypes.string.isRequired,
	tooltip: PropTypes.shape({
		site: PropTypes.string,
		state: PropTypes.string
	}).isRequired,
	disabled: PropTypes.bool.isRequired,
	onClick: PropTypes.func
}

SimpleMarker.defaultProps = {
	onClick: () => {}
}

export default SimpleMarker
