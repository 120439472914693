import React from "react";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Content Components */
import SitesCreateForm from "components/content/sites/site-create";

/* Utils */
import { useTranslation } from "react-i18next";
import { useApplySiteMutation, useFindOneSiteQuery, useUpdateSiteDraftMutation } from "redux/sites/sites.api";
import { useParams } from "react-router-dom";
import useWithAssetsMutation from "@acromove/hooks/use-with-assets-mutation";

const SitesViewEditContainer = () => {
	const { t } = useTranslation("sites_create");

	const { siteId } = useParams();
	const Id = parseInt(siteId.split("-")[0], 10);
	const { data, isLoading, isFetching, refetch } = useFindOneSiteQuery({ id: Id });

	const [applySite, {  isLoading: isApplyLoading}] = useApplySiteMutation()
	const [updateDraft, {isLoading: isUpdateLoading}] = useWithAssetsMutation(useUpdateSiteDraftMutation, [
		{
			entity: "site",
			path: "images"
		},
		{
			entity: "space",
			path: "spaces[].images"
		},
		{
			entity: "cage",
			path: "spaces[].cages[].images",
		},
		{
			entity: "power_source",
			path: "spaces[].power_sources[].images",
		},
		{
			entity: "network",
			path: "spaces[].networks[].images",
		},
	], {afterAll: refetch})

	return (
		<Container loading={isFetching || isLoading || isFetching} skeleton={["text", "section:2"]}>
			<Container.Header title={t("title_view")} back="/sites"/>
			<SitesCreateForm
				id={Id}
				data={data}
				loading={isUpdateLoading || isApplyLoading}
				applySite={applySite}
				submitSite={updateDraft}/>
		</Container>
	);
};

export default SitesViewEditContainer;
