import React from "react";

/* MUI */
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

/* Assets */
import logo from "assets/images/logo.svg";

const SplashScreen = () => (
    <Box
        sx={{
        alignItems: 'center',
        backgroundColor: 'neutral.900',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 2000
        }}
    >
        <img src={logo} alt="logo"/>
        <CircularProgress />
    </Box>
);


export default SplashScreen;