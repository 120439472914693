import React from "react";
import PropTypes from "prop-types";

/* MUI */
import MUISwitch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

/* Utils */
import useField from "./hooks/use-filed";

/**
 * Switch component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Form Controlled Switch Component.
 */
const Switch = (props) => {
    const {labelPlacement, control, label, disabled, helperText, name: fieldName, ...rest} = props;

    const field = useField({
        fromProps: {
            label,
            name: fieldName,
            disabled,
			helperText,
			control,
        }
    })

    const { onChange, onBlur, name, value, ref } = field.controller.field;
	const { error } = field.controller.fieldState;

    return(
        <FormGroup aria-label="position" row>
            <FormControlLabel
                sx={{
                    "span": {
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                    }
                }}
                checked={value}
                control={<MUISwitch 
                            inputRef={ref} 
                            name={name} 
                            onChange={onChange} 
                            onBlur={onBlur} 
                            disabled={field.disabled} 
                            {...rest}
                        />}
                label={field.label}
                labelPlacement={labelPlacement}
            />
            {error && <FormHelperText>{(error?.message || error?.type)}</FormHelperText>}
            {field.helperText && <FormHelperText>{field.helperTextMsg}</FormHelperText>}
        </FormGroup>
    );
}

/**
 * Switch Properties.
 *
 * @typedef {object} Props
 * @property {string | true | false} [label = "false"]  - Switch Label.
 * @property {object} control - Form control options.
 * @property {string} name - Form Switch Name.
 * @property {"top" | "start" | "end" | "bottom"} [labelPlacement = "start"] - Switchs label placement.
 */

Switch.propTypes = {
    control: PropTypes.shape({}),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string,
    labelPlacement: PropTypes.oneOf([
        "top",
        "start",
        "end",
        "bottom"
    ]),
    disabled: PropTypes.bool,
    helperText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.oneOf([null])]),
}

Switch.defaultProps = {
    control: {},
    name: "",
    labelPlacement: "start",
    label: false,
    helperText: null,
    disabled: false,
}


export default Switch;