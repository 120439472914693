import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";


const useStyles = makeStyles(theme => ({
    tileCtrlGrid: {
      marginBottom: 20,
    },
    tileBtn: {
      zIndex: 2000,
      marginBottom: 1,
      minWidth: 55,
      fontSize: 12,
      maxWidth: 70,
      backgroundColor: theme.palette.text.grayDark,
      '&:hover': {
        backgroundColor: theme.palette.text.grayDark,
      }
    },
    tileBtnSelected: {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      }
    }
}))

const TILES = [
	{
		label: "Roads",
		slug: "simple"
	},
	{
		label: "Topo",
		slug: "topo"
	},
	{
		label: "Satelite",
		slug: "satelite"
	}
];


const TileControls = props => {
	const { onSelect, selected } = props;
	const classes = useStyles()
	const handleSelect = slug => () => onSelect(slug);

	return (
		<Grid container className={classes.tileCtrlGrid} sx={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end"}} gap={1}>
			{TILES.map(tile => (
				<Button
					variant="contained"
					onClick={handleSelect(tile.slug)}
					type="button"
					className={classNames(classes.tileBtn, selected === tile.slug && classes.tileBtnSelected)}
				>
					{tile.label}
				</Button>
			))}
		</Grid>
	);
};

TileControls.propTypes = {
	selected: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired,
}

export default TileControls;
