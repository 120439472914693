import React, {useMemo} from "react";
import PropTypes from "prop-types";

/* Components */
import Carousel from "@acromove/components/carousel";
import ImageAsset from "@acromove/components/assets/image";
import Form from "@acromove/components/form";
import ProtectedComponent from "@acromove/authentication/components/protected/component";
import DragNDropDialog from "@acromove/components/form/drag-n-drop-dialog";

/* MUI */
import { Alert, Box, Grid, IconButton, Tooltip, Typography, Link as MuiLink } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

/* Utils */
import { useTranslation } from "react-i18next";
import helpers from "general/helpers";
import {useWatch} from "react-hook-form";
import { Link } from "react-router-dom";

const filterImages = (entity) => {
	const result = []
	const images = entity.map((item) => item.images)
	for(const image of images) {
		result.push(...image)
	}
	return result
}


const SpaceCard = (props) => {
	const { space, openSpace, spaceIndx, form } = props;
	const { t } = useTranslation("sites_create", { keyPrefix: "sites.spaces[]" });

	const spaceImages = useWatch({control: form.control, name: `spaces[${spaceIndx}].images`});
	const cages = useWatch({control: form.control, name: `spaces[${spaceIndx}].cages`});
	const networks = useWatch({control: form.control, name: `spaces[${spaceIndx}].networks`});
	const powerSources = useWatch({control: form.control, name: `spaces[${spaceIndx}].power_sources`});

	const previewFiles = useMemo(() => [...spaceImages, ...filterImages(cages), ...filterImages(powerSources), ...filterImages(networks)], [spaceImages, cages, networks, powerSources])

	const getKeys = (object, name) => {
		if (object) {
			const stringOfKeys = Object.keys(object)
				.filter((key) => object[key])
				.map((i) => t(`${name}.${i}.label`))
				.join(", ");
			return stringOfKeys;
		}
		return null;
	};

	return (
		<Grid
			container
			gap={1}
			sx={{
				borderRadius: "10px",
				backgroundColor: "background.default",
				width: "100%",
				overflow: "hidden",
				maxHeight: "250px",
				minHeight: "250px",
				flexWrap: "nowrap",
			}}
		>
			{previewFiles && previewFiles.length ? (
				<Grid item md={3} sx={{ overflow: "hidden" }}>
					<Carousel
						items={previewFiles.map((img) => (
							<ImageAsset
								size="small"
								asset={img}
								sx={{ height: "260px", width: "100%", objectFit: "cover" }}
								onDragStart={(e) => e.preventDefault()}
							/>
						))}
						sx={{ minHeight: "260px", height: 260, borderTopRightRadius: 0, p: 0 }}
					/>
				</Grid>
			) : null}
			<Grid
				gap={1}
				item
				md={previewFiles && previewFiles.length ? 7 : 10}
				sx={{ display: "flex", flexDirection: "column", ml: 2, mt: 2 }}
			>
				<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
					<Typography fontWeight={500} fontSize={20} sx={{ color: "text.primary" }}>
						{t("space_card_labels.name")}
					</Typography>
					<Typography fontWeight={400} fontSize={20} sx={{ color: "text.secondary" }}>
						{space?.name || "N/A"}
					</Typography>
				</Box>
				<Grid container sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }} gap={5}>
					<Grid item md={3} gap={2} sx={{ display: "flex", flexDirection: "column" }}>
						<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
							<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
								{t("space_card_labels.location")}
							</Typography>
							<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
								{t(`location.options.${space?.location}.label`) || "N/A"}
							</Typography>
						</Box>
						<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
							<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
								{t("space_card_labels.type")}
							</Typography>
							<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
								{t(`type.options.${space?.type}.label`) || "N/A"}
							</Typography>
						</Box>
						<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
							<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
								{t("space_card_labels.access_type")}
							</Typography>
							<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
								{t(`access_type.options.${space?.access_type}.label`) || "N/A"}
							</Typography>
						</Box>
					</Grid>
					<Grid item md={3} gap={2} sx={{ display: "flex", flexDirection: "column" }}>
						<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
							<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
								{t("space_card_labels.cages")}
							</Typography>
							<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
								{space?.cages.length || "N/A"}
							</Typography>
						</Box>
						<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
							<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
								{t("space_card_labels.power_sources")}
							</Typography>
							<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
								{space?.power_sources.length || "N/A"}
							</Typography>
						</Box>
						<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
							<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
								{t("space_card_labels.networks")}
							</Typography>
							<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
								{space?.networks.length || "N/A"}
							</Typography>
						</Box>
					</Grid>
					<Grid item gap={2} md={6} sx={{ display: "flex", flexDirection: "column" }}>
						<Box gap={1} sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
							<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
								{t("space_card_labels.security_features")}
							</Typography>
							<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
								{space?.security_features ? (
									<Tooltip title={getKeys(space?.security_features, "security_features") || ""}>
										<Box>
											{helpers.truncateString(
												getKeys(space?.security_features, "security_features"),
												30
											)}
										</Box>
									</Tooltip>
								) : (
									"N/A"
								)}
							</Typography>
						</Box>

						<Box gap={1} sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
							<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
								{t("space_card_labels.firefighting_equipment")}
							</Typography>
							<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
								{space?.firefighting_equipment ? (
									<Tooltip title={getKeys(space?.firefighting_equipment, "firefighting_equipment") || ""}>
										<Box>
											{helpers.truncateString(
												getKeys(space?.firefighting_equipment, "firefighting_equipment"),
												30
											)}
										</Box>
									</Tooltip>
								) : (
									"N/A"
								)}
							</Typography>
						</Box>
					</Grid>
				</Grid>
				<Box gap={1} sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
					<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
						Clusters:
					</Typography>
					<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
						{space?.clusters.length
							? space.clusters.map((item) => (
									<MuiLink
										component={Link}
										to={`/clusters/view/${item.id}-${item.label.replace(/ /g, "_")}`}
									>
										{item.label}
									</MuiLink>
							  ))
							: "N/A"}
					</Typography>
				</Box>
			</Grid>
			<Grid
				item
				md={2}
				sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end" }}
			>
				<Box sx={{ display: "flex", flexDirection: "row", justifySelf: "flex-start", mb: "auto" }}>
					<IconButton size="large" color="primary" title="Edit" onClick={() => openSpace(space.uid)}>
						<EditIcon />
					</IconButton>
					<ProtectedComponent slug="sites:import">
						<Form.Field name={`spaces[${spaceIndx}].images`}>
							<DragNDropDialog
								label="images.label"
								fileTypes={["jpg", "png"]}
								multiple
								removedImagesKey={`spaces[${spaceIndx}].$removedImages`}
								iconButton
							/>
						</Form.Field>
					</ProtectedComponent>
					<IconButton size="large" color="error" disabled title="Request Delete">
						<DeleteForever />
					</IconButton>
				</Box>

				<Box sx={{ display: "flex", alignSelf: "flex-end" }}>
					{space?.has_notices?.status === "error" && (
						<Tooltip title={space.has_notices.tooltip}>
							<Alert sx={{ m: 1 }} severity="error">
								<Box>{helpers.truncateString(space.has_notices.tooltip, 30)}</Box>
							</Alert>
						</Tooltip>
					)}
				</Box>
			</Grid>
		</Grid>
	);
};

SpaceCard.propTypes = {
	space: PropTypes.shape({
		name: PropTypes.string,
		location: PropTypes.string,
		type: PropTypes.string,
		access_type: PropTypes.string,
		clusters: PropTypes.arrayOf(PropTypes.shape({})),
		cages: PropTypes.arrayOf(PropTypes.shape({})),
		networks: PropTypes.arrayOf(PropTypes.shape({})),
		power_sources: PropTypes.arrayOf(PropTypes.shape({})),
		security_features: PropTypes.shape({}),
		firefighting_equipment: PropTypes.shape({}),
		uid: PropTypes.string,
		has_notices: PropTypes.shape({
			tooltip: PropTypes.string,
			status: PropTypes.string,
		}),
		images: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
	openSpace: PropTypes.func.isRequired,
	spaceIndx: PropTypes.number.isRequired,
	form: PropTypes.shape({
		setValue: PropTypes.func,
		getValues: PropTypes.func,
		watch: PropTypes.func,
		control: PropTypes.shape({})
	}).isRequired,
};

export default SpaceCard;
