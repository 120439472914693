import React from "react";
import PropTypes from "prop-types";

/* MUI */
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/* Utils */
import sh from "@acromove/utils/styles-helpers";

const NoContent = (props) => {
    const {label, sx, hidden} = props;
    if(hidden) return null;
    return(
        <Box sx={[sh.flex("column", "center", "center"), {width: "100%", height: "100%", p: 1, gap: 1}, sx]}>
            <DoNotDisturbIcon sx={{color: "text.secondary"}}  />
            <Typography color="text.secondary" fontWeight={600} variant="h6">
                {label}
            </Typography>
        </Box>
    );
}

NoContent.propTypes = {
    label: PropTypes.string.isRequired,
	/**
	 * @type {import("@mui/system").SxProps<import("@mui/system").Theme>}
	 */
     sx: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
		PropTypes.func,
		PropTypes.object,
	]),
    hidden: PropTypes.bool
}

NoContent.defaultProps = {
    sx: {},
    hidden: false
}

export default NoContent;