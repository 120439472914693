import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

/* Utils */
import { useTranslation } from "react-i18next";

/**
 * Search component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Search Input.
 */
const Search = (props) => {
	const { onSearch, value, sx } = props;
	const { t } = useTranslation("common");

	const handleChangeSearch = (event) => {
		onSearch(event.target.value)
	};


	return (
		<Box sx={{ minWidth: 120, ...sx }}>
			<OutlinedInput
				size="small"
				type="search"
				value={value}
				onChange={handleChangeSearch}
				placeholder={t("search")}
				sx={{ bgcolor: "background.default", width: "100%" }}
				endAdornment={
					<InputAdornment position="end">
						<SearchIcon />
					</InputAdornment>
				}
			/>
		</Box>
	);
};

/**
 * Search Properties.
 *
 * @typedef {object} Props
 * @property {function} [onSearch = () => null] - Function executed on search.
 */

Search.propTypes = {
	onSearch: PropTypes.func,
	value: PropTypes.string.isRequired,
	sx: PropTypes.shape({})
};

Search.defaultProps = {
	onSearch: () => null,
	sx: {}
};

export default Search;
