import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  scope: null
}

const authSlice = createSlice({
  name: 'acromove/auth',
  initialState,
  reducers: {
    initAuth(state, action) {
      const { isAuthenticated, user } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.isInitialized = true;
      state.user = user;
    },
    logoutUser(state) {
      state.isAuthenticated = false;
      state.user = null;
    },
    setUser(state, action){
      state.user = action.payload
    },
    setScope(state, action){
      state.scope = action.payload
    }
  },
})

export const {
  initAuth,
  logoutUser,
  setUser,
  setScope
} = authSlice.actions

export default authSlice.reducer