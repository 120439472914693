import {useState} from "react";
import useAuth from "../authentication/hooks/use-auth";
import { useLocation } from "react-router-dom";

const useLocalUserSettings = (name, initialValue, options = {}) => {
    const {includeLocation = true} = options;
    const auth = useAuth();
    const location = useLocation()
    
    const getSlug = () => `${auth.user.username}_${name.replaceAll(" ", "_")}${includeLocation ? location.pathname.replaceAll("/", "__") : ""}`
    
    const getInitialValue = () => {
        const val = localStorage.getItem(getSlug());
        if(val){
            return JSON.parse(val).value
        } 
        localStorage.setItem(getSlug(), JSON.stringify({value: initialValue}));
        return initialValue
    }
    
    const [value, setValue] = useState(getInitialValue);
    const set = (newValue) => {
        setValue(newValue)
        localStorage.setItem(getSlug(), JSON.stringify({value: newValue}));
    }
    
    return [value, set]
}

export default useLocalUserSettings;