import React from "react";

import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import AcroAdminGroupTypesContainer from "containers/sys_admin/group-types";

const  AcroAdminGroupTypesPage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={["acromove_admin", "acromove_admin:owner"]}>
            <AcroAdminGroupTypesContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default  AcroAdminGroupTypesPage;