import i18n from "../../i18n";

const addIsSpaceHasNotices = (space) => {
	const notices = {
		general: !![...(space?.notices || [])].length,
		cages: !![...(space?.cages?.map(i => i.notices).flat() || [])].length,
		environmental: !![...(space?.environmental?.notices || [])].length,
		power_sources: !![...(space?.power_sources?.map(i => i.notices).flat() || [])].length,
		networks: !![...(space?.networks?.map(i => i.notices).flat() || [])].length,
	}
	const status = Object.values(notices).find( s => s)
	const hasError = Object.entries(notices).filter((i) => i.at(1)).map(i => i.at(0))
	return {
		...space,
		has_notices: {
			status: status ? "error" : "success",
			tooltip: hasError.map(t => (t === "environmental" || t === "general") ? i18n?.t(`sites_create:sites.spaces[].${t}.tooltip`) : i18n?.t(`sites_create:sites.spaces[].${t}[].tooltip`)).join(", "),
			notices
		}
	}
}

export default {addIsSpaceHasNotices}
