import React from "react";
import constants from "@acromove/constants";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import SitesMyContainer from "containers/sites/admin/my-sites";

const  SitesMySitesPage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={constants.ALL_ROLES}>
            <SitesMyContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default  SitesMySitesPage;