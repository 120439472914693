const findAverageLocation = (points) => points.reduce((acc, d, i, arr) => {
    const current = acc;
    const [lat, lon] = d || [0, 0];
    if (!lat || !lon) {
        if (i === arr.length - 1 && current[2]) {
            return current.map((sum) => sum / (current[2] - 1));
        }
        return current;
    }
    current[0] += lat;
    current[1] += lon;
    current[2] = (current[2] || 1) + 1;
    if (i === arr.length - 1) {
        return current.map((sum) => sum / (current[2] - 1));
    }
    return current;
}, [0, 0])

export default {
	findAverageLocation
}
