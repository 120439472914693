import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import useMediaQuery from '@mui/material/useMediaQuery';

const zones = [
	{ id: 1, name: "Zone 1" },
	{ id: 2, name: "Zone 2" },
	{ id: 3, name: "Zone 3" },
	{ id: 4, name: "Zone 4" },
];

const regionse = [
	{ id: 1, name: "Region 1 long long long long" },
	{ id: 2, name: "Region 2" },
	{ id: 3, name: "Region 3" },
	{ id: 4, name: "Region 4" },
];

const Toolbar = () => {
	const [zone, setZone] = useState(0);
	const [region, setRegione] = useState(0);
	const [search, setSearch] = useState("");
	const {t} = useTranslation("common")
	const isMd = useMediaQuery((theme) => `${theme.breakpoints.down('md')}`);
	const isSm = useMediaQuery((theme) => `${theme.breakpoints.down('sm')}`);

	const handleChangeRegione = (event) => {
		setRegione(event.target.value);
	};

	const handleChangeZone = (event) => {
		setZone(event.target.value);
	};

	const handleChangeSearch = (event) => {
		setSearch(event.target.value);
	};

	return (
		<Grid
			container
			sx={{
				flexDirection: "row",
				justifyContent: "flex-end",
				alignItems: "center",
				gap: 1,
				flexWrap: isSm && "wrap",
				pt: isMd && 1,
				minWidth: isSm ? "100%" : 120
			}}
		>
			<Box sx={{ minWidth: isSm ? "100%" : 120 }}>
				<Select sx={{color: "primary.main", bgcolor: "background.paper"}} fullWidth size="small" value={region} placeholder="Region" onChange={handleChangeRegione}>
					<MenuItem disabled value={0}>
						{t("toolbar.region")}
					</MenuItem>
					{regionse.map((r) => (
						<MenuItem key={r.id} value={r.id}>
							{r.name}
						</MenuItem>
					))}
				</Select>
			</Box>
			<Box sx={{ minWidth: isSm ? "100%" : 120 }}>
				<Select sx={{color: "primary.main", bgcolor: "background.paper"}} size="small" fullWidth value={zone} placeholder="Zone" onChange={handleChangeZone}>
					<MenuItem disabled value={0}>
						{t("toolbar.zone")}
					</MenuItem>
					{zones.map((z) => (
						<MenuItem key={z.id} value={z.id}>
							{z.name}
						</MenuItem>
					))}
				</Select>
			</Box>
			<Box sx={{ minWidth: isSm ? "100%" : 120 }}>
				<OutlinedInput
					size="small"
					type="search"
					value={search}
					onChange={handleChangeSearch}
					placeholder="Search here..."
					sx={{bgcolor: "background.paper", width: "100%"}}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => {}}
								onMouseDown={() => {}}
								edge="end"
							>
								<SearchIcon />
							</IconButton>
						</InputAdornment>
					}
				/>
			</Box>
		</Grid>
	);
};

export default Toolbar;
