import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Tooltip } from "@mui/material";
import ImageAsset from "@acromove/components/assets/image";
import { DeleteForever } from "@mui/icons-material";

const UUIDLENGTH = 37;

const DragDropFilePreview = (props) => {
	const { file, onRemove } = props;
	return (
		<Tooltip title={file.name || file?.filename?.slice(UUIDLENGTH)}>
			<Box
				sx={{
					width: "100px",
					height: "100px",
					position: "relative",
					borderRadius: "8px",
					overflow: "hidden",
					"&:hover": {
						"& div": {
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						},
					},
				}}
			>
				<Box
					sx={{
						zIndex: 9999999999,
						display: "none",
						position: "absolute",
						right: 0,
						top: 0,
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(0,0,0,0.5)",
					}}
				>
					<IconButton
						size="large"
						color="error"
						onClick={() => onRemove(file)}
					>
						<DeleteForever />
					</IconButton>
				</Box>
				<ImageAsset
					asset={file}
					key={file.filename}
					size="thumbnail"
					sx={{ width: "100px", height: "100px" }}
				/>
			</Box>
		</Tooltip>
	);
};

DragDropFilePreview.propTypes = {
	file: PropTypes.shape({
		name: PropTypes.string,
		filename: PropTypes.string,
	}),
	onRemove: PropTypes.func.isRequired,
};

DragDropFilePreview.defaultProps = {
	file: {},
};

export default DragDropFilePreview;
