import SmartRouter from "@acromove/utils/smart-router";

import HomeRoutes from "router/routes/home";
import AuthUserRoutes from "router/routes/auth-user";

import AcroMoveRoutes from "router/routes/acromove";
import ClientRoutes from "router/routes/clients";
import SitesRoutes from "router/routes/sites";
import ClusterRoutes from "router/routes/clusters"
// import CloudServicesRoutes from "router/routes/cloud-services";
// import DashboardRoutes from "router/routes/dashboard";
// import AcroDcimRoutes from "router/routes/acrodcim";
// import BillingRoutes from "router/routes/billing";
// import AcroTraceRoutes from "router/routes/acrotrace"

import PublicLayout from "components/layouts/public";
import Page404 from "pages/404";

const router = new SmartRouter();

router.use(HomeRoutes)
router.use(AuthUserRoutes)
router.use(SitesRoutes)
router.use(ClusterRoutes)
router.use(ClientRoutes)
router.use(AcroMoveRoutes)
// router.use(DashboardRoutes)
// router.use(CloudServicesRoutes)
// router.use(AcroDcimRoutes)
// router.use(AcroTraceRoutes)
// router.use(BillingRoutes)

router.addRoute("/404", {
    title: "404",
    Container: Page404,
    Layout: PublicLayout,
    index: true,
    ignoreOnNav: true,
})

export default {
    navElements: router.getNavElements(),
    routerElements: router.getRouterElements()
}