import React from "react";
import PropTypes from "prop-types";

const HomeLayout = ({children}) => (
    <main>
        {children}
    </main>
)

HomeLayout.propTypes = {
    children: PropTypes.node.isRequired
}

export default HomeLayout;