import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

/* MUI */
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import PanToolIcon from "@mui/icons-material/PanTool";

/* Utils */
import { ChartContext } from "./provider";

/**
 * Chart Toolbar component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Chart Toolbar Component.
 */
const ChartToolbar = (props) => {
	const { tools, sx } = props;
	const { chartRef } = useContext(ChartContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selected, setSelected] = useState([]);

	const detectSelected = () => {
		const active = [];
		if (chartRef && chartRef.current) {
			const toCheck = [
				{ name: "pan", el: chartRef.current.chart.ctx.toolbar.elPan },
				{ name: "zoom", el: chartRef.current.chart.ctx.toolbar.elZoom },
			];
			for (const { el, name } of toCheck) {
				if (el.classList.contains("apexcharts-selected")) {
					active.push(name);
				}
			}
		}
		setSelected(active);
	};

	const handlePan = () => {
		if (chartRef && chartRef.current) {
			const el = chartRef.current.chart.ctx.toolbar.elPan;
			el.click();
			detectSelected();
		}
	};

	const handleReset = () => {
		if (chartRef && chartRef.current) {
			const el = chartRef.current.chart.ctx.toolbar.elZoomReset;
			el.click();
			detectSelected();
		}
	};

	const handleZoom = () => {
		if (chartRef && chartRef.current) {
			const el = chartRef.current.chart.ctx.toolbar.elZoom;
			el.click();
			detectSelected();
		}
	};

	const handleDownload = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "download-chart-popover" : undefined;

	const downloadLabel = ["SVG", "PNG", "CSV"];

	return (
		<Box sx={sx}>
			{tools.includes("pan") && (
				<IconButton onClick={handlePan}>
					<PanToolIcon fontSize="small" color={selected.includes("pan") ? "primary" : "default"} />
				</IconButton>
			)}
			{tools.includes("zoom") && (
				<IconButton onClick={handleZoom}>
					<ZoomInIcon fontSize="small" color={selected.includes("zoom") ? "primary" : "default"} />
				</IconButton>
			)}
			{tools.includes("reset") && (
				<IconButton onClick={handleReset}>
					<RestartAltIcon fontSize="small" />
				</IconButton>
			)}
			{tools.includes("download") && (
				<>
					<IconButton size="small" onClick={handleDownload}>
						<MoreVertIcon fontSize="small" />
					</IconButton>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
					>
						<List dense sx={{ p: 1 }}>
							{(chartRef?.current?.chart?.ctx?.toolbar?.elMenuItems || []).map((el, i) => (
								<ListItem key={el.id} button onClick={() => el.click()}>
									<ListItemIcon>
										<FileDownloadIcon />
									</ListItemIcon>
									<ListItemText primary={`Download ${downloadLabel[i]}`} />
								</ListItem>
							))}
						</List>
					</Popover>
				</>
			)}
		</Box>
	);
};

/**
 * Chart Toolbar Properties.
 *
 * @typedef {object} Props
 * @property {string[]} [tools = ["zoom", "pan", "reset", "download"]] - Tools.
 * @property {function | object | array} [sx = {}] - Prop used to change Chart Toolbar Wrapper CSS properties.
 */


ChartToolbar.propTypes = {
	tools: PropTypes.arrayOf(PropTypes.oneOf(["zoom", "pan", "reset", "download"])),
	/**
	 * @type {import("@mui/system").SxProps<import("@mui/system").Theme>}
	 */
	sx: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
		PropTypes.func,
		PropTypes.object,
	]),
};

ChartToolbar.defaultProps = {
	tools: ["zoom", "pan", "reset", "download"],
	sx: {},
};

export default ChartToolbar;
