import socket from "@acromove/authentication/socket"

const defSetter = (draft, incomingMessage) => ({
	...draft,
	...incomingMessage
})

const wsStreamListener = (eventName, setter = defSetter) => async (
	_,
	{ updateCachedData, cacheDataLoaded, cacheEntryRemoved }
) => {
	try {
	  await cacheDataLoaded
	  socket.on(eventName, (msg) => {
		updateCachedData((draft) => setter(draft, msg))
	  })
	} catch {} //eslint-disable-line
	await cacheEntryRemoved
}

export default wsStreamListener
