import React, { useState } from "react";
import PropTypes from "prop-types";
import useField from "@acromove/components/form//hooks/use-filed";
import DragDrop from "@acromove/components/drag-and-drop";
import { Button, IconButton } from "@mui/material";
import useFormContext from "@acromove/components/form/hooks/use-form-context";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Dialog from "@acromove/components/dialog";

const DragNDropDialog = (props) => {
	const { label, name: fieldName, disabled, control, removedImagesKey, fileTypes, multiple, iconButton } = props;
	const { form } = useFormContext();

	const field = useField({
		fromProps: {
			name: fieldName,
			label,
			disabled,
			control,
		},
	});
	const { value, name } = field.controller.field;
	const { error } = field.controller.fieldState;

	const [importOpen, setImportOpen] = useState(false);
	const [files, setFiles] = useState([]);

	const handleChangeFile = (incomingFiles) => {
		setFiles([...files, ...incomingFiles]);
	};

	const handleRemoveFile = (incomingFile) => {
		if (incomingFile instanceof File) {
			return setFiles([...files.filter((f) => f.name !== incomingFile.name)]);
		}
		form.setValue(name, [...value.filter((f) => f.filename !== incomingFile.filename)]);
		return form.setValue(removedImagesKey, [...(form.getValues(removedImagesKey) || []), incomingFile]);
	};

	const handleCloseImport = async (isAgree) => {
		if (isAgree) {
			const filesToSend = [];
			for (const file of files) {
				filesToSend.push(file);
			}
			console.log(value)
			form.setValue(name, [...value, ...filesToSend], { shouldDirty: true });
		} else {
			form.setValue(name, [...(value || []), ...(form.getValues(removedImagesKey) || [])]);
			form.setValue(removedImagesKey, []);
		}
		setFiles([]);
		setImportOpen(false);
	};

	return (
		<>
			{iconButton ? (
				<IconButton size="large" color="primary" title={field.label || label} onClick={() => setImportOpen(true)}>
					<AddPhotoAlternateIcon />
				</IconButton>
			) : (
				<Button variant="contained" onClick={() => setImportOpen(true)}>
					<AddPhotoAlternateIcon sx={{ mr: 1 }} />
					{field.label || label}
				</Button>
			)}
			<Dialog
				fullWidth
				key="dialog-sites-image"
				maxWidth="sm"
				title={field.label || label}
				open={importOpen}
				onClose={handleCloseImport}
			>
				<DragDrop
					fileTypes={fileTypes}
					onChange={handleChangeFile}
					previewFiles={value}
					files={files}
					multiple={multiple}
					onRemove={handleRemoveFile}
				/>
				{error && error}
			</Dialog>
		</>
	);
};

DragNDropDialog.propTypes = {
	fileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
	multiple: PropTypes.bool,
	removedImagesKey: PropTypes.string,
	control: PropTypes.shape({}),
	name: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	iconButton: PropTypes.bool
};

DragNDropDialog.defaultProps = {
	multiple: false,
	removedImagesKey: "$removedImages",
	name: "",
	control: {},
	label: "",
	disabled: false,
	iconButton: false,
};

export default DragNDropDialog;
