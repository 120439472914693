import React from "react";
import PropTypes from "prop-types";

/* MUI */
import { Box, Alert } from "@mui/material";

/* Components */
import Map from "@acromove/components/map";
import Carousel from "@acromove/components/carousel"
import SiteInfo from "./site-info";
import ImageAsset from "@acromove/components/assets/image";

const SiteMainInfo = (props) => {
	const { form, notices, children } = props;
	const images = form.watch("images");

	const handleDraggableChange = (pos) => {
		form.setValue("latitude", pos?.lat || pos[0], { shouldDirty: true });
		form.setValue("longitude", pos?.lng || pos[1], { shouldDirty: true });
	};

	const handleCountryChange = (country) => {
		if (country) {
			form.setValue("country_id", { id: country.id }, { shouldDirty: true });
			form.setValue("latitude", country?.latitude, { shouldDirty: true });
			form.setValue("longitude", country?.longitude, { shouldDirty: true });
		} else {
			form.setValue("country_id", null);
		}
	};

	const handleCityChange = (city) => {
		if (city) {
			form.setValue("city_id", { id: city.id }, { shouldDirty: true });
			form.setValue("latitude", city?.latitude, { shouldDirty: true });
			form.setValue("longitude", city?.longitude, { shouldDirty: true });
		} else {
			form.setValue("city_id", null);
		}
	};
	return (
		<Box sx={{ width: "100%" }}>
			<Map
				draggable
				form={form}
				draggableDefPos={{
					lat: form.getValues("latitude"),
					lng: form.getValues("longitude"),
				}}
				onDraggableChange={handleDraggableChange}
				onCountryChange={handleCountryChange}
				onCityChange={handleCityChange}
				defCity={form.getValues("city")}
				defCountry={form.getValues("country")}
				notices={
					<>
						{notices?.country_id && (
							<Alert sx={{ width: "100%", my: 1 }} severity="error">
								{notices.country_id.join(" & ")}
							</Alert>
						)}
						{notices?.city_id && (
							<Alert sx={{ width: "100%", my: 1 }} severity="error">
								{notices.city_id.join(" & ")}
							</Alert>
						)}
					</>
				}
			>
				{images?.length ? (
					<Carousel
						items={(images || []).map((img) => (
							<ImageAsset
								size="medium"
								asset={img}
								sx={{ width: "100%", maxHeight: "400px", height: "400px" }}
								onDragStart={(e) => e.preventDefault()}
							/>
						))}
						sx={{ width: "50%", maxHeight: "400px", height: "400px" }}
					/>
				) : null}
			</Map>
			{children}
			<SiteInfo form={form} />
		</Box>
	);
};

SiteMainInfo.propTypes = {
	form: PropTypes.shape({
		setValue: PropTypes.func,
		getValues: PropTypes.func,
		watch: PropTypes.func,
	}).isRequired,
	notices: PropTypes.shape({
		city_id: PropTypes.number,
		country_id: PropTypes.number,
	}),
	children: PropTypes.node,
};

SiteMainInfo.defaultProps = {
	notices: {},
	children: null,
};

export default SiteMainInfo;
