import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

/* Utils */
import { useNavigate } from "react-router-dom";

/* Icons */
import ArrowLeftIcon from "components/icons/arrow-left";

const ContainerHeader = ({ title, children, back }) => {
    const navigate = useNavigate();
	return (
		<Grid
			container
			sx={{ mb: 2}}
			display="flex"
			direction="row"
			justifyContent="space-between"
			alignItems="center"
		>
			<Grid item>
				{back ? (
					<Button startIcon={<ArrowLeftIcon />} onClick={() => typeof back === "function" ? back() : navigate(back)}>
						<Typography fontSize="20px" color="neutral.900" fontWeight="600">
							{title}
						</Typography>
					</Button>
				) : (
					<Typography fontSize="20px" color="neutral.900" fontWeight="600">
						{title}
					</Typography>
				)}
			</Grid>
			<Grid item xs={12} sm={12} md={9} lg={9} sx={{display: "flex", justifyContent: "flex-end"}}>{children}</Grid>
		</Grid>
	);
};

ContainerHeader.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node,
	back: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.func]),
};

ContainerHeader.defaultProps = {
	children: "",
	back: false,
};

export default ContainerHeader;
