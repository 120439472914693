import React from "react";
import PropTypes from "prop-types";

/* Components */
import Form from "@acromove/components/form";
import Input from "@acromove/components/form/input";
import Section from "components/layouts/dashboard/ui/section";
import Select from "@acromove/components/form/select";

/* MUI */
import { Grid, InputAdornment } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";

/* Utils */
import useFormNotices from "@acromove/hooks/use-form-warnings-parser";
import useFormContext from "@acromove/components/form/hooks/use-form-context";
import DragNDropDialog from "@acromove/components/form/drag-n-drop-dialog";

const NetworkItem = (props) => {
    const {t, field, watchLabel, watchBackhaul, spaceIndx, index} = props
    const {form} = useFormContext()
    useFormNotices(form, {
        t,
		field: `spaces[${spaceIndx}].networks[${index}]`,
    })
    return (
        <Section
            key={field.id}
            title={watchLabel(index)}
            collapsible
            action={
                // <IconButton onClick={() => remove(index)}>
                //     <DeleteIcon />
                // </IconButton>
                <Form.Field name={`spaces[${spaceIndx}].networks[${index}].images`}>
					<DragNDropDialog
						fileTypes={["jpg", "png"]}
						multiple
						removedImagesKey={`spaces[${spaceIndx}].networks[${index}].$removedImages`}
						iconButton
					/>
				</Form.Field>
            }
        >
            <Grid
                container
                spacing={1}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    flexWrap: "nowrap",
                    mt: 1,
                }}
            >
                <Grid item md={4}>
                    <Form.Field name={`spaces[${spaceIndx}].networks[${index}].label`}>
                        <Input label={t("label.label")} size="medium" sx={{ width: "100%" }} />
                    </Form.Field>
                </Grid>
                <Grid item md={4} lg={4}>
                    <Form.Field name={`spaces[${spaceIndx}].networks[${index}].backhaul_type`}>
                        <Select
                            label={t("backhaul_type.label")}
                            size="medium"
                            sx={{ width: "100%" }}
                            options={[
                                { label: t("backhaul_type.options.fiber_optic.label"), value: "fiber_optic" },
                                { label: t("backhaul_type.options.satellite.label"), value: "satellite" },
                                { label: t("backhaul_type.options.microwave.label"), value: "microwave" },
                                { label: t("backhaul_type.options.custom.label"), value: "custom" },
                            ]}
                        />
                    </Form.Field>
                </Grid>
                {watchBackhaul(index) === "custom" && (
                    <Grid item md={4}>
                        <Form.Field name={`spaces[${spaceIndx}].networks[${index}].backhaul_custom`}>
                            <Input label={t("backhaul_custom.label")} size="medium" sx={{ width: "100%" }} />
                        </Form.Field>
                    </Grid>
                )}
            </Grid>
            <Grid
                container
                spacing={1}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    flexWrap: "nowrap",
                    mt: 1,
                }}
            >
                <Grid item md={4}>
                    <Form.Field name={`spaces[${spaceIndx}].networks[${index}].bandwidth`}>
                        <Input
                            endAdornment={<InputAdornment position="end">Mbit/s</InputAdornment>}
                            label={t("bandwidth.label")}
                            size="medium"
                            sx={{ width: "100%" }}
                        />
                    </Form.Field>
                </Grid>
                <Grid item md={4}>
                    <Form.Field name={`spaces[${spaceIndx}].networks[${index}].provider`}>
                        <Input label={t("provider.label")} size="medium" sx={{ width: "100%" }} />
                    </Form.Field>
                </Grid>
                <Grid item md={4}>
                    <Form.Field name={`spaces[${spaceIndx}].networks[${index}].coverage_area`}>
                        <Input
                            endAdornment={<InputAdornment position="end">m²</InputAdornment>}
                            label={t("coverage_area.label")}
                            size="medium"
                            sx={{ width: "100%" }}
                        />
                    </Form.Field>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    flexWrap: "nowrap",
                    mt: 1,
                }}
            >
                <Grid item md={4}>
                    <Form.Field name={`spaces[${spaceIndx}].networks[${index}].upload`}>
                        <Input
                            endAdornment={<InputAdornment position="end">Mbit/s</InputAdornment>}
                            label={t("upload.label")}
                            size="medium"
                            sx={{ width: "100%" }}
                        />
                    </Form.Field>
                </Grid>
                <Grid item md={4}>
                    <Form.Field name={`spaces[${spaceIndx}].networks[${index}].download`}>
                        <Input
                            endAdornment={<InputAdornment position="end">Mbit/s</InputAdornment>}
                            label={t("download.label")}
                            size="medium"
                            sx={{ width: "100%" }}
                        />
                    </Form.Field>
                </Grid>
                <Grid item md={4}>
                    <Form.Field name={`spaces[${spaceIndx}].networks[${index}].latency`}>
                        <Input
                            endAdornment={<InputAdornment position="end">ms</InputAdornment>}
                            label={t("latency.label")}
                            size="medium"
                            sx={{ width: "100%" }}
                        />
                    </Form.Field>
                </Grid>
            </Grid>
        </Section>
    )
}

NetworkItem.propTypes = {
	spaceIndx: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    watchLabel: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    field: PropTypes.shape({
		id: PropTypes.number.isRequired

	}).isRequired,
    // remove: PropTypes.func.isRequired,
    watchBackhaul: PropTypes.func.isRequired
}

export default NetworkItem;
