import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* MUI */
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

/* Utils */
import useDebounce from "@acromove/hooks/use-debounce";
import useField from "@acromove/components/form/hooks/use-filed";
import sh from "@acromove/utils/styles-helpers";

const DEF_PAG = {
	limit: 5,
	offset: 0
}

/**
 * Async Autocomplete Component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Async Autocomplete.
 */
const AsyncAutocomplete = (props) => {
	const { label, nameKey, disabled, useLazyLoad, idKey, control = null, name: fieldName, helperText, size, loadArg, sx, onChange: handleChange } = props;

	const field = useField({
		fromProps: {
			label,
			name: fieldName,
			disabled,
			control,
			helperText
		},
	});

	const { onChange, onBlur, name, value, ref } = field.controller.field;
	const { error } = field.controller.fieldState;
	const [search, setSearch] = useState("");
	const [fetchItems, { data, isLoading, isFetching, isSuccess }] = useLazyLoad({refetchOnReconnect: false, refetchOnFocus: false});
	const [options, setOptions] = useState([]);

	const debounced = useDebounce(search);

	const handleChangeSearch = (event) => {
		const val = event.target.value;
		if(val && val !== ""){
			setSearch(val);
		}
	};

	useEffect(() => {
		if(debounced && debounced !== ""){
			fetchItems({ search: debounced, ...loadArg, ...DEF_PAG });
		}
	}, [debounced]);

	useEffect(() => {
		if (isSuccess && data.items) {
			setOptions([...data.items]);
		}
	}, [data, isSuccess]);

	return (
		<Box sx={[sh.flex("row", "flex-start", "flex-start"), { width: "100%", borderRadius: 1 }]}>
			<Autocomplete
				id="autocomp"
				error={error}
				disabled={field.disabled}
				sx={{ width: "100%" }}
				onBlur={onBlur}
				ref={ref}
				onChange={(e, a) => {
					handleChange(a)
					onChange({
						...e,
						target: {
							...e.target,
							value: a,
						},
					});
				}}
				value={value || null}
				name={name}
				getOptionLabel={(option) => (option[nameKey] ? option[nameKey] : "")}
				isOptionEqualToValue={(option, valueOpt) => option[idKey] === valueOpt[idKey]}
				options={value ? [value, ...options] : options}
				loading={isLoading || isFetching}
				loadingText="Loading..."
				clearOnEscape
				renderInput={(params) => (
					<TextField
						{...params}
						sx={{...sx, borderRadius: 1}}
						size={size}
						helperText={field.helperTextMsg}
						onChange={handleChangeSearch}
						label={label || field.label}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{isLoading ? <CircularProgress color="primary" size={20} /> : null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
			/>
			{(field.helperText || helperText) && (
				<IconButton
					sx={{
						mt: 1,
						ml: 1,
					}}
					size={size}
					color="primary"
					onClick={() => field.setShowHelper(!field.showHelper)}
				>
					<InfoOutlinedIcon />
				</IconButton>
			)}
		</Box>
	);
};

/**
 * Async Autocomplete Properties.
 *
 * @typedef {object} Props
 * @property {string} label - Autocomplete label.
 * @property {string} [nameKey = "name"] - name of key of items, that will be displayed in autocomplete.
 * @property {true | false} [disabled = false] - disable/enable prop.
 * @property {func} useLazyLoad - Lazy load function passed to Autocomplete component.
 * @property {object} loadArg - Lazy load argument.
 */

AsyncAutocomplete.propTypes = {
	label: PropTypes.string,
	nameKey: PropTypes.string,
	disabled: PropTypes.bool,
	useLazyLoad: PropTypes.func.isRequired,
	size: PropTypes.string,
	helperText: PropTypes.string,
	name: PropTypes.string,
	control: PropTypes.shape({}),
	idKey: PropTypes.string,
	loadArg: PropTypes.shape({}),
	sx: PropTypes.shape({}),
	onChange: PropTypes.func
};

AsyncAutocomplete.defaultProps = {
	label: "",
	name: "",
	control: {},
	nameKey: "name",
	disabled: false,
	size: "medium",
	helperText: "",
	idKey: "id",
	loadArg: {},
	sx: {},
	onChange: () => {}
};

export default AsyncAutocomplete;
