import React, { useState } from "react";

/* MUI */
import Paper from "@mui/material/Paper";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

/* Components */
import Input from "@acromove/components/form/input";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAuth from "@acromove/authentication/hooks/use-auth";
import { useTranslation } from "react-i18next";

/* Assets */
import qr from "assets/images/qr.png";

/* Styles */
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import AuthPageWrapper from "./wrapper";

const LoginForm = () => {
	const { t, i18n } = useTranslation("login_page");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const schema = yup.object({
		email: yup.string().email(t("form.email.errors.email")).required(t("form.email.errors.required")),
		password: yup.string().required(t("form.password.errors.required")),
	});
	const { control, handleSubmit } = useForm({
		resolver: yupResolver(schema),
	});
	const auth = useAuth();

	const onSubmit = async ({ email, password }) => {
		setLoading(true);
		await auth.login(email, password);
		setLoading(false);
	};

	return (
		<AuthPageWrapper>
			<Box component="form" onSubmit={handleSubmit(onSubmit)} display="flex" flexDirection="column" sx={styles.from}>
				<Paper elevation={20} sx={styles.inputsWrap}>
					<Input
						sx={{ width: "100%" }}
						control={control}
						name="email"
						label={t("form.email.label")}
						placeholder={t("form.email.placeholder")}
					/>
					<Input
						sx={{ width: "100%" }}
						control={control}
						name="password"
						label={t("form.password.placeholder")}
						type="password"
						placeholder={t("form.password.placeholder")}
					/>
				</Paper>
				<Box
					sx={{ display: "flex", alignSelf: "flex-end", alignItems: "center", justifyContent: "flex-end", my: 2 }}
				>
					<Typography
						sx={{
							color: "primary.main",
							cursor: "pointer",
							fontWeight: 500,
							fontSize: 16,
							textDecoration: "underline",
						}}
						onClick={() => navigate(`/forgot`)}
					>
						{t("forgot")}
					</Typography>
				</Box>
				<Box display="flex" flexDirection="row" sx={{ gap: 1 }}>
					<LoadingButton loading={loading} sx={{ flexBasis: "80%" }} type="submit" variant="contained">
						{t("btns.login")}
					</LoadingButton>
					<FormControl sx={{ flexBasis: "20%" }} size="small">
						<InputLabel id="demo-simple-select-label">{t("form.lang.label")}</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={i18n.resolvedLanguage}
							label={t("form.lang.label")}
						>
							<MenuItem value="en">EN</MenuItem>
							<MenuItem value="el">EL</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</Box>
			<Box sx={styles.qrWrap}>
				<Typography sx={{ mt: 2 }} textAlign="center">
					{t("texts.qr_label")}
				</Typography>
				<img style={{ margin: "0 auto", display: "block" }} src={qr} alt="QR" />
			</Box>
		</AuthPageWrapper>
	);
};

export default LoginForm;
