import React from "react";
import { useTheme } from "@mui/material/styles";
import Chart from "react-apexcharts";

const data = {
	series: [
		{
			color: "#FFB547",
			data: 14859,
			label: "Strategy",
		},
		{
			color: "#7BC67E",
			data: 35690,
			label: "Outsourcing",
		},
		{
			color: "#7783DB",
			data: 45120,
			label: "Marketing",
		},
	],
};

const PieChart = () => {
	const theme = useTheme();

	const chartOptions = {
		chart: {
			background: "transparent",
			stacked: false,
			toolbar: {
				show: false,
			},
		},
		colors: data.series.map((item) => item.color),
		dataLabels: {
			enabled: false,
		},
		fill: {
			opacity: 1,
		},
		labels: data.series.map((item) => item.label),
		legend: {
			show: false,
		},
		stroke: {
			colors: [theme.palette.background.paper],
			width: 1,
		},
		theme: {
			mode: theme.palette.mode,
		},
	};

	const chartSeries = data.series.map((item) => item.data);

	return <Chart width={102} height={102} options={chartOptions} series={chartSeries} type="pie" />
};

export default PieChart;