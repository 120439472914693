import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import {Fade} from "react-reveal"

/* Components */
import Header from "./container-header";

const skeletonsTypes = {
	text: (
		<Grid item xs={6} sm={6} md={6} lg={6}>
			<Typography variant="h2">
				<Skeleton />
			</Typography>
		</Grid>
	),
	section: (
		<Grid item xs={12} sm={12} md={12} lg={12}>
			<Skeleton variant="rectangular" height={200} />
		</Grid>
	),
};

const Container = (props) => {
	const { loading, children, skeleton } = props;

	return (
		<Grid>
			{loading &&
				<Fade
					when={loading}
					duration={500}
					unmountOnExit
				>
					<Grid container sx={{gap: 1, width: "100%", display: !loading && "none"}}>
						{skeleton.map((s, c) => {
							if(s.includes(":")){
								const [type, count] = s.split(":");
								const elements = [...new Array(parseInt(count, 10))].map((_, i) => i).map((key) => (
									<React.Fragment key={`${s}-${type}-${key}`}>{skeletonsTypes[type]}</React.Fragment>
								))
								return (
									<React.Fragment key={s}>
										{elements}
									</React.Fragment>
								)
							}
							return <React.Fragment key={`${s}_${c}`}>{skeletonsTypes[s]}</React.Fragment> //eslint-disable-line
						})}
					</Grid>
				</Fade>
				}
			<Fade unmountOnExit when={!loading} delay={500}>
				<Grid container>
					{!loading ? children : null}
					{/* {children} */}
				</Grid>
			</Fade>
		</Grid>
	);
};

Container.propTypes = {
	loading: PropTypes.bool,
	children: PropTypes.node,
	skeleton: PropTypes.arrayOf(PropTypes.string),
};

Container.defaultProps = {
	loading: false,
	children: "",
	skeleton: ["text", "section"],
};

Container.Header = Header;

export default Container;
