import React from "react";
import PropTypes from "prop-types";

/* MUI */
import MUIDialogTitle from "@mui/material/DialogTitle";
import MUIDialog from "@mui/material/Dialog";
import MUIDialogActions from "@mui/material/DialogActions";
import MUIDialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

/* Utils */
import sh from "@acromove/utils/styles-helpers";

const Dialog = (props) => {
	const { open, children, title, onClose, labelOk, labelCancel, sx, hideButtons, loading, ...rest } = props;

	return (
		<MUIDialog onClose={() => !loading && onClose(false)} open={open} {...rest}>
			<MUIDialogTitle sx={sh.flex("row", "space-between", "center")}>
				{title}
				<IconButton onClick={() => !loading && onClose(false)}>
					<CloseIcon />
				</IconButton>
			</MUIDialogTitle>

			<MUIDialogContent sx={{zIndex: 1000, ...sx}}>{children}</MUIDialogContent>

			{!hideButtons && (
				<MUIDialogActions>
					<LoadingButton loading={loading} variant="text" color="primary" onClick={() => onClose(false)}>
						{labelCancel}
					</LoadingButton>
					<LoadingButton
						loading={loading}
						variant="contained"
						color="primary"
						onClick={() => onClose(true)}
						autoFocus
					>
						{labelOk}
					</LoadingButton>
				</MUIDialogActions>
			)}
		</MUIDialog>
	);
};

Dialog.propTypes = {
	open: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	onClose: PropTypes.func,
	labelOk: PropTypes.string,
	labelCancel: PropTypes.string,
	sx: PropTypes.shape({}),
	hideButtons: PropTypes.bool,
	loading: PropTypes.bool,
};

Dialog.defaultProps = {
	onClose: () => {},
	labelOk: "Save",
	labelCancel: "Cancel",
	sx: {},
	hideButtons: false,
	loading: false,
};

export default Dialog;
