import React from "react";
import PropTypes from "prop-types";

/* Components */
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box } from "@mui/material";

const Carousel = (props) => {
	const { sx, items } = props;
	return (
		<Box
			sx={{
				borderRadius: "8px",
				width: "100%",
				height: "100%",
				overflow: "hidden",
				objectFit: "cover",
				li: {
					width: "100% !important",
				},
				div: {
					position: "relative",
					height: sx.height,
					overflow: "hidden",
					".alice-carousel__prev-btn": {
						position: "absolute",
						top: 0,
						width: "40px",
						height: "100%",
						padding: 0,
						left: 0,
						transform: "scale(1.7)",
						cursor: "pointer",
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					},
					".alice-carousel__next-btn": {
						position: "absolute",
						top: 0,
						width: "40px",
						height: "100%",
						padding: 0,
						right: 0,
						transform: "scale(1.7)",
						cursor: "pointer",
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					},
				},
				...sx,
			}}
		>
			<AliceCarousel
				autoHeight
				autoWidth
				infinite
				autoPlay
				autoPlayInterval={5000}
				mouseTracking
				items={items}
				renderDotsItem={() => {}}
				renderNextButton={() => <ChevronRight color="primary" fontSize="large" />}
				renderPrevButton={() => <ChevronLeft color="primary" fontSize="large" />}
			/>
		</Box>
	);
};

Carousel.propTypes = {
	sx: PropTypes.shape({
		height: PropTypes.number
	}),
	items: PropTypes.arrayOf(PropTypes.shape({})),
};

Carousel.defaultProps = {
	sx: {},
	items: [],
};

export default Carousel;
