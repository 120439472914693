/* eslint-disable */
import { useUploadImagesMutation, useRemoveAssetsMutation } from "@acromove/redux/uploads/api";
import _ from "lodash";
import { v4 as uuidV4 } from "uuid";

const ASSETS_ADDRESS_KEY = "assets_address";

export const findAllArrayPaths = (source, key) => {
	let paths = [];
	if (key.includes("[]")) {
		const startOfArray = key.indexOf("[]");
		const left = key.slice(0, startOfArray + 1);
		const right = key.slice(startOfArray + 1, key.length);
		const endpoint = left.slice(0, -1);
		const entry = _.get(source, endpoint);
		if (!entry || !entry.length) {
			return [];
		}
		const length = entry.length;
		for (let index = 0; index < length; index++) {
			paths.push(`${left}${index}${right}`);
		}

		for (const innerPath of paths) {
			paths = [...paths.filter((p) => p !== innerPath), ...findAllArrayPaths(source, innerPath)];
		}
		return paths;
	} else {
		return [key];
	}
};

/**
 *
 * @param {import("@reduxjs/toolkit/dist/query/react/buildHooks").UseMutation<{} extends import("@reduxjs/toolkit/dist/query").MutationDefinition<any, any, any, any, any>>} useMutation
 * @param {Array<{}>} assetsSettings
 */
const useWithAssetsMutation = (useMutation, assetsSettings, options = {}) => {
	const [mutate, { isLoading, isError, isSuccess }] = useMutation();
	const [upload, { isError: isUploadError, isLoading: isUploadLoading, isSuccess: isUploadSuccess }] =
		useUploadImagesMutation();
	const [remove, { isError: isRemoveError, isLoading: isRemoveLoading, isSuccess: isRemoveSuccess }] =
		useRemoveAssetsMutation();

	const splitRootPaths = (data, assetsPathSettings) => {
		const result = [];
		for (const { path, entity } of assetsPathSettings) {
			const root = path.includes("[]") || path.includes(".") ? path.split(".").slice(0, -1).join(".") : null;
			const assetKey = path.includes("[]") || path.includes(".") ? path.split(".").at(-1) : path;
			if (path.includes("[]")) {
				const keys = findAllArrayPaths(data, root);
				result.push(...keys.map((key) => ({ root: key, key: assetKey, entity })));
			} else {
				if (root) {
					result.push({ root, key: assetKey, entity });
				} else {
					result.push({ root: "$root", key: assetKey, entity });
				}
			}
		}
		return result;
	};

	const ensureAssetAddress = (data, rootPaths) => {
		let cloned = _.cloneDeep(data);
		for (const path of rootPaths) {
			const pathWithAddressKey = path === "$root" ? ASSETS_ADDRESS_KEY : `${path}.${ASSETS_ADDRESS_KEY}`;
			if (!_.has(cloned, pathWithAddressKey) || !_.get(cloned, pathWithAddressKey)) {
				cloned = _.set(cloned, pathWithAddressKey, uuidV4());
			} else {
			}
		}
		return cloned;
	};

	const getFinalizedData = (data, assetsPathSettings) => {
		const keysAndRoots = splitRootPaths(data, assetsPathSettings);
		const formattedData = ensureAssetAddress(
			data,
			keysAndRoots.map((k) => k.root)
		);
		const formattedAssets = [];
		const removedAssets = [];
		for (const { entity, root, key } of keysAndRoots) {
			const imagesKey = root === "$root" ? key : `${root}.${key}`;
			const entityAddressKey = root === "$root" ? ASSETS_ADDRESS_KEY : `${root}.${ASSETS_ADDRESS_KEY}`;
			const images = _.get(formattedData, imagesKey);
			const entityAddress = _.get(formattedData, entityAddressKey);
			const newImages = (images || []).filter((img) => !img.id);
			if (newImages.length) {
				formattedAssets.push({
					images: newImages,
					entity: entity,
					entity_address: entityAddress,
				});
			}
			const assetsToRemove = _.get(data, root === "$root" ? "$removedImages" : `${root}.$removedImages`);
			removedAssets.push(...(assetsToRemove || []));
		}

		return [formattedData, formattedAssets, removedAssets];
	};

	const submitData = async (data) => {
		const [formattedData, formattedAssets, removedAssets] = getFinalizedData(data, assetsSettings);
		const mutationResponse = await mutate(formattedData);
		if (!mutationResponse.error) {
			for (const asset of formattedAssets) {
				await upload(asset);
			}
			if(removedAssets.length){
				await remove({ ids: removedAssets.map((i) => i.id) });
			}
		}
		if(options?.afterAll){
			await options.afterAll()
		}
		return mutationResponse
	};

	return [
		submitData,
		{
			isLoading: isLoading || isUploadLoading || isRemoveLoading,
			isError: isError || isUploadError || isRemoveError,
			idSuccess: isSuccess || isUploadSuccess || isRemoveSuccess,
		},
	];
};

export default useWithAssetsMutation;
