/* eslint-disable */
import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";

/* Components */
import { Box } from "@mui/material";
import Dialog from "@acromove/components/dialog";
import Form from "@acromove/components/form";
import Input from "@acromove/components/form/input";
import Select from "@acromove/components/form/select";
import AsyncAutocomplete from "@acromove/components/form/autocomplete";

/* Redux */
import { useLazyFindOneSiteQuery, useLazyFindSitesQuery } from "redux/sites/sites.api";
import { useCreateClusterMutation } from "redux/clusters/clusters.api";

/* Utils */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ClusterCreateDialog = ({open, setOpen}) => {
	const [spaces, setSpaces] = useState([]);
	const [cages, setCages] = useState([]);
	const [findOneSite] = useLazyFindOneSiteQuery();
	const [createCluster] = useCreateClusterMutation();
	const { t } = useTranslation("clusters");

	const form = useForm({
		defaultValues: {
			label: "",
			hypervisor_type: "",
			site_id: "",
			space_id: null,
			cage_id: null,
		},
	});

	const site = form.watch("site_id");
	const space = form.watch("space_id");

	const findSite = async (id) => {
		const res = await findOneSite({ id });
		setSpaces(res.data.spaces);
	};

	const handleClose = async (isAgree) => {
		if (isAgree) {
			await createCluster({ ...form.getValues(), site_id: site.id });
            form.reset(form.defaultValues)
		}
		setOpen(false);
	};

	useEffect(() => {
		if (site) {
			findSite(site.id);
		}
	}, [site]);

	useEffect(() => {
		if (space) {
			setCages(spaces.find((s) => s.id === space).cages);
		}
	}, [space]);
	return (
		<Dialog open={open} onClose={handleClose} title={t("create_cluster")} maxWidth="sm" sx={{ minWidth: "500px" }}>
			<Form name="clusters" mode="add" form={form} i18n="clusters">
				<Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 1 }}>
					<Form.Field name="label">
						<Input size="medium" sx={{ width: "100%" }} />
					</Form.Field>
					<Form.Field name="hypervisor_type">
						<Select
							size="medium"
							sx={{ width: "100%" }}
							options={[
								{ label: "noCloud", value: "noCloud" },
								{ label: "Sunlight", value: "sunlight" },
								{ label: "Other", value: "other" },
							]}
						/>
					</Form.Field>
					<Form.Field name="site_id">
						<AsyncAutocomplete
							useLazyLoad={useLazyFindSitesQuery}
							onChange={() => {
								form.setValue("space_id", null);
								form.setValue("cage_id", null);
							}}
						/>
					</Form.Field>
					<Form.Field name="space_id">
						<Select
							disabled={!site}
							size="medium"
							sx={{ width: "100%" }}
							options={spaces.map((s) => ({ ...s, label: s.name, value: s.id }))}
							onChange={() => form.setValue("cage_id", null)}
						/>
					</Form.Field>
					<Form.Field name="cage_id">
						<Select
							disabled={!space}
							size="medium"
							sx={{ width: "100%" }}
							options={cages.map((c) => ({ ...c, label: c.label, value: c.id }))}
						/>
					</Form.Field>
				</Box>
			</Form>
		</Dialog>
	);
};

// ClusterCreateDialog.propTypes = {
// 	open: PropTypes.bool.isRequired,
// 	setOpen: PropTypes.func().isRequired
// }

export default ClusterCreateDialog;
