import React from "react";
import PropTypes from "prop-types";

/* Components */
import Form from "@acromove/components/form";
import Input from "@acromove/components/form/input";
import Section from "components/layouts/dashboard/ui/section";

/* MUI */
import { Grid } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";

/* Utils */
import useFormNotices from "@acromove/hooks/use-form-warnings-parser";
import useFormContext from "@acromove/components/form/hooks/use-form-context";
import DragNDropDialog from "@acromove/components/form/drag-n-drop-dialog";

const CageItem = (props) => {
	const { t, field, watchLabel, spaceIndx, index } = props;
	const { form } = useFormContext();
	useFormNotices(form, {
		t,
		field: `spaces[${spaceIndx}].cages[${index}]`,
	});
	return (
		<Section
			key={field.id}
			title={watchLabel(index)}
			collapsible
			action={
				<Form.Field name={`spaces[${spaceIndx}].cages[${index}].images`}>
					<DragNDropDialog
						fileTypes={["jpg", "png"]}
						multiple
						removedImagesKey={`spaces[${spaceIndx}].cages[${index}].$removedImages`}
						iconButton
					/>
				</Form.Field>
			}
		>
			<Grid
				container
				gap={1}
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					flexWrap: "nowrap",
					mt: 1,
				}}
			>
				<Grid item md={4}>
					<Form.Field name={`spaces[${spaceIndx}].cages[${index}].label`}>
						<Input label={t("label.label")} size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
				<Grid item md={4}>
					<Form.Field name={`spaces[${spaceIndx}].cages[${index}].capacity`}>
						<Input label={t("capacity.label")} size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
				<Grid item md={4}>
					<Form.Field name={`spaces[${spaceIndx}].cages[${index}].position_label`}>
						<Input label={t("position_label.label")} size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
			</Grid>
		</Section>
	);
};

CageItem.propTypes = {
	spaceIndx: PropTypes.number.isRequired,
	t: PropTypes.func.isRequired,
	watchLabel: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	field: PropTypes.shape({
		id: PropTypes.number.isRequired,
	}).isRequired,
	// remove: PropTypes.func.isRequired,
};

export default CageItem;
