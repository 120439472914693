import SmartRouter from "@acromove/utils/smart-router";

/* Layouts */
import PublicLayout from "components/layouts/public";
import DashboardLayout from "components/layouts/dashboard";

/* Pages */
import LoginPage from "pages/login";
import UserProfilePage from "pages/user/profile";
import ForgotPage from "pages/forgot";
import ResetPage from "pages/reset";

const router = new SmartRouter();

router.addRoute("/login", {
    title: "login",
    Container: LoginPage, 
    Layout: PublicLayout, 
    ignoreOnNav: true,
})

router.addRedirect("/user", "/user/profile")

router.addRoute("/forgot", {
    title: "forgot",
    Container: ForgotPage,
    Layout: PublicLayout,
    ignoreOnNav: true,
})

router.addRoute("/reset", {
    title: "reset",
    Container: ResetPage,
    Layout: PublicLayout,
    ignoreOnNav: true,
})

router.addRoute("/user/profile", {
    title: "profile",
    Container: UserProfilePage, 
    Layout: DashboardLayout, 
    ignoreOnNav: true,
})


export default router;