/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

import formGroupContext from "./contexts/group";
import { useState } from "react";
import { useEffect } from "react";
import _ from "lodash";

const FormGroup = (props) => {
    const { strategy, defaultValue, onChange, children, formGroup } = props;
	const [formsData, setFormsData] = useState(defaultValue)

	const handleChange = (name, values) => {
		if(strategy === "merge"){
			setFormsData({...formsData, ...values})
		} else if (strategy === "by-alias") {
			setFormsData(_.set({...formsData}, name, values))
		}
	}

	useEffect(() => {
		onChange(formsData)
		formGroup?.setFormsValues(formsData)
	}, [formsData])

	const handleDispatchGroup = (name, form, opt) => {
		formGroup?.addForm(name, form, opt)
	}

    return(
        <formGroupContext.Provider value={{
				onChange: handleChange,
				formsData,
				dispatchForm: handleDispatchGroup,
				forms: formGroup.forms
			}}>
            {children}
        </formGroupContext.Provider>
    );
}

FormGroup.propTypes = {
	strategy: PropTypes.oneOf(["merge", "by-alias"]),
	onChange: PropTypes.func,
	defaultValue: PropTypes.shape({}),
	formGroup: PropTypes.shape({})
}

FormGroup.defaultProps = {
	strategy: "merge",
	onChange: () => {},
	defaultValue: {},
	formGroup: {}
}

export default FormGroup;
