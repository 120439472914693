import React from "react";

import Grid from "@mui/material/Grid";
import sh from "@acromove/utils/styles-helpers"

const Page404 = () => (
    <Grid container sx={[sh.flex("row", "center", "center")]}>
        <Grid item>
            404 Page Not Found
        </Grid>
    </Grid>
)

export default Page404;