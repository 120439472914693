import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

/* Layout Components */
import Section from "components/layouts/dashboard/ui/section";

/* Common Components */
import Input from "@acromove/components/form/input";
import Select from "@acromove/components/form/select";
import ArrowRight from "components/icons/arrow-right";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import useMediaQuery from '@mui/material/useMediaQuery';


const BillingDetails = (props) => {
	const { editMode, button } = props;
	const downMd = useMediaQuery((theme) => `${theme.breakpoints.down('md')}`);
	const { t } = useTranslation("clients_users_info");
	const schema = yup.object({});
	const { control } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			first_name: "",
			last_name: "",
			organization: "",
			street: "",
			email: "",
			country: 1,
			state: "",
			city: "",
			zip: "",
			country_code: 1,
			phone: "",
		},
	});

	return (
		<Section
			collapsible
			title={t("billing_form.title")}
			action={
				button && <Button variant="contained" endIcon={<ArrowRight />} onClick={() => null}>
					{t("billing_form.button")}
				</Button>
			}
		>
			<Box component="form" sx={{ display: "flex", flexDirection: "row", mt: 6 }}>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={8}>
						<Grid container gap={4} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
							<Grid item xs={12} sm={12} md={12}>
								<Grid container gap={3} sx={{ flexWrap: downMd ? "wrap" : "nowrap" }}>
									<Grid item xs={12} sm={12} md={6}>
										<Input
											size="medium"
											disabled={!editMode}
											sx={{ width: "100%" }}
											control={control}
											name="name"
											label={t("billing_form.first_name")}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<Input
											size="medium"
											disabled={!editMode}
											sx={{ width: "100%" }}
											control={control}
											name="full_name"
											label={t("billing_form.last_name")}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Input
									size="medium"
									disabled={!editMode}
									sx={{ width: "100%" }}
									control={control}
									name="organization"
									label={t("billing_form.organization")}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Input
									size="medium"
									disabled={!editMode}
									sx={{ width: "100%" }}
									control={control}
									name="email"
									label={t("billing_form.email")}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Input
									size="medium"
									disabled={!editMode}
									sx={{ width: "100%" }}
									control={control}
									name="street"
									label={t("billing_form.street")}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Grid container gap={3} sx={{ flexWrap: "nowrap" }}>
									<Grid item xs={6} sm={6} md={6}>
										<Select
											size="medium"
											disabled={!editMode}
											sx={{ width: "100%" }}
											control={control}
											name="country"
											label={t("billing_form.country_region")}
											options={[
												{ label: "Country 1", value: 1 },
												{ label: "Country 2", value: 2 },
												{ label: "Country 3", value: 3 },
											]}
										/>
									</Grid>
									<Grid item xs={6} sm={6} md={6}>
										<Input
											size="medium"
											disabled={!editMode}
											sx={{ width: "100%" }}
											control={control}
											name="state"
											label={t("billing_form.state")}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Grid container gap={3} sx={{ flexWrap: "nowrap" }}>
									<Grid item xs={6} sm={6} md={6}>
										<Input
											size="medium"
											disabled={!editMode}
											sx={{ width: "100%" }}
											control={control}
											name="name"
											label={t("billing_form.city")}
										/>
									</Grid>
									<Grid item xs={6} sm={6} md={6}>
										<Input
											size="medium"
											disabled={!editMode}
											sx={{ width: "100%" }}
											control={control}
											name="full_name"
											label={t("billing_form.zip")}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Grid container gap={3} sx={{ flexWrap: "nowrap" }}>
									<Grid item xs={5} sm={5} md={3}>
										<Select
											size="medium"
											disabled={!editMode}
											sx={{ width: "100%" }}
											control={control}
											name="country_code"
											label={t("billing_form.country_code")}
											options={[
												{ label: "Code 1", value: 1 },
												{ label: "Code 2", value: 2 },
												{ label: "Code 3", value: 3 },
											]}
										/>
									</Grid>
									<Grid item xs={7} sm={7} md={9}>
										<Input
											size="medium"
											disabled={!editMode}
											sx={{ width: "100%" }}
											control={control}
											name="phone"
											label={t("billing_form.phone")}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Section>
	);
};

BillingDetails.propTypes = {
	editMode: PropTypes.bool,
	button: PropTypes.bool
};

BillingDetails.defaultProps = {
	button: true,
	editMode: false
}

export default BillingDetails;
