import React from "react";
import PropTypes from "prop-types";

/* MUI */
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import ArrowRightIcon from "components/icons/arrow-right";

/* Utils */
import {useNavigate} from "react-router-dom";

/**
 * Data Grid Icon Link component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Data Grid Icon Link Component.
 */
const DataGridIconLink = (props) => {
	const { row, field, func } = props
	const navigate = useNavigate()
	return (
	<Box sx={{ px: "10px", display: "flex", justifyContent: "flex-end" }}>
			<IconButton onClick={() => func ? func() : navigate(`${row[field]}`)}>
				<ArrowRightIcon color="primary" />
			</IconButton>
	</Box>
)}

/**
 * Data Grid Icon Link Properties.
 *
 * @typedef {object} Props
 * @property {object} params - Data Grid Value.
 */

 DataGridIconLink.propTypes = {
   row: PropTypes.shape({}).isRequired,
   field: PropTypes.string.isRequired,
   func: PropTypes.func
}

DataGridIconLink.defaultProps = {
	func: () => {}
}

export default DataGridIconLink