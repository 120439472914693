import DataGridIconLink from "@acromove/components/data-grid/cells/datagrid-icon-link";

export const columns = [
	{
		headerName: "label",
		field: "label",
		minWidth: 300,
		sortable: true,
	},
	{
		headerName: "hypervisor_type",
		field: "hypervisor_type",
		minWidth: 300,
		sortable: true,
	},
	{
		headerName: "status",
		field: "status",
		sortable: false,
	},
	{
		headerName: "machines",
		field: "machines",
		sortable: false,
		renderCell: (cellProps) => {
			const { row } = cellProps;
			return row?.machines?.length;
		},
	},
	{
		headerName: "",
		field: "url",
		flex: 0.5,
		align: "right",
		sortable: false,
		renderCell: DataGridIconLink,
	},
];

export default {
	columns,
};
