/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

/* MUI */
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

/* Utils */
import sh from "../../utils/styles-helpers";
import useFiled from "./hooks/use-filed";

/**
 * Input component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Form Controlled Input.
 */
const Input = (props) => {
	const {
		name: fieldName,
		defaultValue,
		label,
		placeholder,
		size,
		type = "text",
		sx = {},
		helperText,
		control,
		disabled,
		endAdornment = null,
		startAdornment = null,
		...rest
	} = props;
	const field = useFiled({
		fromProps: {
			placeholder,
			defaultValue,
			name: fieldName,
			disabled,
			helperText,
			control,
		},
	});

	
	const { onChange, onBlur, name, value, ref } = field.controller.field;
	const { error } = field.controller.fieldState;

	return (
		<Box
			sx={[sh.flex("row", "flex-start", "flex-start"), { width: "100%" }]}
		>
			<TextField
				multiline={type === "textarea"}
				rows={type === "textarea" ? 5 : 1}
				sx={sx}
				type={type}
				size={size}
				label={field.label || label}
				placeholder={field.placeholder || placeholder}
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				name={name}
				inputRef={ref}
				error={!!error}
				disabled={field.disabled}
				helperText={field.helperTextMsg}
				InputProps={{
					endAdornment,
					startAdornment
				}}
				{...rest}
			/>
			{(field.helperText || helperText) && (
				<IconButton
					sx={{
						mt: 1,
						ml: 1,
					}}
					size={size}
					color="primary"
					onClick={() => field.setShowHelper(!field.showHelper)}
				>
					<InfoOutlinedIcon />
				</IconButton>
			)}
		</Box>
	);
};

/**
 * Input Properties.
 *
 * @typedef {object} Props
 * @property {string | true | false | null} [helperText = null] - Helper Text of the Input.
 * @property {object} control - Form control options.
 * @property {string} name - Form Input name.
 * @property {string} [defaultValue = ""] - Default Value passed to Input.
 * @property {string} [placeholder = ""] - Placeholder.
 * @property {string} [label = ""] - Label on top of Input.
 * @property {"small" | "medium"} [size = "small"] - Size of Input.
 * @property {"text" | "password" | "textarea" | "number" | "email"} [type="text"] - Type of Input
 * @property {object} [sx = {}] - Property to rewrite Input CSS properties.
 * @property {React.ReactNode} endAdornment - Element added to the end of Input.
 * @property {React.ReactNode} startAdornment - Element added to the beginning of Input.
 */

Input.propTypes = {
	helperText: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
		PropTypes.oneOf([null]),
	]),
	control: PropTypes.shape({}),
	name: PropTypes.string,
	defaultValue: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	size: PropTypes.oneOf(["small", "medium"]),
	type: PropTypes.oneOf(["text", "password", "textarea", "number", "email", "tel"]),
	sx: PropTypes.shape({}),
	disabled: PropTypes.bool,
	endAdornment: PropTypes.node,
	startAdornment: PropTypes.node,
};

Input.defaultProps = {
	name: "",
	control: {},
	helperText: null,
	defaultValue: "",
	placeholder: "",
	label: "",
	size: "small",
	type: "text",
	sx: {},
	disabled: false,
	endAdornment: null,
	startAdornment: null
};

export default Input;
