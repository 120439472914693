import React from "react";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const SortDown = ({sortingOrder: _, ...other}) => (<ArrowDropDownIcon {...other} />)

SortDown.propTypes = {
    sortingOrder: PropTypes.string
}

SortDown.defaultProps = {
    sortingOrder: null
}

export default SortDown;