import React, { useEffect } from "react";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Content Components */
import UserDetails from "components/content/clients/user-details";
// import TenantDetails from "components/content/clients/tenant-details";
// import AdminDetails from "components/content/clients/admin-details";
import BillingDetails from "components/content/clients/billing-details";
// import Switcher from "@acromove/components/switcher";
import DataGridTable from "@acromove/components/data-grid";
import DataGridProvider from "@acromove/components/data-grid/data-grid-provider";
import { groupChildGroups } from "components/table-columns/clients/groups";

/* MUI */
import { Typography } from "@mui/material";

/* Utils */
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useItemsTranslation from "@acromove/hooks/use-items-translation";

/* Redux */
import { useFindOneUserQuery, useFindUserGroupsQuery } from "redux/clients/clients.api";

const ClientUserInfoContainer = () => {
	const { userId } = useParams();
	const Id = userId.split("-")[0];
	const { t } = useTranslation("clients_users_info");
	const itemsTranslator = useItemsTranslation("clients_groups");

	const { data: userData, isLoading: userIsLoading, refetch: userRefetch } = useFindOneUserQuery({ key: Id });
	const { isLoading: groupsIsLoading, refetch: groupsRefetch } = useFindUserGroupsQuery({ key: Id });

	useEffect(() => {
		userRefetch();
		groupsRefetch();
	}, [userData, userId]);

	return (
		<Container loading={userIsLoading && groupsIsLoading} skeleton={["text:2", "section:2"]}>
			<Container.Header title={t("back")} back={-1} skeleton={["form:2", "section:4"]} />
			<Typography fontSize="20px" color="neutral.900" fontWeight="600">
				{t("user_view")}
			</Typography>
			<UserDetails data={userData} userKey={Id} />
			<DataGridProvider useHook={useFindUserGroupsQuery} hookProps={{ key: Id }} initSort="name">
				<DataGridTable
					collapsible
					search
					title={t("user_groups")}
					columns={itemsTranslator(groupChildGroups, "table", "field", "headerName")}
				/>
			</DataGridProvider>
			<BillingDetails />
			{/* Switching system depending on user role - currently not needed */}
			{/* <Switcher value={userData?.role}>
				<Switcher.Item name={["user", "user:view_only"]}>
					<Container.Header title={t("back")} back={-1} skeleton={["form:2", "section:4"]} />
					<Typography fontSize="20px" color="neutral.900" fontWeight="600">
						{t("user_view")}
					</Typography>
					<UserDetails data={userData} userKey={Id} />
					<DataGridProvider useHook={useFindUserGroupsQuery} hookProps={{ key: Id }} initSort="name">
						<DataGridTable
							collapsible
							search
							title={t("user_groups")}
							columns={itemsTranslator(groupChildGroups, "table", "field", "headerName")}
						/>
					</DataGridProvider>
					<BillingDetails />
				</Switcher.Item>
				<Switcher.Item name={["accountant", "accountant:view_only"]}>
					<Container.Header title={t("back")} back={-1} skeleton={["form:2", "section:4"]} />
					<Typography fontSize="20px" color="neutral.900" fontWeight="600">
						{t("tenant_view")}
					</Typography>
					<TenantDetails data={userData} userKey={Id} />
					<DataGridProvider useHook={useFindUserGroupsQuery} hookProps={{ key: Id }} initSort="name">
						<DataGridTable
							collapsible
							search
							title={t("user_groups")}
							columns={itemsTranslator(groupChildGroups, "table", "field", "headerName")}
						/>
					</DataGridProvider>
					<BillingDetails />
				</Switcher.Item>
				<Switcher.Item name={["acromove_admin:owner", "acromove_admin", "admin", "admin:view_only", "admin:owner"]}>
					<Container.Header title={t("back")} back={-1} skeleton={["form:2", "section:4"]} />
					<Typography fontSize="20px" color="neutral.900" fontWeight="600">
						{t("admin_view")}
					</Typography>
					<AdminDetails data={userData} userKey={Id} />
					<DataGridProvider useHook={useFindUserGroupsQuery} hookProps={{ key: Id }} initSort="name">
						<DataGridTable
							collapsible
							search
							title={t("user_groups")}
							columns={itemsTranslator(groupChildGroups, "table", "field", "headerName")}
						/>
					</DataGridProvider>
				</Switcher.Item>
			</Switcher> */}
		</Container>
	);
};

export default ClientUserInfoContainer;
