import SmartRouter from "@acromove/utils/smart-router";
import DashboardLayout from "components/layouts/dashboard";

import ClustersPage from "pages/clusters";
import ClustersViewPage from "pages/clusters/view";

const router = new SmartRouter();

// router.addRedirect("/sites", "/sites/browse")

router.addRoute("/clusters", {
    title: "Clusters",
    Layout: DashboardLayout,
	Container: ClustersPage,
    accessSlug: "sites",
})

router.addRoute("/clusters/view/:clusterId", {
    title: "Cluster View",
    Container: ClustersViewPage,
    Layout: DashboardLayout,
    accessSlug: "sites",
    childOf: "/clusters",
    ignoreOnNav: true,
})

export default router