import React from "react";
import PropTypes from "prop-types";

/* Components */
import Form from "@acromove/components/form";
import Section from "components/layouts/dashboard/ui/section";
import Input from "@acromove/components/form/input";

/* MUI */
import { Grid, InputAdornment } from "@mui/material";

/* Utils */
import { useTranslation } from "react-i18next";
import useFormNotices from "@acromove/hooks/use-form-warnings-parser";
import useFormContext from "@acromove/components/form/hooks/use-form-context";

const EnvCharacter = ({spaceIndx}) => {
	const { t } = useTranslation("sites_create", { keyPrefix: "sites.spaces[].environmental" });
	const {form} = useFormContext()

	useFormNotices(form, {
        t,
		field: `spaces[${spaceIndx}].environmental`,
    })

	return (
		<>
			<Section title={t("titles.zones")}>
				<Grid
					container
					gap={1}
					sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", flexWrap: "nowrap", mt: 1 }}
				>
					<Grid item md={4} lg={4}>
						<Form.Field name={`spaces[${spaceIndx}].environmental.windows_area`}>
							<Input
								type="number"
								endAdornment={<InputAdornment position="end">m²</InputAdornment>}
								size="medium"
								sx={{ width: "100%" }}
							/>
						</Form.Field>
					</Grid>
					<Grid item md={4} lg={4}>
						<Form.Field name={`spaces[${spaceIndx}].environmental.doors_area`}>
							<Input
								type="number"
								endAdornment={<InputAdornment position="end">m²</InputAdornment>}
								size="medium"
								sx={{ width: "100%" }}
							/>
						</Form.Field>
					</Grid>
					<Grid item md={4} lg={4}>
						<Form.Field name={`spaces[${spaceIndx}].environmental.air_vents_area`}>
							<Input
								type="number"
								endAdornment={<InputAdornment position="end">m²</InputAdornment>}
								size="medium"
								sx={{ width: "100%" }}
							/>
						</Form.Field>
					</Grid>
				</Grid>
			</Section>
			<Section title={t("titles.capacity")}>
				<Grid
					container
					gap={1}
					sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", flexWrap: "nowrap", mt: 1 }}
				>
					<Grid item md={4} lg={4}>
						<Form.Field name={`spaces[${spaceIndx}].environmental.ac_capacity`}>
							<Input
								type="number"
								endAdornment={<InputAdornment position="end">BTU</InputAdornment>}
								size="medium"
								sx={{ width: "100%" }}
							/>
						</Form.Field>
					</Grid>
					<Grid item md={4} lg={4}>
						<Form.Field name={`spaces[${spaceIndx}].environmental.heater_capacity`}>
							<Input
								type="number"
								endAdornment={<InputAdornment position="end">BTU</InputAdornment>}
								size="medium"
								sx={{ width: "100%" }}
							/>
						</Form.Field>
					</Grid>
				</Grid>
			</Section>
			<Section title={t("titles.temperatures")}>
				<Grid
					container
					gap={1}
					sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", flexWrap: "nowrap", mt: 1 }}
				>
					<Grid item md={4} lg={4}>
						<Form.Field name={`spaces[${spaceIndx}].environmental.temperature_min`}>
							<Input
								type="number"
								endAdornment={<InputAdornment position="end">℃</InputAdornment>}
								size="medium"
								sx={{ width: "100%" }}
							/>
						</Form.Field>
					</Grid>
					<Grid item md={4} lg={4}>
						<Form.Field name={`spaces[${spaceIndx}].environmental.temperature_max`}>
							<Input
								type="number"
								endAdornment={<InputAdornment position="end">℃</InputAdornment>}
								size="medium"
								sx={{ width: "100%" }}
							/>
						</Form.Field>
					</Grid>
					<Grid item md={4} lg={4}>
						<Form.Field name={`spaces[${spaceIndx}].environmental.temperature_mean`}>
							<Input
								type="number"
								endAdornment={<InputAdornment position="end">℃</InputAdornment>}
								size="medium"
								sx={{ width: "100%" }}
							/>
						</Form.Field>
					</Grid>
				</Grid>
			</Section>
		</>
	);
};

EnvCharacter.propTypes = {
	spaceIndx: PropTypes.number.isRequired
}

export default EnvCharacter;
