import React from "react";

/* MUI */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

/* Content Components */
import PieChart from "components/content/acro-dashboard/pie-chart";

/* Common Components */
import StatsBox from "@acromove/components/statistics/StatsBox";
import Chart from "@acromove/components/chart";

/* Utils */
import { useTranslation } from "react-i18next";
import useItemsTranslation from "@acromove/hooks/use-items-translation";
import sh from "@acromove/utils/styles-helpers";

/* Mock */
import mock from "mock/acro-admin-dashboard";

const ResourcesRequests = () => {
	const {t} = useTranslation("acro_admin_dashboard");
	const itemsTranslator = useItemsTranslation("acro_admin_dashboard");

	const chartSeries = itemsTranslator(mock.resourcesRequestsChart, "physical_resources_requests.chart", "slug", "name");
	return (
		<Grid container>
			<Grid item sm={12} md={4} lg={4} sx={{width: "100%"}}>
				<Grid container sx={{ p: "0px 20px", flexDirection: "column", width: "100%" }}>
					<Grid item>
						<StatsBox
							label={t(`physical_resources_requests.stats.${mock.resourcesRequestsStats.installationsInProgress.slug}`)}
							stat={mock.resourcesRequestsStats.installationsInProgress.stat}
							append={
								<Box sx={{ pt: "34px", pr: "0px", pl: "0px", flexBasis: "100px" }}>
									<PieChart />
								</Box>
							}
						/>
					</Grid>
					<Grid item>
						<StatsBox 
							label={t(`physical_resources_requests.stats.${mock.resourcesRequestsStats.newRequests.slug}`)} 
							stat={mock.resourcesRequestsStats.newRequests.stat}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item sm={12} md={8} lg={8}>
					<Chart.Provider xAxisCategories={["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]} series={chartSeries}>
						<Chart.Header title={t("physical_resources_requests.chart.title")}>
							<Chart.Toolbar tools={["download"]}/>
						</Chart.Header>
						<Chart type="bar" height={300} options={{
							fill: {
								opacity: 1,
							},
							plotOptions: {
								bar: {
								  borderRadius: 5,
								  columnWidth: 50,
								}
							  }
						}}/>
						<Box sx={[sh.flex("row", "flex-end", "center"), { width: "100%" }]}>
							<Chart.Legends />
						</Box>
					</Chart.Provider>
			</Grid>
		</Grid>
	);
};

export default ResourcesRequests;
