import React, { useEffect, useState } from "react";

/* MUI */
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Content Components */
import { mySitesMain } from "components/table-columns/sites";
import ProtectedComponent from "@acromove/authentication/components/protected/component";
import Dialog from "@acromove/components/dialog";
import DragDrop from "@acromove/components/drag-and-drop";
import DataGridProvider from "@acromove/components/data-grid/data-grid-provider";
import DataGridTable from "@acromove/components/data-grid";
import Map from "@acromove/components/map";

/* Utils */
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useItemsTranslation from "@acromove/hooks/use-items-translation";
import { toast } from "react-hot-toast";
import sites from "assets/as-js-string/sites";

/* Redux */
import { useFindAllSitesQuery, useFindSitesQuery, useImportSitesMutation } from "redux/sites/sites.api";
import useLocalUserSettings from "@acromove/hooks/use-local-user-settings";

const SitesMyContainer = () => {
	const { t } = useTranslation("sites_my");
	const itemsTranslator = useItemsTranslation("sites_my");
	const navigate = useNavigate();
	const [selectedCountry, setSelectedCountry] = useLocalUserSettings("sitesCountry", null);
	const [selectedCity, setSelectedCity] = useLocalUserSettings("sitesCity", null);
	const [file, setFile] = useState(null);
	const [importOpen, setImportOpen] = useState(false);
	const [importKmz] = useImportSitesMutation();
	const { data, refetch, isLoading } = useFindAllSitesQuery({ cityId: selectedCity?.id, countryId: selectedCountry?.id });
	const mapGroups = [
		{
			slug: "site",
			label: t("map.sites"),
			icon: sites,
		},
	];

	const handleImport = async (fileToSend) => {
		const result = await importKmz({ file: fileToSend });
		if (result.data) {
			toast.success(`${t("import.messages.success") + result.data.imported}`);
		}
		if (result.data?.failed) {
			toast.error(`${t("import.messages.failed") + result.data.failed}`);
			for (const error of result.data.errors) {
				toast.error(
					t("import.messages.failed_poi") + //eslint-disable-line
						`${error.name}, ${error.cords}, ${t(`import.messages.errors.${error.reason}`)}`
				);
			}
		}
		setFile(null)
	};

	const handleChangeFile = (incomingFile) => {
		setFile(incomingFile);
	};

	const handleCloseImport = async (isAgree) => {
		if (isAgree) {
			const localFile = new File([file], file.name, { type: "application/vnd.google-earth.kmz" });
			await handleImport(localFile);
		}
		setImportOpen(false);
	};

	useEffect(() => {
		refetch();
	}, []);

	return (
		<Container loading={isLoading}>
			<Dialog
				fullWidth
				maxWidth="sm"
				title={t("import.title")}
				labelCancel={t("import.discard")}
				labelOk={t("import.submit")}
				open={importOpen}
				onClose={handleCloseImport}
			>
				<DragDrop fileTypes={["kml", "kmz"]} onChange={handleChangeFile} files={file || []} multiple={false}/>
			</Dialog>
			<Container.Header title={t("title")} />
			<Map
				defCountry={selectedCountry}
				defCity={selectedCity}
				onCountryChange={setSelectedCountry}
				onCityChange={setSelectedCity}
				groups={mapGroups}
				onPointClick={(_, point) => navigate(point.url)}
				points={data?.items
					.filter((p) => p.latitude && p.longitude)
					.map((point) => ({
						url: point.url,
						id: point.id,
						group: "site",
						position: { lat: point.latitude, lng: point.longitude },
						type: "dot",
						tooltip: [
							{ label: t("map.tooltip.name.label"), value: point.name },
							{ label: t("map.tooltip.status.label"), value: point.status },
						],
					}))}
			/>
			<DataGridProvider
				useHook={useFindSitesQuery}
				initSort="name"
			>
				<DataGridTable
					collapsible
					search
					action={
						<>
							<ProtectedComponent slug="sites:import">
								<Button variant="contained" onClick={() => setImportOpen(true)}>
									<AddIcon sx={{ mr: 1 }} />
									{t("import.button")}
								</Button>
							</ProtectedComponent>
							<Button variant="contained" onClick={() => navigate("/sites/create")}>
								<AddIcon />
								{t("add")}
							</Button>
						</>
					}
					title={t("table.title")}
					columns={itemsTranslator(mySitesMain, "table", "field", "headerName")}
				/>
			</DataGridProvider>
		</Container>
	);
};

export default SitesMyContainer;
