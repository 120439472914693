import React, { useState } from "react";
import PropTypes from "prop-types";
import TreeView from "@mui/lab/TreeView";
import TreeViewItem from "./item";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import treeViewContext from "./context";

/**
 * Tree View Provider Component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Tree View Provider.
 */

const TreeViewProvider = (props) => {
	const { limit, onSelect, useLazyLoadChild, sx, idKey, formateData, name } = props;
	const [expanded, setExpanded] = useState([]);

	const handleToggle = (_, ids) => {
		if (ids.length > 1 && ids[0] === "root") {
			setExpanded(["root"]);
		} else {
			setExpanded(ids);
		}
	};

	return (
		<treeViewContext.Provider
			value={{
				limit,
				onSelect,
				useLazyLoadChild,
				idKey,
				formateData,
				expanded,
			}}
		>
			<TreeView
				defaultCollapseIcon={<ArrowDropDownIcon />}
				defaultExpandIcon={<ArrowRightIcon />}
				expanded={expanded}
				disableSelection={false}
				onNodeToggle={handleToggle}
				sx={{mx: "4px", ...sx}}
			>
				<TreeViewItem name={name} id="root" />
			</TreeView>
		</treeViewContext.Provider>
	);
};

/**
 * Tree View Provider Properties.
 *
 * @typedef {object} Props
 * @property {function} useLazyLoadChild - Hook passed to Provider.
 * @property {number} [limit = 5] - Limit.
 * @property {function} [onSelect = () => null] - executed when tree item element is selected.
 * @property {string} name - Tree root element name.   
 * @property {function | object | array} [sx = {}] - Additional CSS options.
 * @property {string} [idKey = "key"] - idKey passed from parent component to item, to define which data to be rendered.
 * @property {function} [formateData = (item) => item]
 */


TreeViewProvider.propTypes = {
	name: PropTypes.string.isRequired,
	limit: PropTypes.number,
	onSelect: PropTypes.func,
	useLazyLoadChild: PropTypes.func.isRequired,
	sx: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
		PropTypes.func,
		PropTypes.object,
	]),
	idKey: PropTypes.string,
	formateData: PropTypes.func,
};

TreeViewProvider.defaultProps = {
	limit: 5,
	onSelect: () => null,
	sx: {},
	idKey: "key",
	formateData: (item) => item,
};

export default TreeViewProvider;
