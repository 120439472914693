/* eslint-disable */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

/* MUI */
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import UploadIcon from '@mui/icons-material/Upload';
import Button from "@mui/material/Button"


/* Utils */
import { useFileUpload } from 'use-file-upload'
import sh from "../../utils/styles-helpers"
import useField from "./hooks/use-filed";
import { Typography } from "@mui/material";
import ImageAsset from "../assets/image";

/**
 * Avatar Upload component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Form Controlled Avatar Uploader Component.
 */
const AvatarUploader = (props) => {
	const { alt, sx, size, fontSize, direction, editText, startIcon, name: fieldName, control, disabled, width, height, borderRadius, padding, backgroundColor, placeholder } = props;
    const [file, selectFile] = useFileUpload();

    const field = useField({
        fromProps: {
            name: fieldName,
            disabled,
			control,
        }
    })
    const { onChange, value } = field.controller.field;
    const { error } = field.controller.fieldState;

    useEffect(() => {
        if(file?.file){
            onChange({
                target: {
                    value: file.file
                }
            })
        }
    }, [file])


    const selectFileWithRules = () => {
        if(!disabled){
            selectFile({ accept: 'image/*' })
        }
    }

	return (
		<Box sx={[{width: "200px"}, sh.flex(direction, "flex-start", "flex-start"), sx]}>
			<Avatar
				sx={[
                    {
                        width: width || size,
                        height: height || size,
                        fontSize,
                        borderRadius,
                        padding,
                        backgroundColor
                    },
					{
						cursor: field.disabled ? "default" : "pointer",
						position: "relative",
						overflow: "hidden",
						"&:hover > div:nth-child(2)": {
							opacity: field.disabled ? 0 : 1,
						},
					},
				]}
                >
				<ImageAsset asset={value} size="thumbnail" sx={{
					width: width || size,
					height: height || size,
				}}/>
				<Box
					sx={[
                        {
                            bgcolor: backgroundColor,
                            opacity: 0,
                            transition: "opacity ease .25s",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: 10000
					    },
                        sh.flex("column", "center", "center")
                    ]}
                    onClick={!field.disabled ? selectFileWithRules : undefined}
                    >
					<UploadIcon fontSize={`${fontSize}px`}/>
				</Box>
				{alt}
            </Avatar>
            {error && <Typography color="error.main" fontSize="0.75rem" fontWeight="400" sx={{m: "3px 14px 0 14px"}}>{field.helperTextMsg}</Typography>}
            <Button
                startIcon={startIcon}
                disabled={field.disabled}
                variant="text"
                onClick={selectFileWithRules}
                sx={{width: "100%", my: 1}}>
                {editText}
            </Button>
		</Box>
	);
};

/**
 * Avatar Uploader Properties.
 *
 * @typedef {object} Props
 * @property {object} control - Form control options.
 * @property {string} name - Form Avatar name.
 * @property {React.ReactNode} startIcon - Props to add Custom Icon.
 * @property {string} alt  - Alternative prop for image.
 * @property {string} [direction = "row"] - Prop to Change Flex Direction of Avatar Container.
 * @property {string} [editText = "Change"] - Property to change edit button text.
 * @property {array | func | object} [sx = {}] - Property to control Avatar Container CSS.
 * @property {number} size - Avatar Witdth and Height.
 * @property {number} fontSize - Upload Icon size prop.
 * @property {true | false} [disabled = "false"] - Enable/Disable Avatar uploader.
 * @property {string | number} [width = ""] - width of the avatar element.
 * @property {string | number} [height = ""] - height of the avatar element.
 * @property {string} [borderRadius = "50%"] - border radiut of the avatar element.
 * @property {string} [placeholder = null] - placeholder for avatar.
 */

AvatarUploader.propTypes = {
    control: PropTypes.shape({}),
    name: PropTypes.string,
	alt: PropTypes.string,
    direction: PropTypes.string,
    editText: PropTypes.string,
    startIcon: PropTypes.node,
	/**
	 * @type {import("@mui/system").SxProps<import("@mui/system").Theme>}
	 */
	sx: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
		PropTypes.func,
		PropTypes.object,
	]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fontSize: PropTypes.number,
    disabled: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    borderRadius: PropTypes.string,
    padding: PropTypes.string,
    backgroundColor: PropTypes.string,
    placeholder: PropTypes.string
};

AvatarUploader.defaultProps = {
    contol: {},
    name: "",
    alt: "",
	sx: {},
    size: 150,
    fontSize: 24,
    disabled: false,
    direction: "row",
    editText: "Change",
    startIcon: "",
    width: "",
    height: "",
    borderRadius: "50%",
    padding: "",
    backgroundColor: "rgba(0,0,0, .5)",
    placeholder: null
};


export default AvatarUploader;
