import React from "react";
import PropTypes from "prop-types";
import Section from "components/layouts/dashboard/ui/section";
import { Grid } from "@mui/material";
import Form from "@acromove/components/form";
import AsyncAutocomplete from "@acromove/components/form/autocomplete";
import { useLazyFindCitiesInCountryQuery, useLazyFindCountriesQuery } from "redux/locations/locations.api";

const MapSection = (props) => {
	const {title, children, countryId, onCountryChange} = props
	return (
		<Section
			title={title}
			action={
				<Grid
					container
					sx={{
						marginLeft: "auto",
						width: "100%",
						display: "flex",
						flexDirection: "row",
						flexWrap: "nowrap",
						minWidth: "400px",
					}}
					spacing={1}
				>
					<Grid item md={6} lg={6} sx={{ minWidth: "50%" }}>
						<Form.Field name="country">
							<AsyncAutocomplete
								size="small"
								sx={{ width: "100%", backgroundColor: "background.paper" }}
								useLazyLoad={useLazyFindCountriesQuery}
								onChange={onCountryChange}
							/>
						</Form.Field>
					</Grid>
					<Grid item md={6} lg={6} sx={{ minWidth: "50%" }}>
						<Form.Field name="city">
							<AsyncAutocomplete
								key={countryId}
								size="small"
								disabled={!countryId}
								sx={{ width: "100%", backgroundColor: "background.paper" }}
								useLazyLoad={useLazyFindCitiesInCountryQuery}
								loadArg={{ countryId }}
								helperText
							/>
						</Form.Field>
					</Grid>
				</Grid>
			}
		>
			{children}
		</Section>
	);
};

MapSection.propTypes = {
	title: PropTypes.string.isRequired,
	countryId: PropTypes.number.isRequired,
	children: PropTypes.node,
	onCountryChange: PropTypes.func
}

MapSection.defaultProps = {
	children: null,
	onCountryChange: () => {}
}

export default MapSection;
