import React, { useState } from "react";
import PropTypes from "prop-types";

/* MUI */
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

/* Components */
import PickerDialog from "./PickerDialog";

/* Utils */
import { DEFAULT_CONVERTER, converters } from "./transformers";
import useFiled from "@acromove/components/form/hooks/use-filed";
import sh from "@acromove/utils/styles-helpers";

/**
 * Color Picker Component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Color Picker.
 */

const ColorPicker = (props) => {
	const { convert, name: fieldName, helperText, label, control, disabled, size = "medium" } = props;
	const [showPicker, setShowPicker] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const field = useFiled({
		fromProps: {
			label,
			name: fieldName,
			disabled,
			helperText,
			control,
		},
	});

	const { onChange, onBlur, name, value, ref } = field.controller.field;
	const { error } = field.controller.fieldState;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		if (!field.disabled) {
			setShowPicker(true);
		}
	};

	return (
		<>
			<Box sx={[sh.flex("row", "flex-start", "flex-start"), { width: "100%" }]}>
				<TextField
					sx={{width: "100%"}}
					name={name}
					id={name}
					error={!!error}
					label={field.label}
					disabled={field.disabled}
					value={value}
					onBlur={onBlur}
					helperText={field.helperTextMsg}
					ref={ref}
					onChange={onChange}
					inputProps={{
						maxLength: 7,
					}}
					InputProps={{ //eslint-disable-line
						endAdornment: (
							<InputAdornment position="end">
								<Box
									onClick={handleClick}
									sx={{
										width: 25,
										height: 25,
										bgcolor: value,
										border: "1px solid",
										borderRadius: "8px",
										borderColor: "text.secondary",
										cursor: "pointer"
									}}
								/>
							</InputAdornment>
						),
					}}
				/>
				{(field.helperText || helperText) && (
					<IconButton
						sx={{
							mt: 1,
							ml: 1,
						}}
						size={size}
						color="primary"
						onClick={() => field.setShowHelper(!field.showHelper)}
					>
						<InfoOutlinedIcon />
					</IconButton>
				)}
			</Box>
			{showPicker && (
				<PickerDialog
					value={value}
					open={showPicker}
					anchor={anchorEl}
					onClick={() => {
						setShowPicker(false);
						onChange(value);
					}}
					onChange={(c) => {
						const newValue = converters[convert](c);
						onChange({
							...newValue,
							target: {
								value: newValue,
							},
						});
					}}
				/>
			)}
		</>
	);
};

/**
 * Color Picker Properties.
 *
 * @typedef {object} Props
 * @property {string} label - Color Picker Label.
 * @property {true | false} [disabled = false] - disable/enable prop.
 * @property {Object} [convert = DEFAULT_CONVERTER] - Color Converter.
 * @property {string} name - Form controlled Color Picker name.
 * @property {func} control - control.
 * @property {string} [helperText = ""] - helper text of text field element.
 * @property {string} [defaultValue = "#000000"] - default color of color picker.
 * @property {string} [size = "medium"] - input size.
 * @property {true | false} [disabled = false] defines if component enabled or disabled for editing.
 */

ColorPicker.propTypes = {
	label: PropTypes.string,
	convert: PropTypes.oneOf(Object.keys(converters)),
	name: PropTypes.string,
	control: PropTypes.shape({}),
	helperText: PropTypes.string,
	size: PropTypes.string,
	disabled: PropTypes.bool,
};

ColorPicker.defaultProps = {
	label: "",
	name: "",
	control: {},
	convert: DEFAULT_CONVERTER,
	helperText: "",
	size: "medium",
	disabled: false,
};

export default ColorPicker;
