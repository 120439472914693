import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import LoadingButton from "@mui/lab/LoadingButton";

/* Common Components */
import Input from "@acromove/components/form/input";
import Form from "@acromove/components/form";
import ColorPicker from "@acromove/components/form/color-picker";
import AvatarUploader from "@acromove/components/form/avatar-uploader";
import SitePreview from "components/content/clients/group-details-components/site-preview";

/* Utils */
import { useTranslation } from "react-i18next";
import AsyncAutocomplete from "@acromove/components/form/autocomplete";
import { useLazyFindGroupTypesQuery } from "redux/group-types/group-types.api";
import ProtectedComponent from "@acromove/authentication/components/protected/component";
import logo from "assets/images/logo.svg";
import Switch from "@acromove/components/form/switch";

const GroupForm = (props) => {
	const { mode, submit, isLoading, editMode, form } = props;
	const { t } = useTranslation("clients_groups_info");

	const watchLogo = form.watch("logo");
	const watchAccentColor = form.watch("colors.accentColor");
	const watchNavBg = form.watch("colors.navigationBg");
	const watchNavFontColor = form.watch("colors.navigationFontColor");
	const watchNavDivider = form.watch("colors.navigationDivider");

	return (
		<>
			<Form
				name="groups"
				i18n="clients_groups_info"
				mode={mode}
				form={form}
				isLoading={isLoading}
				disabled={mode === "edit" && !editMode}
				sx={{ display: "flex", flexDirection: "row", mt: 6 }}
			>
				<Grid container gap={4} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
					<Form.Field name="name">
						<Grid item md={12}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Grid>
					</Form.Field>
					<Form.Field name="description">
						<Grid item md={12}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Grid>
					</Form.Field>
					<Grid container gap={2} sx={{display: "flex", flexDirection: "row", flexWrap: "nowrap", alignItems: "center"}}>
						<Form.Field name="type_id">
							<Grid item md={10}>
								<AsyncAutocomplete
									size="medium"
									sx={{ width: "100%" }}
									useLazyLoad={useLazyFindGroupTypesQuery}
								/>
							</Grid>
						</Form.Field>
						<Form.Field name="disabled">
							<Grid item md={1}>
								<Switch />
							</Grid>
						</Form.Field>
					</Grid>
					{mode === "edit" && (
						<Grid container gap={2} sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
							<Grid item md={3} gap={2} sx={{ display: "flex", flexDirection: "column" }}>
								<Grid item md={12}>
									<Form.Field name="logo">
										<AvatarUploader
											direction="column"
											editText={t("groups.logo.buttonText")}
											startIcon={<BorderColorIcon />}
											sx={{ width: "100%" }}
											padding="8px"
											width="100%"
											height="60px"
											borderRadius="8px"
											fontSize={24}
											backgroundColor={watchNavBg}
											alt=""
											placeholder={logo}
										/>
									</Form.Field>
								</Grid>
								<Grid item md={12}>
									<Form.Field name="colors.accentColor">
										<ColorPicker />
									</Form.Field>
								</Grid>
								<Grid item md={12}>
									<Form.Field name="colors.navigationBg">
										<ColorPicker />
									</Form.Field>
								</Grid>
								<Grid item md={12}>
									<Form.Field name="colors.navigationFontColor">
										<ColorPicker />
									</Form.Field>
								</Grid>
								<Grid item md={12}>
									<Form.Field name="colors.navigationDivider">
										<ColorPicker />
									</Form.Field>
								</Grid>
							</Grid>
							<ProtectedComponent slug={`groups:logo:${mode}`} field>
								<Grid item md={9}>
									<SitePreview
										disabled={!editMode}
										logo={watchLogo}
										accentColor={watchAccentColor}
										navBg={watchNavBg}
										navFontColor={watchNavFontColor}
										navDivider={watchNavDivider}
									/>
								</Grid>
							</ProtectedComponent>
						</Grid>
					)}
				</Grid>
			</Form>
			{editMode && (
				<LoadingButton
					loading={isLoading}
					startIcon={<SaveAsIcon />}
					type="submit"
					variant="contained"
					onClick={submit}
					sx={{ marginLeft: "auto", mt: 1, display: "flex", alignSelf: "flex-end" }}
				>
					{mode === "edit" ? t("save") : t("save_create")}
				</LoadingButton>
			)}
		</>
	);
};

GroupForm.propTypes = {
	mode: PropTypes.string.isRequired,
	submit: PropTypes.func,
	isLoading: PropTypes.bool.isRequired,
	editMode: PropTypes.bool.isRequired,
	form: PropTypes.shape({
		watch: PropTypes.func,
	}).isRequired,
};

GroupForm.defaultProps = {
	submit: () => null,
};

export default GroupForm;
