import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

/* MUI */
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";
import Description from "@mui/icons-material/Description";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";

/* Components */
import EmptyComponent from "@acromove/components/table/empty";
import StatusStat from "@acromove/components/statistics/StatusStat";
import Search from "@acromove/components/form/search";
import SortDown from "./ui/sort-down";
import SortUp from "./ui/sort-up";

/* Utils */
import { tableStatusMap } from "@acromove/components/statistics/status-map";
import { dataContext } from "@acromove/components/data-grid/data-grid-provider";
import useLocalUserSettings from "@acromove/hooks/use-local-user-settings"
import { useMediaQuery } from "@mui/material";
import _ from "lodash";

const StyledDataGrid = styled(DataGridPro)`
	.MuiDataGrid-cell {
		outline: none !important;
	}
	&.MuiDataGrid-root {
		outline: none;
	}
	,
	&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
	&.MuiDataGrid-root .MuiDataGrid-cell:focus {
		outline: none;
	}
	.MuiDataGrid-columnHeader {
		outline: none !important;
	}
`;

/**
 * Data Grid Table component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Data Grid.
 */
const DataGridTable = (props) => {
	const {
		search,
		sx,
		columns,
		data,
		pageSize,
		page,
		pageSizeOptions,
		onPageSizeChange,
		onPageChange,
		onSearch,
		total,
		action,
		checkboxSelection,
		loading,
		title,
		emptyText,
		emptyIcon,
		line,
		status,
		statusText,
		noCardShadow,
		onSelect,
		onUpdate,
		selected,
		handleSortModelChange,
		collapsible,
		pinnedColumns,
		sortDir,
		sortItem,
		searchText,
		paginationMode,
		sortingMode,
		rowIdPath,
		notices = null,
	} = props;

	const [open, setOpen] = useLocalUserSettings(title, true);
	const isDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

	const handleSelect = (current) => {
		const event = {
			action: "",
			id: "",
		};
		for (const item of current) {
			if (!selected.includes(item)) {
				event.action = "add";
				event.id = item;
			}
		}

		for (const item of selected) {
			if (!current.includes(item)) {
				event.action = "remove";
				event.id = item;
			}
		}
		onSelect(event);
	};

	useEffect(() => {
		if (onUpdate) {
			onUpdate(data);
		}
	}, [data]);

	return (
		<Grid container sx={{ mb: 1, ...sx, position: "relative", width: "100%" }}>
			{line && (
				<Box
					sx={{
						position: "absolute",
						left: 0,
						top: "50%",
						transform: "translateY(-50%)",
						borderRadius: "0px 2px 2px 0px",
						width: "5px",
						height: "80%",
						backgroundColor:
							status === "review" ? tableStatusMap[status].backgroundColor : tableStatusMap[status].color,
					}}
				/>
			)}
			<Card sx={{ width: "100%", boxShadow: noCardShadow && "0 0 0 0"}}>
				{(title || action) && (
					<CardHeader
						action={
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									// justifyContent: "flex-end",
									alignItems: "center",
									gap: 1,
								}}
							>
								{(search && !isDownSm) && <Search onSearch={onSearch} value={searchText} />}
								{action}
								{status && <StatusStat type="table" status={status} statusText={statusText} />}
								{collapsible ? (
									<IconButton size="small" onClick={() => setOpen(!open)}>
										{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									</IconButton>
								) : null}
							</Box>
						}
						title={title}
						sx={{
							bgcolor: "#F4F6F9",
							mt: 2,
							mb: 1,
							ml: 1,
							mr: 1,
							padding: "8px 16px",
							borderRadius: "6px",
							// flexDirection: isDownSm ? "column" : "row",
						}}
					/>
				)}
				{notices}
				<Collapse in={open}>
					<Grid container sx={{ p: 1, width: "100%" }}>
						<Grid item xs={12} sm={12} md={12} lg={12} sx={{ width: "100%" }}>
							<Box sx={{ width: "100%" }}>
								{(search && isDownSm) && <Search sx={{width: "100%", minWidth: "100% !important"}} onSearch={onSearch} value={searchText} />}
								<StyledDataGrid
									rows={data}
									getRowId={(row) => _.get(row, rowIdPath)}
									columns={columns}
									pageSize={pageSize}
									onPageChange={onPageChange}
									page={page}
									onPageSizeChange={onPageSizeChange}
									rowsPerPageOptions={pageSizeOptions}
									rowCount={total}
									loading={loading}
									checkboxSelection={checkboxSelection}
									keepNonExistentRowsSelected
									autoHeight
									disableSelectionOnClick
									disableChildrenFiltering
									disableColumnMenu
									disableColumnFilter
									disableMultipleColumnsFiltering
									pagination={total > 5}
									paginationMode={paginationMode}
									sortingMode={sortingMode}
									sortingOrder={["desc", "asc"]}
									onSortModelChange={handleSortModelChange}
									onSelectionModelChange={handleSelect}
									initialState={{
										sorting: {sortModel: [{ field: sortItem, sort: sortDir.toLowerCase() }]},
										pinnedColumns: {
											left: pinnedColumns.left,
											right: pinnedColumns.right
										}
									}}
									selectionModel={selected}
									components={{
										ColumnSortedAscendingIcon: SortDown,
										ColumnSortedDescendingIcon: SortUp,
										ColumnUnsortedIcon: null,
									}}
								>
									{!loading && !data.length && (
										<EmptyComponent emptyText={emptyText} emptyIcon={emptyIcon} />
									)}
								</StyledDataGrid>
							</Box>
						</Grid>
					</Grid>
				</Collapse>
			</Card>
		</Grid>
	);
};

/**
 * Data Grid Table Properties.
 *
 * @typedef {object} Props
 * @property {object[]} columns - Array of Collumns
 * @property {object[]} data - Table data / rows.
 * @property {number} [pageSize = "5"] - Ammount of elements displayed in one Table Page.
 * @property {number} [page = "1"] - Index of Table Page.
 * @property {string} [sortItem = "id"] - Item that the table can be sorted by.
 * @property {"desc" | "asc"} [sortDer = "desc"] - Direction of sorting.
 * @property {React.ReactNode} [action = ""] - Element added to the Table Header.
 * @property {number[]} [pageSizeOptions = [5, 10, 15]] - Page Sizing Options.
 * @property {function} [onPageSizeChange = () => {}] - Event triggered on Page size change.
 * @property {function} [onPageChange = () => {}] - Event triggered on Page change.
 * @property {number} total - Total items in Table.
 * @property {true | false} [loading = "false"] - Loading status.
 * @property {string} [emptyText = "No Data"] - Text Displayed when Table is Empty,
 * @property {React.ReactNode} [emptyIcon = DescriptionIcon<Object<{sx:{"text.secondary"}}>>] - Icon for Empty Table.
 * @property {object} [sx = {}] - Table container CSS properties.
 * @property {string} [status = ""] - Table Item Status.
 * @property {true | false} [line = "false"] - Table Status displaying line.
 * @property {string} [statusText = ""] - Text describing status of current Table.
 * @property {true | false} [noCardShadow = "false"] - Property for displaying/hiding Card Shadow.
 * @property {true | false} [checkboxSelection = "false"] - Property passed if selectable checkbox table is needed.
 * @property {true | false} [collapsible = "false"] - Property to define if data grid section is collapsible.
 */

DataGridTable.propTypes = {
	title: PropTypes.string,
	columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	pageSize: PropTypes.number,
	page: PropTypes.number,
	action: PropTypes.node,
	pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
	onPageSizeChange: PropTypes.func,
	onPageChange: PropTypes.func,
	onSearch: PropTypes.func,
	total: PropTypes.number.isRequired,
	loading: PropTypes.bool,
	emptyText: PropTypes.string,
	emptyIcon: PropTypes.node,
	sx: PropTypes.shape({}),
	status: PropTypes.string,
	line: PropTypes.bool,
	statusText: PropTypes.string,
	noCardShadow: PropTypes.bool,
	checkboxSelection: PropTypes.bool,
	onSelect: PropTypes.func,
	onUpdate: PropTypes.func,
	selected: PropTypes.arrayOf(PropTypes.string),
	search: PropTypes.bool,
	handleSortModelChange: PropTypes.func,
	collapsible: PropTypes.bool,
	sortItem: PropTypes.string.isRequired,
	sortDir: PropTypes.string,
	paginationMode: PropTypes.string,
	sortingMode: PropTypes.string,
	pinnedColumns: PropTypes.shape({
		left: PropTypes.arrayOf(PropTypes.string),
		right: PropTypes.arrayOf(PropTypes.string),
	}),
	searchText: PropTypes.string.isRequired,
	rowIdPath: PropTypes.string,
	notices: PropTypes.node
};

DataGridTable.defaultProps = {
	title: "",
	pageSize: 5,
	page: 1,
	action: "",
	pageSizeOptions: [5, 10, 15],
	onPageSizeChange: () => {},
	onPageChange: () => {},
	onSearch: () => {},
	loading: false,
	emptyText: "No Data",
	emptyIcon: <Description sx={{ color: "text.secondary" }} />,
	sx: {},
	status: "",
	line: false,
	statusText: "",
	noCardShadow: false,
	checkboxSelection: false,
	onSelect: () => null,
	onUpdate: () => null,
	selected: [],
	search: false,
	handleSortModelChange: () => null,
	collapsible: false,
	pinnedColumns: {
		left: [],
		right: []
	},
	sortDir: "ASC",
	paginationMode: "server",
	sortingMode: "server",
	rowIdPath: "id",
	notices: null
};

const Wrapper = (props) => {
	const fromDataContext = useContext(dataContext);
	return <DataGridTable {...{ ...props, ...fromDataContext }} />;
};

export default Wrapper;
