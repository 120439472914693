import React, { useState } from "react";

/* MUI */
import Button from "@mui/material/Button";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CancelIcon from '@mui/icons-material/Cancel';

/* Components */
import Section from "components/layouts/dashboard/ui/section";
import Input from "@acromove/components/form/input";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@acromove/utils/validators";
import { useTranslation } from "react-i18next";

/* Redux */
import { useUpdateMyPasswordMutation } from "@acromove/redux/auth/api";

const PasswordChange = () => {
	const { t } = useTranslation("user_profile");
	const [updatePassword, { isLoading: loading }] = useUpdateMyPasswordMutation();
	const [editMode, setEditMode] = useState(false);
	const schema = yup.object({
		password: yup.string().required(t("passwordChange.form.password.required")),
		confirmPassword: yup
			.string()
			.equalTo(yup.ref("password"), t("passwordChange.form.confirmPassword.equalTo"))
			.required(t("passwordChange.form.confirmPassword.required")),
	});

	const { control, handleSubmit, formState, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const { errors } = formState;

	const onSubmit = async ({ password }) => {
		await updatePassword({ password });
		reset();
	};

	const handleCancelEdit = () => {
		if (!editMode) {
			setEditMode(true);
		} else if (editMode) {
			reset();
			setEditMode(false);
		}
	};

	const handleSaveEdit = () => {
		if (editMode) {
			handleSubmit(onSubmit)();
			if (!Object.keys(errors).length) {
				setEditMode(!editMode);
			}
			return;
		}
		setEditMode(!editMode);
	};

	return (
		<Section
			loading={loading}
			title={t("passwordChange.title")}
			action={
				<Button onClick={handleCancelEdit} startIcon={editMode ? <CancelIcon /> : <BorderColorIcon />}>
					{editMode ? t("btns.cancel") : t("btns.edit")}
				</Button>
			}
		>
			<Box component="form">
				<Grid container gap={4}>
					<Grid item md={8}>
						<Grid container gap={3}>
							<Grid item md={4}>
								<Input
									disabled={!editMode}
									sx={{ width: "100%" }}
									control={control}
									name="password"
									label={t("passwordChange.form.password.label")}
									type="password"
								/>
							</Grid>
							<Grid item md={4}>
								<Input
									disabled={!editMode}
									sx={{ width: "100%" }}
									control={control}
									name="confirmPassword"
									label={t("passwordChange.form.confirmPassword.label")}
									type="password"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{editMode && (
					<Button
						startIcon={<SaveAsIcon />}
						type="submit"
						variant="contained"
						onClick={handleSaveEdit}
						sx={{ marginLeft: "auto", mt: 1, display: "flex", alignSelf: "flex-end" }}
					>
						{t("btns.save")}
					</Button>
				)}
			</Box>
		</Section>
	);
};

export default PasswordChange;
