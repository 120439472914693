import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { Tooltip } from "react-leaflet";

const PointTooltip = (props) => {
	const { items, disabled } = props;
	if(disabled) return null;
	return (
		<Tooltip>
			<Box>
				{
					items.map((item, i) => (
						<React.Fragment key={Object.values(item).join()}>
							{item.label}: {item.value}
							{i+1 !== items.length ? <br /> : ""}
						</React.Fragment>
					))
				}
			</Box>
		</Tooltip>
	);
};

PointTooltip.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		})
	),
	disabled: PropTypes.bool
}

PointTooltip.defaultProps ={
	items: [],
	disabled: false
}

export default PointTooltip;
