import helpers from "general/helpers"

export default (color) => helpers.getUrlForSvgString(`
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="10" fill="${color}"/>
<path d="M13.3657 6.36719C12.4534 6.55768 11.476 6.67044 10.4688 6.69726V9.47905H13.7658C13.7354 8.38336 13.5987 7.32995 13.3657 6.36719Z" fill="white"/>
<path d="M5.29616 9.47851C5.32707 8.30907 5.4734 7.18047 5.72418 6.14457C4.90293 5.91404 4.15153 5.61601 3.49989 5.25781C2.60738 6.48939 2.08798 7.94312 2 9.47851H5.29616Z" fill="white"/>
<path d="M5.29616 10.418H2C2.08798 11.9534 2.60738 13.4071 3.49986 14.6387C4.1515 14.2805 4.90293 13.9824 5.72418 13.7519C5.4734 12.716 5.32704 11.5874 5.29616 10.418Z" fill="white"/>
<path d="M11.3481 2.48965C11.0614 2.24715 10.7671 2.08379 10.4688 2V5.75956C11.3893 5.73393 12.2799 5.63298 13.1101 5.46421C12.9578 4.99854 12.7807 4.56114 12.5797 4.15931C12.2188 3.43742 11.8044 2.87563 11.3481 2.48965Z" fill="white"/>
<path d="M5.97259 14.6523C5.28629 14.8451 4.65559 15.0881 4.10156 15.3768C4.17595 15.4574 4.2517 15.5371 4.32971 15.6151C5.23097 16.5164 6.30909 17.1764 7.48582 17.5631C7.15619 17.1734 6.85134 16.7029 6.578 16.1562C6.34669 15.6936 6.14439 15.1893 5.97259 14.6523Z" fill="white"/>
<path d="M7.421 4.15931C7.2201 4.56114 7.04299 4.99854 6.89062 5.46421C7.72085 5.63298 8.61142 5.73393 9.532 5.75956V2C9.23362 2.08382 8.9393 2.24715 8.65265 2.48965C8.19634 2.87563 7.78198 3.43742 7.421 4.15931Z" fill="white"/>
<path d="M14.7015 10.418C14.6705 11.5874 14.5242 12.716 14.2734 13.7519C15.0947 13.9824 15.8461 14.2805 16.4978 14.6387C17.3902 13.4071 17.9096 11.9534 17.9976 10.418H14.7015Z" fill="white"/>
<path d="M14.0211 5.24271C14.7074 5.04997 15.3381 4.80694 15.8921 4.51825C15.8177 4.43765 15.742 4.35798 15.6639 4.27997C14.7627 3.37875 13.6845 2.7187 12.5078 2.33203C12.8374 2.72164 13.1423 3.19216 13.4156 3.73882C13.6469 4.20143 13.8493 4.70574 14.0211 5.24271Z" fill="white"/>
<path d="M12.5797 15.737C12.7807 15.3352 12.9578 14.8977 13.1101 14.4321C12.2799 14.2633 11.3893 14.1623 10.4688 14.1367V17.8963C10.7671 17.8125 11.0614 17.6492 11.3481 17.4066C11.8044 17.0206 12.2188 16.4589 12.5797 15.737Z" fill="white"/>
<path d="M14.2734 6.14454C14.5242 7.18044 14.6705 8.30904 14.7015 9.47849H17.9976C17.9096 7.94309 17.3902 6.48936 16.4978 5.25781C15.8461 5.61598 15.0947 5.91401 14.2734 6.14454Z" fill="white"/>
<path d="M10.4688 10.418V13.1998C11.476 13.2266 12.4534 13.3393 13.3657 13.5298C13.5987 12.567 13.7354 11.5137 13.7658 10.418H10.4688Z" fill="white"/>
<path d="M9.53141 9.47905V6.69726C8.5242 6.67044 7.54681 6.55768 6.63445 6.36719C6.40146 7.32999 6.26475 8.38336 6.23438 9.47905H9.53141Z" fill="white"/>
<path d="M5.97259 5.24271C6.14436 4.70571 6.34669 4.20143 6.578 3.73882C6.85134 3.19216 7.15619 2.72161 7.48582 2.33203C6.30912 2.7187 5.23097 3.37875 4.32971 4.28001C4.2517 4.35802 4.17595 4.43768 4.10156 4.51828C4.65559 4.80694 5.28629 5.04997 5.97259 5.24271Z" fill="white"/>
<path d="M14.021 14.6523C13.8493 15.1893 13.6469 15.6936 13.4156 16.1562C13.1423 16.7029 12.8374 17.1734 12.5078 17.5631C13.6845 17.1764 14.7627 16.5164 15.6639 15.6151C15.7419 15.5371 15.8177 15.4574 15.8921 15.3768C15.338 15.0881 14.7073 14.8451 14.021 14.6523Z" fill="white"/>
<path d="M9.53141 10.418H6.23438C6.26475 11.5137 6.40146 12.5671 6.63445 13.5298C7.54678 13.3393 8.5242 13.2266 9.53141 13.1998V10.418Z" fill="white"/>
<path d="M8.65265 17.4066C8.9393 17.6491 9.23365 17.8125 9.532 17.8963V14.1367C8.61142 14.1623 7.72085 14.2633 6.89062 14.4321C7.04299 14.8977 7.22007 15.3352 7.421 15.737C7.78198 16.4589 8.19634 17.0206 8.65265 17.4066Z" fill="white"/>
</svg>
`)