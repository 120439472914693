import { configureStore, combineReducers } from "@reduxjs/toolkit";
import app from "./app.slice";
import auth from "@acromove/redux/auth/slice";
import authAPI from "@acromove/redux/auth/api";
import uploadsAPI from "@acromove/redux/uploads/api";
import notificationsAPI from "./notifications/notification.api";
import clientsAPI from "./clients/clients.api"
import groupTypesAPI from "./group-types/group-types.api"
import mw from "@acromove/utils/redux/mw"
import i18n from "../i18n";
import themeAPI from "./theme/theme.api";
import locationsAPI from "./locations/locations.api";
import sitesAPI from "./sites/sites.api"
import assetsAPI from "@acromove/redux/assets/assets.api"
import clustersAPI from "./clusters/clusters.api";
import machinesAPI from "./machines/machines.api";

const rootReducer = combineReducers({
    app,
    auth,
    [authAPI.reducerPath]: authAPI.reducer,
    [assetsAPI.reducerPath]: assetsAPI.reducer,
	[sitesAPI.reducerPath]: sitesAPI.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [uploadsAPI.reducerPath]: uploadsAPI.reducer,
    [themeAPI.reducerPath]: themeAPI.reducer,
    [notificationsAPI.reducerPath]: notificationsAPI.reducer,
    [clientsAPI.reducerPath]: clientsAPI.reducer,
    [groupTypesAPI.reducerPath]: groupTypesAPI.reducer,
    [locationsAPI.reducerPath]: locationsAPI.reducer,
    [clustersAPI.reducerPath]: clustersAPI.reducer,
    [machinesAPI.reducerPath]: machinesAPI.reducer
})

const MWs = [
    mw.rtkQueryErrorLogger(i18n, "api_responses", "error.validation"),
    mw.rtkQuerySuccessLogger(i18n, "api_responses"),
    notificationsAPI.middleware,
    clientsAPI.middleware,
    groupTypesAPI.middleware,
    themeAPI.middleware,
    locationsAPI.middleware,
	sitesAPI.middleware,
	assetsAPI.middleware,
    clustersAPI.middleware,
    machinesAPI.middleware
]

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(MWs),

})

// socket.on("events", () => store.dispatch(notificationsAPI.util.invalidateTags(["ws:events"])))

window.store = store

export default store;
