import constants from "general/constants";

const getInitials = (firstname = "", lastname = "") => {
	const firstName = firstname.slice(0, 1).toUpperCase();
	const lastName = lastname.slice(0, 1).toUpperCase();
	return `${firstName}${lastName}`;
};

const getApiURL = (uri) => `${constants.API_BASE_URL}${uri}`;

const sleep = (ms) =>
	new Promise((resolve) => {
		setTimeout(() => resolve(true), ms);
	});

export const getDifference = (array1, array2) =>
	array1.filter((object1) => !array2.some((object2) => object1.id === object2.id));

const findAverageLocation = (acc, d, i, arr) => {
	const current = acc;
	const [lat, long] = d || [0, 0];
	if (!d || !lat || !long) {
		if (i === arr.length - 1 && current[2]) {
			return current.map((sum) => sum / (current[2] - 1));
		}
		return current;
	}
	current[0] += lat;
	current[1] += long;
	current[2] = (current[2] || 1) + 1;
	if (i === arr.length - 1) {
		return current.map((sum) => sum / (current[2] - 1));
	}
	return current;
};

const getUrlForSvgString = (svgString) => {
	const blob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
	return URL.createObjectURL(blob);
};

const sliceIndexes = (s) => {
	const bracketIndx = s.search(/\[\d/);
	if (bracketIndx === -1) {
		return s;
	}
	const formatted = s.slice(0, bracketIndx + 1) + s.slice(bracketIndx + 2);
	return sliceIndexes(formatted);
};

const truncateString = (string, lengthToTrunc) => {
	if (string.length <= lengthToTrunc) {
		return string;
	}

	const findIndex = string.lastIndexOf("," || " ", lengthToTrunc);
	return `${string.slice(0, findIndex)}...`;
};

export default {
	getInitials,
	sliceIndexes,
	getApiURL,
	sleep,
	getDifference,
	findAverageLocation,
	getUrlForSvgString,
	truncateString,
};
