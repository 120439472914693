/* eslint-disable */
import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import TreeViewProvider from "@acromove/components/tree-view";
import Dialog from "@acromove/components/dialog";
import { useTranslation } from "react-i18next";
import useAuth from "@acromove/authentication/hooks/use-auth";
import { useNavigate } from "react-router-dom";

/* Redux */
import { useFindOneGroupQuery, useLazyFindChildGroupsQuery, useAssignOneUserMutation } from "redux/clients/clients.api";

const AssignToNested = (props) => {
    const { open, onClose, user } = props;
	const [tableKey, setTableKey] = useState("root");
	const auth = useAuth();
	const authKey = auth.scope._key;
	const defineKey = tableKey === "root" ? authKey : tableKey;
    const [assignOneUser] = useAssignOneUserMutation()
    
	const navigate = useNavigate();

	const { t } = useTranslation("clients_groups_info");

	const { data, refetch, isLoading } = useFindOneGroupQuery({ key: defineKey });

	const onSelect = (key) => {
		setTableKey(key);
	};

    const onUserAssign = (values) => {
		assignOneUser({userKey: values.user, groupKey: values.group})
	}

	const handleClose = (isAgree) => {
		if (isAgree && defineKey === authKey) {
            onClose()
		} 
        if (isAgree) {
            onUserAssign({user, group: parseInt(defineKey, 10)})
            navigate(-1);
            onClose();
        }
        else
        navigate(-1);
		onClose()
	};


	useEffect(() => {
		refetch();
	}, [tableKey, defineKey, data]);

	return (
			<Dialog
				labelCancel={t("discard")}
				labelOk={t("submit")}
				loading={isLoading}
				maxWidth="sm"
				fullWidth
				open={open}
				onClose={handleClose}
				title={t("assign_user")}
			>
				<TreeViewProvider
					onSelect={onSelect}
					name={auth.scope.name}
					sx={{ height: 500, flexGrow: 1, minWidth: 400, maxWidth: 400, overflowY: "auto" }}
					formateData={(item) => ({ name: item.name, id: item._key })}
					useLazyLoadChild={useLazyFindChildGroupsQuery}
				/>
				{defineKey === authKey ? "" : <Typography>{t("assign")} <strong>{user}</strong> {t("to")} <strong>{data?.name}</strong> {t("group")}</Typography>}
			</Dialog>
	);
};

export default AssignToNested;
