/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Popover from "@mui/material/Popover";
import { Box } from "@mui/material";
import { ChromePicker } from "react-color";

const PickerDialog = (props) => {
	const { value, onClick, onChange, open, anchor } = props;

	return (
		<Popover
			open={open}
			onClose={onClick}
			anchorEl={anchor}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<Box sx={{p: 1}}>
				<ChromePicker color={value} onChange={onChange} />
			</Box>
		</Popover>
	);
};

PickerDialog.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
};

export default PickerDialog;
