import React from "react";
import PropTypes from "prop-types";

/* Components */
import NetworkItem from "./items/network"

/* MUI */
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

/* Utils */
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useFormContext from "@acromove/components/form/hooks/use-form-context";

const Networks = ({spaceIndx}) => {
	const { t } = useTranslation("sites_create", { keyPrefix: "sites.spaces[].networks[]" });
	const {form} = useFormContext()

	const watchLabel = (index) => form.watch(`spaces[${spaceIndx}].networks[${index}].label`);
	const watchBackhaul = (index) => form.watch(`spaces[${spaceIndx}].networks[${index}].backhaul_type`);

	const { fields = [], append, remove } = useFieldArray({ control: form.control, name: `spaces[${spaceIndx}].networks`, keyName: "uid" });

	return (
		<>
			<Button
				variant="contained"
				onClick={() =>
					append({
						label: "",
						backhaul_type: "fiber_optic",
						bandwidth: "",
						provider: "",
						coverage_area: "",
						upload: "",
						download: "",
						latency: "",
						images: [], 
						$removedImages: []
					})
				}
			>
				<AddIcon sx={{ mr: 1 }} />
				{t("add")}
			</Button>
			<>
				{fields.map((field, index) => (
					<NetworkItem 
						t={t}
						field={field} 
						watchLabel={watchLabel} 
						spaceIndx={spaceIndx} 
						index={index}
						remove={remove}
						watchBackhaul={watchBackhaul}
					/>
				))}
			</>
		</>
	);
};

Networks.propTypes = {
	spaceIndx: PropTypes.number.isRequired
}


export default Networks;
