import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import { CircleMarker } from "react-leaflet";
import PointTooltip from "./point-tooltip";

const AreaMarker = (props) => {
	const { position, slug, icon, radius, tooltip, disabled, disabledTooltip, onClick} = props;
	const theme = useTheme()
	if(disabled) return null;
	return (
		<CircleMarker
			eventHandlers={{click: onClick}}
			key={`${slug}_${Object.values(position).join()}`}
			icon={icon}
			center={position}
			radius={radius}
			pathOptions={{
				color: theme.palette.primary.main,
			}}
		>
			<PointTooltip items={tooltip} disabled={!disabledTooltip} />
		</CircleMarker>
	);
};

AreaMarker.propTypes = {
    disabledTooltip: PropTypes.bool.isRequired,
	slug: PropTypes.string.isRequired,
	position: PropTypes.arrayOf(PropTypes.number).isRequired,
	icon: PropTypes.string.isRequired,
	radius: PropTypes.number,
	tooltip: PropTypes.shape({
		site: PropTypes.string,
		state: PropTypes.string
	}).isRequired,
	disabled: PropTypes.bool.isRequired,
	onClick: PropTypes.func
}

AreaMarker.defaultProps = {
	onClick: () => {},
	radius: 0
}

export default AreaMarker;
