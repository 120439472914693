import SmartRouter from "@acromove/utils/smart-router";

import DashboardLayout from "components/layouts/dashboard";

import AcroAdminDashboardPage from "pages/acromove";
import AcroAdminGroupTypesPage from "pages/acromove/group-types";
import AcroAdminGroupTypePage from "pages/acromove/group-type";

import AcromoveSmallLogo from 'components/icons/smallLogo';

const router = new SmartRouter();

// router.addRedirect("/acromove", "/acromove/dashboard")
router.addRedirect("/acromove", "/acromove/group_types")

router.addRoute("/acromove", {
    title: "acromove",
    Container: AcroAdminDashboardPage, 
    Layout: DashboardLayout, 
    accessSlug: "acromove", 
    icon: AcromoveSmallLogo,
})

// router.addRoute("/acromove/dashboard", {
//     title: "acromove_dashboard",
//     Container: AcroAdminDashboardPage, 
//     Layout: DashboardLayout, 
//     accessSlug: "acromove_dashboard", 
//     childOf: "/acromove"
// })

router.addRoute("/acromove/group_types", {
    title: "acromove_group-types",
    Container: AcroAdminGroupTypesPage, 
    Layout: DashboardLayout, 
    accessSlug: "acromove_group-types", 
    childOf: "/acromove"
})

router.addRoute("/acromove/group_types/view/:groupTypeId", {
    title: "acromove_group-types_view",
    Container: AcroAdminGroupTypePage, 
    Layout: DashboardLayout, 
    accessSlug: "acromove_group-types", 
    ignoreOnNav: true,
})

export default router;