import React, { useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Marker } from "react-leaflet";
import L from "leaflet";
import gpsPointIcon from "assets/icons/gps_point.png";

const DraggableMarker = (props) => {
    const {onChange, defPos} = props

    const markerIcon = new L.Icon({
        iconAnchor: [20, 40],
        popupAnchor: [0, -35],
        iconSize: [35, 35],
        iconUrl: gpsPointIcon,
        iconRetinaUrl: gpsPointIcon,
    });

	const [position, setPosition] = useState(defPos);
	const markerRef = useRef(null);
	const eventHandlers = useMemo(
		() => ({
			dragend() {
				const marker = markerRef.current;
				if (marker != null) {
					setPosition(marker.getLatLng());
                    onChange(marker.getLatLng())
				}
			},
		}),
		[]
	);

	return (
		<Marker icon={markerIcon} draggable eventHandlers={eventHandlers} position={position} ref={markerRef} />
	);
};

DraggableMarker.propTypes = {
	defPos: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.number),
		PropTypes.shape({
			lat: PropTypes.number,
			lng: PropTypes.number
		})
	]).isRequired,
	onChange: PropTypes.func.isRequired
}

export default DraggableMarker;
