
const typePermissions = [
	{ name: "groupsAddBaseFields", alias: "groups-add_base_fields"},
	{ name: "groupsAddWithType", alias: "groups-add_with_type"},

	{ name: "groupsEditBaseFields", alias: "groups-edit_base_fields"},
	{ name: "groupsEditWithTheme", alias: "groups-edit_with_theme"},
	{ name: "groupsEditWithType", alias: "groups-edit_with_type"},
	{ name: "groupsEditDisabled", alias: "groups-edit-disable"},

	{ name: "usersUnassignFromGroup", alias: "users-unassign-from-group"},
	{ name: "usersAssignToGroup", alias: "users-assign-to-group"},
	{ name: "usersAddAllFields", alias:"users-add_all_fields"},
	{ name: "usersEditAllFields", alias:"users-edit_all_fields"},
	{ name: "usersFindOne", alias:"users-findOne"},
];

export default typePermissions