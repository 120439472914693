import React from "react";
import PropTypes from "prop-types";
import CageItem from "./items/cage";

/* MUI */
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

/* Utils */
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useFormContext from "@acromove/components/form/hooks/use-form-context";


const Cages = ({spaceIndx}) => {
	const { t } = useTranslation("sites_create", { keyPrefix: "sites.spaces[].cages[]" });
	const {form} = useFormContext()

	const watchLabel = (index) => form.watch(`spaces[${spaceIndx}].cages[${index}].label`);
	const { fields = [], append, remove } = useFieldArray({ control: form.control, name: `spaces[${spaceIndx}].cages`, keyName: "uid" });

	return (
		<>
			<Button variant="contained" onClick={() => append({ label: "", capacity: "", position_label: "", images: [], $removedImages: [] })}>
				<AddIcon sx={{ mr: 1 }} />
				{t("add")}
			</Button>

				{fields.map((field, index) => (
					<CageItem
						t={t}
						field={field}
						watchLabel={watchLabel}
						spaceIndx={spaceIndx}
						index={index}
						remove={remove}
					/>
				))}
		</>
	);
};

Cages.propTypes = {
	spaceIndx: PropTypes.number.isRequired
}


export default Cages;
