import { useEffect, useState, useMemo } from "react";

const useFormNotices = (form, options) => {
	const {
		t,
		field="",
		initialPath = ""
	} = options
	const [notices, setNotices] = useState([]);

	const noticesWatch = form.watch(field ? `${field}.notices` : "notices");

	const acrocloudNotices = useMemo(() => (noticesWatch || []).filter(n => n.source === "acrocloud"), [noticesWatch])

	const translateMsg = (notice) => t(`${initialPath ? `${initialPath}.` : ""}${notice.field}.${notice.message}`)

	const updateMessage = (notice, rawNotice) => ({
		...notice,
		message: `${notice.message} & ${translateMsg(rawNotice)}`
	})

	useEffect(() => {
		if(acrocloudNotices) {
			const newNotices = [];
			for (const notice of acrocloudNotices) {
				const needleNotice = newNotices.find((e) => e.field === notice.field);
				if (needleNotice) {
					newNotices.push(updateMessage(needleNotice, notice));
				} else {
					newNotices.push({
						entity_id: notice.entity_id,
						field: notice.field,
						message: translateMsg(notice),
					});
				}
			}
			setNotices(newNotices);
		}
	}, [acrocloudNotices]);

	const getFieldPath = (notice) => field ? `${field}.${notice.field}` : notice.field

	useEffect(() => {
		for (const n of notices) {
			form.setError(getFieldPath(n), { message: n.message, type: "onChange" }, true);
		}
	}, [notices]);

	return useMemo(() => {
		const result = {};
		for (const notice of notices) {
			if(result[notice.field]){
				result[notice.field].push(notice.message)
			} else {
				result[notice.field] = [notice.message]
			}
		}
		return result
	}, [notices])
};

export default useFormNotices;
