import React, { useState } from "react";

/* Components */
import Container from "components/layouts/dashboard/ui/container";
import Section from "components/layouts/dashboard/ui/section";
import { Box, Button } from "@mui/material";
import Form from "@acromove/components/form";
import Dialog from "@acromove/components/dialog";
import AsyncAutocomplete from "@acromove/components/form/autocomplete";
import ProtectedComponent from "@acromove/authentication/components/protected/component";
import MachineCard from "components/content/clusters/machine-card";
import NoData from "components/common/no-data";
import { ReactComponent as DeviceIcon } from "assets/Device.svg";

/* Redux */
import {
	useAssignToClusterMutation,
	useLazyFindUnassignMachinesQuery,
	useRestartMachineMutation,
} from "redux/machines/machines.api";
import clustersAPI, { useFindOneClusterQuery } from "redux/clusters/clusters.api";

/* Utils */
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const ClustersViewContainer = () => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const { clusterId } = useParams();
	const cId = parseInt(clusterId.split("-")[0], 10);
	const [assignMachine] = useAssignToClusterMutation();
	const [restartMachine] = useRestartMachineMutation();
	const { data, isFetching } = useFindOneClusterQuery({ id: cId });
	const { t } = useTranslation("clusters", { keyPrefix: "view" });

	const form = useForm({
		defaultValues: {
			machine: "",
		},
	});

	const handleClose = async (isAgree) => {
		if (isAgree) {
			await assignMachine({ serial: form.getValues("machine").serial, clusterId: cId });
			dispatch(clustersAPI.util.invalidateTags(["ClustersFindOne"]));
		}
		setOpen(false);
	};

	const executeCommand = async (controlId, serial) => {
		await restartMachine({ serial, controlId });
	};

	return (
		<Container loading={isFetching} skeleton={["section:4"]}>
		<Container.Header title="Back" back={-1} />
		<Dialog open={open} onClose={handleClose} title={t("add_machine")} maxWidth="sm" sx={{ minWidth: "500px" }}>
			<Form name="machines" mode="anonymous" form={form}>
				<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
					<Form.Field name="machine">
						<AsyncAutocomplete label={t("machine.label")} useLazyLoad={useLazyFindUnassignMachinesQuery} />
					</Form.Field>
				</Box>
			</Form>
		</Dialog>
		{data?.machines.length ? (
				<Section
					title={`Machines in ${data.label}`}
					action={
						<ProtectedComponent slug="machines:assign_to_cluster">
							<Button variant="contained" onClick={() => setOpen(true)}>
								{t("add_machine")}
							</Button>
						</ProtectedComponent>
					}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: 1,
							p: 1,
							m: "0 auto",
							backgroundColor: "background.gray",
							borderRadius: "8px",
							justifyContent: "space-between",
							flexWrap: "wrap",
						}}
					>
						{(data?.machines || []).map((device) => (
							<MachineCard device={device} executeCommand={executeCommand}/>
						))}
					</Box>
				</Section>
		) : (
			<NoData
			text={t("no_machines")}
			butText={t("add_machine")}
			icon={<DeviceIcon fontSize="large" sx={{ width: "66px", height: "66px", color: "neutral.300" }} />}
			onClick={() => setOpen(true)}
			/>
			)}
		</Container>
	);
};

export default ClustersViewContainer;
