/* Assets */
import sites from "assets/as-js-string/sites";

const mapGroups = [
	{
		slug: "sites",
		icon: sites,
		points: [
			[40.43117080513673, 49.87534478991991],
			[40.44117080513673, 49.81534478991991],
		],
		tooltip: {
			site: "Site",
			state: "Active",
			cpu_load: "6/24",
			ram_load: "8/128GB",
			storage: "100/400GB"
		}
	},
];

const physicalResourcesStatsChartData = {
	series: [
		{
			color: "#4CAF50",
			data: [3350, 1840, 2254, 5780, 9349, 5241, 2770, 2051, 3764, 2385, 5912, 8323],
			name: "CPU Cores",
			slug: "cpu_cores"
		},
		{
			color: "#FF9800",
			data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35],
			name: "RAM",
			slug: "ram"
		},
		{
			color: "#F44336",
			data: [100, 122, 50, 300, 250, 400, 312, 200, 10, 60, 90, 400],
			name: "SSD",
			slug: "ssd"
		},
	],
	xaxis: {
		dataPoints: [
			"01 Jan",
			"02 Jan",
			"03 Jan",
			"04 Jan",
			"05 Jan",
			"06 Jan",
			"07 Jan",
			"08 Jan",
			"09 Jan",
			"10 Jan",
			"11 Jan",
			"12 Jan",
		],
	},
};

const physicalResourcesStats = {
	small: [
		{ slug: "sites_runing", stat: "49/50" },
		{ slug: "cluster_runing", stat: "98/100" },
	],
	big: {
		slug: "servers_running",
		stat: "5100/5200"
	}
}

const resourcesRequestsChart = [
	{
		data: [30, 40, 25, 50, 49, 21, 70, 51],
		slug: "this_week",
		color: "#13affe",
	},
	{
		data: [23, 12, 54, 61, 32, 56, 81, 19],
		slug: "last_week",
		color: "#fbab49",
	},
]

const resourcesRequestsStats = {
	installationsInProgress: {
		slug: "installations_in_progress",
		stat: "52"
	},
	newRequests: {
		slug: "new_requests",
		stat: "213"
	}
}

export default {
    mapGroups,
	physicalResourcesStatsChartData,
	physicalResourcesStats,
	resourcesRequestsChart,
	resourcesRequestsStats
}