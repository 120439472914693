import yup from "@acromove/utils/validators";

const genDraftSchema = (t) =>
	yup.object().shape({
		name: yup.string().required(t("sites.spaces[].name.required")),
		environmental: yup.object().shape({}),
		cages: yup.array().of(
			yup.object().shape({
				label: yup.string().required(t("sites.spaces[].cages[].label.required")),
			})
		),
		networks: yup.array().of(
			yup.object().shape({
				label: yup.string().required(t("sites.spaces[].networks[].label.required")),
			})
		),
		power_sources: yup.array().of(
			yup.object().shape({
				label: yup.string().required(t("sites.spaces[].power_sources[].label.required")),
			})
		),
	});

export default genDraftSchema;
