import React from "react";
import { createSvgIcon } from '@mui/material/utils';

const PhysicalRes = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="#F47C06"/>
    <path d="M13.3333 12.6667L10.6667 10V7.88C11.44 7.6 12 6.86667 12 6C12 4.89333 11.1067 4 10 4C8.89333 4 8 4.89333 8 6C8 6.86667 8.56 7.6 9.33333 7.88V10L6.66667 12.6667H4V16H7.33333V13.9667L10 11.1667L12.6667 13.9667V16H16V12.6667H13.3333Z" fill="#E0E0E0"/>
  </svg>, 'PhysicalRes');

export default PhysicalRes;
