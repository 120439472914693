import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        whitelist: ['en'],
        nonExplicitWhitelist: true,
        load: 'all',
        fallbackLng: 'en',
        // have a common namespace used around the full app
        ns: [
            "login_page",
            "reset_forget_page",
            "acro_admin_dashboard",
            "acro_admin_group-types",
            "cloud_services_create_server",
            "cloud_services_servers",
            "cloud_services",
            "cloud_services_server_info",
            "dashboard",
            "router",
            "api_responses",
            "notifications",
            "user_profile",
            "cloud_services_disks",
            "cloud_services_create_disks",
            "cloud_services_network",
            "cloud_services_create_network",
            "cloud_services_vpn",
            "cloud_services_vpn_create",
            "cloud_services_addresses",
            "cloud_services_private_network",
            "cloud_services_create_private_network",
            "cloud_services_tasks",
            "cloud_services_ssh_keys",
            "clients_users",
            "clients_users_info",
            "clients_groups",
            "clients_groups_info",
            "sites_browse",
            "sites_my",
            "sites_create",
            "sites_info",
            "billing_revenue",
            "billing_revenue_customer",
            "billing_invoices",
            "acrodcim",
            "acrodcim_devices",
            "acrodcim_devinces_info",
            "acrodcim_clusters",
            "acrodcim_clusters_create",
            "acrodcim_clusters_info",
            "acrotrace"
        ],
        defaultNS: 'main',
        debug: false,
        interpolation: {},
        react: {
            wait: true,
			useSuspense: true,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        }
    });

i18n.services.formatter.add('removeID', (value) => value.split("-").at(1));
i18n.services.formatter.add('tSelf', (value, _, options) => {
	const path = `${options.ns}:tSelfValues.${options[options.interpolationkey]}`;
	if(i18n.exists(path)) {
		return i18n.t(path)
	}
	return value;
});

export default i18n;
