import * as yup from "yup";
import isMobilePhone from "./phones"

const getFileInfo = (fileBinary) => new Promise((resolved) => {
	const info = {};
	const i = new Image()
	i.onload = () => {
		info.width = i.width;
		info.height = i.height;
		info.name = fileBinary.name;
		info.size = fileBinary.size;
		resolved(info)
	};
	i.src = URL.createObjectURL(fileBinary);
})


function ipv4(message = "Invalid IP address") {
	return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
		message,
		excludeEmptyString: true
	}).test("ip", message, value => {
		if(value === null || value === undefined || value.trim() === ""){
			return true;
		}
		return value.split(".").find(i => parseInt(i, 10) > 255) === undefined;
	});
}

function hex(message = "Invalid Hex Code") {
	return this.matches(/^#[0-9A-F]{6}$/i, {
		message,
		excludeEmptyString: true
	}).test("hex", message, value => {
		if(value === null || value === undefined || value.trim() === ""){
			return true;
		}
		return value
	});
}

function phone(message = "Invalid Phone Number") {
	return this.matches(/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm, {
		message,
		excludeEmptyString: true
	}).test("phone", message, value => {
		if(value === null || value === undefined || value.trim() === ""){
			return true;
		}
		return value
	});
}


function phoneNumber(message = "Invalid Phone Number", locales = "any", options = undefined) {
	return this.test({
		name: "phoneNumber",
		exclusive: false,
		message,
		test: (value) => isMobilePhone(value, locales, options)
	});
}



function resolution(resolutions, message = "Invalid Resolution") {
	return this.test({
		name: "file",
		exclusive: false,
		message,
		test: async (value) => {
			if (!value) return true;
			try {
				const { width, height } = resolutions;
				const info = await getFileInfo(value);
				if (info.width !== width || info.height !== height) {
					return false
				}
			} catch (error) {
				console.log(error);
			}
			return true;
		}
	});
}

function ratioOneToOne(message = "Ratio should be 1:1") {
	return this.test({
		name: "file",
		exclusive: false,
		message,
		test: async (value) => {
			if (!value) return true;
			try {
				const info = await getFileInfo(value);
				if (info.width !== info.height) {
					return false
				}
			} catch (error) {
				console.log(error);
			}
			return true;
		}
	});
}

function fileSize(size = null, message = "Invalid size") {
	return this.test({
		name: "file",
		exclusive: false,
		message,
		test: (value) => {
			if (!value) return true;
			try {
				if (value.size > size * 1000) {
					return false
				}
			} catch (error) {
				console.log(error);
			}
			return true;
		}
	});
}

function file(ext, message = "Invalid extensions") {
	return this.test({
		name: "file",
		exclusive: false,
		message: message || `Selected file ext should be one of ${ext.join(", ")}`,
		test: (value) => {
			if (!value) return true;
			try {
				const allowedRegExp = new RegExp(`(${ext.join("|")})$`, "i");
				if (!allowedRegExp.exec(value.name)) {
					return false
				}
			} catch (error) {
				console.log(error);
			}
			return true;
		}
	});
}

function equalTo(ref, msg) {
	return this.test({
		name: "equalTo",
		exclusive: false,
		message: msg || "${path} must be the same as ${reference}", //eslint-disable-line
		params: {
			reference: ref.path
		},
		test(value) {
			return value === this.resolve(ref);
		}
	});
}

yup.addMethod(yup.mixed, "file", file);
yup.addMethod(yup.mixed, "ratioOneToOne", ratioOneToOne);
yup.addMethod(yup.mixed, "resolution", resolution);
yup.addMethod(yup.mixed, "fileSize", fileSize);

yup.addMethod(yup.string, "equalTo", equalTo);
yup.addMethod(yup.string, "ipv4", ipv4);
yup.addMethod(yup.string, "hex", hex)
yup.addMethod(yup.string, "phoneNumber", phoneNumber)
yup.addMethod(yup.string, "phone", phone)

export default yup;
