import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

/* Components */
import gpsPointIcon from "assets/icons/gps_point.png";
import TileControls from "./tiles-controls";
import DraggableMarker from "./draggable-marker";
import MapSection from "./map-section";
import Form from "@acromove/components/form";
import MapGroupControls from "./group-controls";
import MapMarker from "./marker";
import useFormatGroups from "./use-formatted-groups";
import mapTools from "./tools";

/* Utils */
import Box from "@mui/material/Box";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useForm, useWatch } from "react-hook-form";

/* Assets */
import pcDevice from "assets/as-js-string/pc_device";
import { useTheme } from "@mui/styles";
import useLocalUserSettings from "@acromove/hooks/use-local-user-settings";

const TILES = {
	simple: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
	topo: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
	satelite: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
};

const DEF_CENTER = [37.983760833740234, 23.727840423583984];
const DEF_ZOOM = 3;
const COUNTRY_LEVEL_ZOOM = 8;
const CITY_LEVEL_ZOOM = 12;

const markerIcon = new L.Icon({
	iconAnchor: [20, 40],
	popupAnchor: [0, -35],
	iconSize: [35, 35],
	iconUrl: gpsPointIcon,
	iconRetinaUrl: gpsPointIcon,
});

const Map = (props) => {
	const {
		title,
		marker,
		defCountry,
		defCity,
		groups,
		points,
		onPointClick,
		onCountryChange,
		onCityChange,
		onDraggableChange,
		draggableDefPos,
		draggable,
		notices = null,
		children = null,
	} = props;
	const [selectedTile, setSelectedTile] = useLocalUserSettings("mapTile", "simple");

	const [selectedGroups, setSelectedGroups] = useState((groups || []).map((group) => group.slug));
	const formattedGroup = useFormatGroups(groups);
	const theme = useTheme();

	const mapRef = useRef(null);

	const setMapCenter = (center, customMapZoom) => {
		if (mapRef.current) {
			const zoom = customMapZoom || mapRef.current.getZoom();
			mapRef.current.setView(center, zoom);
		} else {
			setTimeout(() => {
				setMapCenter(center, customMapZoom)
			}, 100)
		}
	};

	const form = useForm({
		defaultValues: {
			country: defCountry,
			city: defCity,
			draggable: draggableDefPos,
		},
	});

	const country = useWatch({ control: form.control, name: "country" });
	const city = useWatch({ control: form.control, name: "city" });
	const draggablePos = useWatch({ control: form.control, name: "draggable" });

	useEffect(() => {
		onCountryChange(country);
		if (country && Object.keys(country).length) {
			const pos = { lat: country.latitude, lng: country.longitude };
			setMapCenter(pos, COUNTRY_LEVEL_ZOOM);
			if (draggable) {
				form.setValue("draggable", pos);
			}
		} else {
			form.setValue("city", null);
		}
	}, [country]);

	useEffect(() => {
		onCityChange(city);
		if (city && Object.keys(city).length) {
			const pos = { lat: city.latitude, lng: city.longitude };
			setMapCenter(pos, CITY_LEVEL_ZOOM);
			if (draggable) {
				form.setValue("draggable", pos);
			}
		}
	}, [city]);


	const handleDraggableChange = (val) => {
		form.setValue("draggable", val)
		onDraggableChange(val);
		setMapCenter(val);
	}


	useEffect(() => {
		if (marker) {
			setMapCenter(marker);
		} else if (draggable && draggablePos) {
			setMapCenter(draggablePos, CITY_LEVEL_ZOOM);
			handleDraggableChange(draggablePos)
		} else if (points && points.length) {
			const cnt = mapTools.findAverageLocation(points?.map((p) => [p.position.lat, p.position.lng]));
			setMapCenter(cnt);
		}
	}, [marker, draggable, points]);

	const handleGroupChange = (slug) =>
		setSelectedGroups(
			selectedGroups.includes(slug) ? selectedGroups.filter((s) => s !== slug) : [...selectedGroups, slug]
		);

	const findIcon = (point) => {
		const found = formattedGroup.find((gr) => gr.slug === point.group);
		if (found) {
			return found.mapIcon;
		}
		const def = pcDevice(theme.palette.text.active);
		const icon = new L.Icon({
			iconAnchor: [10, 10],
			popupAnchor: [0, -35],
			iconSize: [20, 20],
			iconUrl: def,
			iconRetinaUrl: def,
		});
		return icon;
	};

	useEffect(() => {
		if(mapRef.current){
			mapRef.current.invalidateSize()
		}
	}, [children])


	return (
		<Form form={form} i18n="common" mode="anonymous" name="map" sx={{ width: "100%" }}>
			<MapSection title={title} onCountryChange={() => form.setValue("city", null)} countryId={country?.id}>
				{notices}
				<Box sx={{ display: "flex", flexDirection: "row", gap: children && 1 }}>
					<Box sx={{ width: children ? "50%" : "100%" }}>
						<MapContainer
							key={window.location.href}
							ref={mapRef}
							scrollWheelZoom={false}
							center={DEF_CENTER}
							zoom={DEF_ZOOM}
							style={{
								height: `400px`,
								borderRadius: "8px",
								zIndex: 1,
								width: "100%",
							}}
						>
							<TileLayer key={selectedTile} url={TILES[selectedTile]} />
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-end",
									alignItems: "flex-end",
									ml: -4,
									position: "relative",
									width: "100%",
									height: "100%",
								}}
							>
								<TileControls onSelect={setSelectedTile} selected={selectedTile} />
							</Box>
							{points &&
								points.map((point) => (
									<MapMarker
										slug={point.group}
										disabled={!selectedGroups.includes(point.group)}
										icon={findIcon(point)}
										onClick={() => onPointClick(point.group, point)}
										{...point}
									/>
								))}
							{marker && <Marker icon={markerIcon} position={marker} />}
							{draggable && (
								<DraggableMarker
									key={draggablePos}
									icon={markerIcon}
									position={draggablePos}
									onChange={handleDraggableChange}
								/>
							)}
						</MapContainer>
					</Box>
					{children}
				</Box>
				<MapGroupControls groups={formattedGroup} selected={selectedGroups} onChange={handleGroupChange} />
			</MapSection>
		</Form>
	);
};

Map.propTypes = {
	zoom: PropTypes.number,
	title: PropTypes.string,
	center: PropTypes.arrayOf(PropTypes.number),
	defCountry: PropTypes.shape({}),
	defCity: PropTypes.shape({}),
	points: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			group: PropTypes.string,
			position: PropTypes.shape({
				lat: PropTypes.number,
				lng: PropTypes.number,
			}),
			type: PropTypes.string,
			tooltip: PropTypes.arrayOf(
				PropTypes.shape({
					label: PropTypes.string,
					value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				})
			),
		})
	),
	marker: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.number)]),
	groups: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			slug: PropTypes.string,
			icon: PropTypes.string,
		})
	),
	displayTooltip: PropTypes.bool,
	onDraggableChange: PropTypes.func,
	draggable: PropTypes.bool,
	draggableDefPos: PropTypes.arrayOf(PropTypes.number),
	onChange: PropTypes.func,
	onPointClick: PropTypes.func,
	onCountryChange: PropTypes.func,
	onCityChange: PropTypes.func,
	notices: PropTypes.node,
	children: PropTypes.node,
};

Map.defaultProps = {
	zoom: 4,
	title: "Map",
	marker: null,
	groups: [],
	points: [],
	defCity: {},
	defCountry: {},
	displayTooltip: false,
	center: null,
	draggableDefPos: null,
	onDraggableChange: () => {},
	onChange: () => {},
	draggable: false,
	onPointClick: () => {},
	onCountryChange: () => {},
	onCityChange: () => {},
	notices: null,
	children: null,
};

export default Map;
