import {useContext, useEffect} from "react";
import formGroupContext from "../contexts/group";
import { useFormState, useWatch } from "react-hook-form";

const useFormGroupInternal = (name, form) => {
	const formGroup = useContext(formGroupContext);
	if(!formGroup) return undefined;

	const values = useWatch({control: form.control})
	const formState = useFormState({control: form.control})

	const validate = async () => ({
		name,
		isValid: await form.trigger(),
		touchedFields: formState.touchedFields,
		errors: formState.errors
	})

	useEffect(() => {
		formGroup.dispatchForm(name, form, {validate, formState, values})
	}, [])

	useEffect(() => {
		formGroup.onChange(name, values)
	}, [values])
	return null
}

export default useFormGroupInternal;
