import React from "react";
import constants from "@acromove/constants";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import ClientGroupsCreateContainer from "containers/clients/admin/create";

const  ClientsGroupCreatePage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={constants.ALL_ROLES}>
            <ClientGroupsCreateContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default ClientsGroupCreatePage
