import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

/* Components */
import ProtectedComponent from "@acromove/authentication/components/protected/component";

/**
 * Menu Button component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Menu Button.
 */
const DataGridMenu = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const { menu } = props;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				startIcon={<MoreVertIcon/>}
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
			/>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{menu.map((item) =>
					item.protected ? (
						<ProtectedComponent slug={item.protected}>
							<MenuItem sx={{color: item?.color}} onClick={item.onClick}>
								{item.text}
							</MenuItem>
						</ProtectedComponent>
					) : (
						<MenuItem sx={{color: item?.color}} onClick={item.onClick}>{item.text}</MenuItem>
					)
				)}
			</Menu>
		</>
	);
};

/**
 * Data Grid Menu Properties.
 *
 * @typedef {object} Props
 * @property {Array} menu - Menu Content.
 */

DataGridMenu.propTypes = {
	menu: PropTypes.arrayOf(
		PropTypes.shape({
			protected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
			onClick: PropTypes.func,
			text: PropTypes.string,
			color: PropTypes.string
		})
	).isRequired
};

export default DataGridMenu;
