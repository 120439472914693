import React, { useEffect } from "react";

/* Content Components */
import LoginForm from "components/content/login/form";

/* Utils. */
import { useNavigate } from "react-router-dom";
import useAuth from "@acromove/authentication/hooks/use-auth";

const LoginContainer = () => {
	const auth = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (auth.isInitialized && auth.isAuthenticated) {
			navigate("/");
		}
	}, [auth.isInitialized, auth.isAuthenticated]);

	return <LoginForm />;
};

export default LoginContainer;
