import React from "react";
import PropTypes from "prop-types";

import useAuth from "@acromove/authentication/hooks/use-auth";
import Switcher from "@acromove/components/switcher";
import { Navigate } from "react-router-dom";

/**
 * RoleSwitcher component.
 *
 * @type {React.FC<Props>}
 * @property {React.FC<PropsRole>} Role - Switcher Item.
 * @returns {React.ReactElement} ACL Component.
 */
const RoleSwitcher = (props) => {
	const { children, redirect, Fallback } = props;
	const auth = useAuth();
	return (
		<Switcher value={auth?.user?.role || "not_auth"}>
			{React.Children.toArray(children)
				.filter((child) => child.type.part_of_acl)
				.map((child) => (
					<Switcher.Item
						key={child.props.value}
						name={child.props.value}
					>
						{child.props.children}
					</Switcher.Item>
				))}
			<Switcher.Fallback>
				{Fallback ? <Fallback /> : <Navigate to={redirect} />}
			</Switcher.Fallback>
		</Switcher>
	);
};

const Role = (props) => {
	const { children } = props;
	return children;
};

Role.part_of_acl = true;

/**
 * ACL Role Properties.
 *
 * @typedef {object} PropsRole
 * @property {React.ReactNode} children - Property to define elements to be displayed according to the role.
 * @property {string[]} [include = []] - Property to define if acl includes a listed role.
 */

Role.propTypes = {
	children: PropTypes.node.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
};

Role.defaultProps = {
	include: [],
};

/**
 * ACL Properties.
 *
 * @typedef {object} Props
 * @property {React.ReactNode} children - Property to define elements to be displayed according to the role.
 * @property {string} [redirect = ""] - Property to redicrect on another page.
 * @property {React.ReactNode | false} [Fallback = "false"]
 */

RoleSwitcher.propTypes = {
	children: PropTypes.node.isRequired,
	redirect: PropTypes.string,
	Fallback: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([false])]),
};

RoleSwitcher.defaultProps = {
	redirect: "/",
	Fallback: false,
};

RoleSwitcher.Role = Role;

export default RoleSwitcher;
