import SmartRouter from "@acromove/utils/smart-router";
import DashboardLayout from "components/layouts/dashboard";

// import SitesBrowsePage from "pages/sites/browse";
import SitesCreatePage from "pages/sites/create";
import SitesViewPage from "pages/sites/site-view";
import SitesMySitesPage from "pages/sites/my-site";
// import SitesMySiteViewPage from "pages/sites/my-site-view";

const router = new SmartRouter();

// router.addRedirect("/sites", "/sites/browse")

router.addRoute("/sites", {
    title: "Sites",
    Layout: DashboardLayout,
	Container: SitesMySitesPage,
    accessSlug: "sites",
})

// router.addRoute("/sites/browse", {
//     title: "Browse",
//     Container: SitesBrowsePage ,
//     Layout: DashboardLayout,
//     accessSlug: "sites_browse",
//     childOf: "/sites",
// })

router.addRoute("/sites/create", {
    title: "Create Site",
    Container: SitesCreatePage ,
    Layout: DashboardLayout,
    accessSlug: "sites_create",
    childOf: "/sites",
    ignoreOnNav: true,
})

router.addRedirect("/sites/view", "/sites")

router.addRoute("/sites/view/:siteId", {
    title: "Site View",
    Container: SitesViewPage,
    Layout: DashboardLayout,
    accessSlug: "sites_browse_view",
    childOf: "/sites",
    ignoreOnNav: true,
})


// router.addRoute("/sites/my", {
//     title: "My Sites",
//     Container: SitesMySitesPage,
//     Layout: DashboardLayout,
//     accessSlug: "sites_my",
//     childOf: "/sites",
// })

// router.addRoute("/sites/my/view/:siteId", {
//     title: "My Site Information",
//     Container: SitesMySiteViewPage,
//     Layout: DashboardLayout,
//     accessSlug: "sites_my_view",
//     childOf: "/sites",
//     ignoreOnNav: true,
// })

export default router;
