import React from "react";
import PropTypes from "prop-types";

/* MUI */
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MUICheckbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

/* Utils */
import sh from "../../utils/styles-helpers";
import useField from "./hooks/use-filed";

/**
 * Checkbox component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Form Controlled Checkbox Component.
 */
const Checkbox = (props) => {
	const {
		label,
		name: fieldName,
		control,
		color,
		disabled,
		helperText,
		size,
		...rest
	} = props;

	const field = useField({
		fromProps: {
			label,
			name: fieldName,
			disabled,
			helperText,
			control,
		},
	});

	const { onChange, onBlur, name, value, ref } = field.controller.field;
	const { error } = field.controller.fieldState;
	return (
		<FormGroup {...rest}>
			<Box
				sx={[
					sh.flex("row", "flex-start", "flex-start"),
					{ width: "100%" },
				]}
			>
				<FormControlLabel
					control={
						<MUICheckbox
							name={name}
							checked={value}
							onChange={onChange}
							onBlur={onBlur}
							ref={ref}
							color={color}
							disabled={field.disabled}
						/>
					}
					label={field.label}
				/>
				{(field.helperText || helperText) && (
					<IconButton
						sx={{
							mt: 1,
							ml: 1,
						}}
						size={size}
						color="primary"
						onClick={() => field.setShowHelper(!field.showHelper)}
					>
						<InfoOutlinedIcon />
					</IconButton>
				)}
			</Box>
			{error && (
				<FormHelperText>{error?.message || error?.type}</FormHelperText>
			)}
			{field.helperTextMsg && (
				<FormHelperText>{field.helperTextMsg}</FormHelperText>
			)}
		</FormGroup>
	);
};

/**
 * Checkbox Properties.
 *
 * @typedef {object} Props
 * @property {string} label  - Checkbox Label.
 * @property {object} control - Form control options.
 * @property {string} name - Form Checkbox name.
 * @property {string} [color = "primary"] - Checkbox Color.
 * @property {true | false} [disabled = "false"] - Enable/Disable Checkbox.
 */

Checkbox.propTypes = {
	label: PropTypes.string.isRequired,
	control: PropTypes.shape({}).isRequired,
	name: PropTypes.string.isRequired,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	helperText: PropTypes.string,
	size: PropTypes.string,
};

Checkbox.defaultProps = {
	color: "primary",
	disabled: false,
	helperText: "",
	size: "small",
};

export default Checkbox;
