import React, { createContext, useState, useRef } from "react";
import PropTypes from "prop-types";

/* MUI */
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

export const ChartContext = createContext({});

/**
 * Chart Provider.
 *
 * @type {React.FC<Props>}
 * @see {@link  https://apexcharts.com/docs/series/} for more information.
 * @returns {React.ReactElement} Chart Provider.
 */
const ChartProvider = (props) => {
	const { children, series, xAxisCategories, boxSx } = props;
	const theme = useTheme();
	const [selectedSeries, setSelectedSeries] = useState([...series.map((s) => s.name)]);
	const chartRef = useRef(null);

	const handleChange = (name) => {
		if (selectedSeries.includes(name)) {
			setSelectedSeries(selectedSeries.filter((item) => item !== name));
		} else {
			setSelectedSeries([...selectedSeries, name]);
		}
	};

	const chartOptions = {
		title: {
			text: undefined,
		},
		chart: {
			background: "transparent",
			stacked: false,
			toolbar: {
				show: true,
				tools: {
					selection: true,
					zoomin: false,
					zoomout: false,
					download: true,
					zoom: true,
					pan: true,
					reset: true,
				},
			},
		},
		colors: series.map((s) => s.color),
		dataLabels: {
			enabled: false,
		},
		fill: {
			gradient: {
				opacityFrom: 0.4,
				opacityTo: 0.1,
				shadeIntensity: 1,
				stops: [0, 100],
				type: "vertical",
			},
			type: "gradient",
		},
		grid: {
			borderColor: theme.palette.divider,
			yaxis: {
				lines: {
					show: false,
				},
			},
		},
		legend: {
			show: false,
		},
		markers: {
			hover: {
				size: undefined,
				sizeOffset: 2,
			},
			radius: 2,
			shape: "circle",
			size: 4,
			strokeWidth: 0,
		},
		stroke: {
			curve: "smooth",
			lineCap: "butt",
			width: 3,
		},
		theme: {
			mode: theme.palette.mode,
		},
		xaxis: {
			axisBorder: {
				color: theme.palette.divider,
			},
			axisTicks: {
				color: theme.palette.divider,
				show: true,
			},
			categories: xAxisCategories,
			labels: {
				style: {
					colors: theme.palette.text.secondary,
				},
			},
		},
		yaxis: [
			{
				axisBorder: {
					color: theme.palette.divider,
					show: true,
				},
				axisTicks: {
					color: theme.palette.divider,
					show: true,
				},
				labels: {
					style: {
						colors: theme.palette.text.secondary,
					},
				},
			},
			{
				axisTicks: {
					color: theme.palette.divider,
					show: true,
				},
				axisBorder: {
					color: theme.palette.divider,
					show: true,
				},
				labels: {
					style: {
						colors: theme.palette.text.secondary,
					},
				},
				opposite: true,
			},
		],
	};

	return (
		<ChartContext.Provider
			value={{
				options: chartOptions,
				series,
				selectedSeries,
				onChange: handleChange,
				chartRef,
			}}
		>
			<Box sx={{ p: 3, ".apexcharts-toolbar": { display: "none"}, ...boxSx }}>{children}</Box>
		</ChartContext.Provider>
	);
};

/**
 * Chart Properties.
 *
 * @typedef {object} Props
 * @property {React.ReactNode} children.
 * @property {true | false | object[]} series - one of ApexChart Options. See {@link  https://apexcharts.com/docs/series/} for more information.
 * @property {true | false | string[]} xAxisCategories -one of ApexChart Options. See {@link  https://apexcharts.com/docs/series/} for more information.
 * @property {object} [boxSx = {}] - property to change Charts children wrappers CSS properties.
 */

ChartProvider.propTypes = {
	children: PropTypes.node.isRequired,
	series: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.shape({}))]).isRequired,
	xAxisCategories: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]).isRequired,
	boxSx: PropTypes.shape({})
};

ChartProvider.defaultProps = {
	boxSx: {}
}

export default ChartProvider;
