import React from "react";

/* MUI */
import { Typography, Grid, Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/* Components */
import Input from "@acromove/components/form/input";
import AuthFormWrapper from "../wrapper";
import Form from "@acromove/components/form";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/* Redux */
import { useLazyForgotQuery } from "@acromove/redux/auth/api";


const ForgotForm = () => {
	const { t } = useTranslation("reset_forget_page");
	const navigate = useNavigate();

    const [lazyForgot, {isSuccess}] = useLazyForgotQuery()
    
	const schema = yup.object({
		username: yup.string().required(t("username.required")),
	});

	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			username: "",
		},
	});

	const watchUsername = form.watch("username").split("@")[1]

	const handleForgot = () => {
		form.handleSubmit(lazyForgot)();
	};

	return (
		<AuthFormWrapper>
			{
			isSuccess ? 
			<Box sx={{display: "flex", flexDirection: "column"}}>
				<Typography fontWeight={500} fontSize={36} sx={{my: 2 }}>
						{t("email_sent")}
				</Typography>
				<Button variant="contained" onClick={() => window.open(`//${watchUsername}`, "_blank")}>
						{t("go_to")} {watchUsername}
				</Button>
			</Box>
			:
			<Form mode="anonymous" form={form} name="register" i18n="home_page">
				<Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
					<Typography fontWeight={500} fontSize={36}>
						{t("forgot")}
					</Typography>
					<Typography fontWeight={300} fontSize={14} sx={{ color: "text.secondary" }}>
						{t("forgot_text")}
					</Typography>
				</Box>
				<Grid container gap={4} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
					<Grid item md={12}>
						<Form.Field name="username">
							<Input sx={{ width: "100%", mb: 1 }} size="medium" />
						</Form.Field>
					</Grid>

					<Button
						endIcon={<ArrowForwardIcon />}
						onClick={() => handleForgot()}
						variant="contained"
						size="medium"
					>
						{t("send_to_reset")}
					</Button>
				</Grid>
				<Box sx={{ display: "flex", alignSelf: "flex-start", alignItems: "center", my: 1 }}>
					<Typography fontSize={14}>{t("remember")}</Typography>
					<Button variant="text" onClick={() => navigate("/login")}>
						{t("login")}
					</Button>
				</Box>
			</Form>
			}
		</AuthFormWrapper>
	);
};

export default ForgotForm;
