import React from "react";
import PropTypes from "prop-types";

/**
 * Switcher component.
 *
 * @type {React.FC<Props>}
 * @property {React.FC<PropsItem>} Item - Switcher Item.
 * @property {React.FC<PropsFallback>} Fallback - Fallback Item.
 * @returns {React.ReactElement} Switcher Component.
 */
const Switcher = (props) => {
    const {children, value} = props;

    const findChildren = () => {
        const rootChildren = React.Children.toArray(children)
            .filter(child => !child.type.fallback_of_switch)
            .filter(child => {
            if(child.type.part_of_switch){
                const name = child?.props?.name || null;
                if(Array.isArray(name) && name.includes(value)) {
                    return true
                }
                if(value === child?.props?.name){
                    return true
                }
                return false
            } 
            return true;
        })

        if(!rootChildren.length){
            const fallback = React.Children.toArray(children).filter(child => child.type.fallback_of_switch);
            return fallback;
        }

        return rootChildren;
    }

    return(
        <>
            {findChildren()}
        </>
    );
}

const Item = (props) => {
    const {children} = props;
    return(
        children
    )
}

const Fallback = (props) => {
    const {children} = props;
    return(
        children
    );
}



Fallback.fallback_of_switch = true;

Item.part_of_switch = true;

Switcher.Item = Item;
Switcher.Fallback = Fallback;

/**
 * Switcher Item Properties.
 *
 * @typedef {object} PropsItem
 * @property {React.ReactNode[]} [children = ""] - Text Displayed when Table is Empty,
 * @property {string | number} name - Switcher Item Name.
 */

Item.propTypes = {
    children: PropTypes.node,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]).isRequired
}

Item.defaultProps = {
    children: ""
};

/**
 * Switcher Fallback Properties.
 *
 * @typedef {object} PropsFallback
 * @property {React.ReactNode[]} [children = ""] - Text Displayed when Table is Empty,
 */

Fallback.propTypes = {
    children: PropTypes.node
}

/**
 * Switcher Properties.
 *
 * @typedef {object} Props
 * @property {React.ReactNode[]} [children = ""] - Text Displayed when Table is Empty,
 * @property {string | number} [value = ""] - Icon for Empty Table.
 */

Switcher.propTypes = {
    children: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Switcher.defaultProps = {
	children: "",
    value: ""
};


export default Switcher;
