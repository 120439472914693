import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

/**
 * TabPanel component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Tab Panel Element.
 */

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</Box>
	);
};

/**
 * Input Properties.
 *
 * @typedef {object} Props
 * @property {number} value - Value of TabElement.
 * @property {number} index - Index of TabElement used to display current element.
 * @property {React.ReactNode} children - A Child element displayed in Tab.
 */

TabPanel.propTypes = {
	value: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
	children: PropTypes.node.isRequired,
};

export default TabPanel;
