import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/system";

/* Styles */
import styles from "./styles";

const AuthPageWrapper = (props) => {
	const { children } = props;
	const theme = useTheme();

	return (
		<Grid container sx={styles.container} display="flex">
			<Grid item sx={styles.logoArea}>
				<img style={styles.logo} src={theme.assets.logo} alt="logo" loading="lazy" />
			</Grid>
			<Grid item sx={styles.fromArea}>
				<Grid container flexDirection="row" justifyContent="center" alignItems="center" sx={styles.fromWrap}>
					<Grid item sx={{ width: "100%" }}>
						{children}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

AuthPageWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthPageWrapper;
