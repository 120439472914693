import PropTypes from "prop-types";

import useAuth from "../../hooks/use-auth";

const ProtectedComponent = (props) => {
    const {slug, children, field} = props;
    const auth = useAuth();

    if(field){
        const [entity, filedName, mode] = slug.split(":")
        return auth.findFiledRule(entity, filedName, mode).includes("field_view") ? children : null
    }
    return auth.isAllowed(slug) ? children : null
}

ProtectedComponent.propTypes = {
    field: PropTypes.bool,
    children: PropTypes.node.isRequired,
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
}

ProtectedComponent.defaultProps = {
    field: false
}

export default ProtectedComponent;