import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";

import { ChartContext } from "./provider";
import sh from "../../utils/styles-helpers";

const ChartLegends = () => {
	const { onChange, series, selectedSeries } = useContext(ChartContext);
	return (
		<Box sx={sh.flex("row")}>
			{series.map((item) => (
				<Box
					key={item.name}
					sx={{
						alignItems: "center",
						display: "flex",
						mr: 2,
						cursor: "pointer",
					}}
					onClick={() => onChange(item.name)}
				>
					<Box
						sx={{
							backgroundColor: item.color,
							borderRadius: "50%",
							height: 8,
							ml: 1,
							mr: 2,
							width: 8,
						}}
					/>
					<Typography
						color={
							selectedSeries.some((visibleItem) => visibleItem === item.name)
								? "text.active"
								: "text.secondary"
						}
						variant="subtitle2"
					>
						{item.name}
					</Typography>
				</Box>
			))}
		</Box>
	);
};

export default ChartLegends;
