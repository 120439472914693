import {useState, useEffect} from "react";

import { useLazyGetImageQuery } from "../redux/uploads/api";


const useImage = (asset) => {
	const [url, setUrl] = useState(null);
    const [fetchImage, {isLoading, data}] = useLazyGetImageQuery()

	useEffect(() => {
        if(asset){
            if(asset instanceof File){
                setUrl(URL.createObjectURL(asset))
            } else if("filename" in asset) {
                fetchImage({ type: "base64", fileName: asset.filename});
            }
        } else {
            setUrl(null)
        }
     
	}, [asset]);

    useEffect(() => {
        if(data){
            setUrl(data)
        }
    }, [data])

    return {loading: isLoading, url, asset: data || asset};
}

export default useImage;