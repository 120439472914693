import React from "react";
import AuthGuard from "@acromove/authentication/components/auth-guard";
import {Navigate} from "react-router-dom";

class SmartRouter {
    #baseUrl = "/";

    #routerElements = [];
    
    #navElements = [];

    #defOptions = {
        title: "Page",
        icon: null,
        Container: React.Fragment,
        Layout: React.Fragment,
        accessSlug: null,
        childOf: null,
        ignoreOnNav: false
    }

    constructor(baseUrl = "/") {
        this.#baseUrl = baseUrl;
    }

    getNavElements(){
        return this.#navElements;
    }

    getRouterElements(){
        return this.#routerElements;
    }

    #componentBuilder(options) {
        if (options.accessSlug) {
            const Component = () => (
                <AuthGuard slug={options.accessSlug}>
                    <options.Layout>
                        <options.Container />
                    </options.Layout>
                </AuthGuard>
            )
            return Component
        } 
        const Component = () => (
            <options.Layout>
                <options.Container />
            </options.Layout>
        )
        return Component
    }

    addRoute(path, options = {}) {
        const ops = {
            path,
            ...this.#defOptions,
            ...options
        }
        const navKeys = ["title", "path", "icon"];
        const routerKeys = ["title", "path", "index"];

        const navElement = {
            sub: [], 
            protected: (ops.accessSlug && ops.accessSlug.length),
            accessSlug: ops.accessSlug
        };
        const routerElement = {
            component: this.#componentBuilder(ops),
            protected: (ops.accessSlug && ops.accessSlug.length),
            accessSlug: ops.accessSlug
        };

        for (const op in ops) {
            if (routerKeys.includes(op)) {
                routerElement[op] = ops[op];
            }
            if (navKeys.includes(op)) {
                navElement[op] = ops[op]
            }
        }

        this.#routerElements.push(routerElement);

        if(ops.ignoreOnNav) {
            return this;
        }

        if(ops.childOf){
            this.#navElements = this.#navElements.map(el => {
                if(el.path === ops.childOf){
                    return {
                        ...el,
                        sub: [
                            ...el.sub,
                            navElement
                        ]
                    }
                }
                return el
            });
            return this;
        }

        this.#navElements.push(navElement)
        return this;

    }

    addRedirect(from, to){
        this.#routerElements.push({
            path: from,
            component: () => <Navigate to={to}/>
        })
    }

    use(router){
        this.#navElements = [
            ...this.#navElements,
            ...router.getNavElements()
        ]
        this.#routerElements = [
            ...this.#routerElements,
            ...router.getRouterElements()
        ]
        return this;
    }


}

export default SmartRouter;