import React, { useState } from "react";

/* MUI */
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Popover from "@mui/material/Popover";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

/* Components */
import NotificationList from "./notification-list";

/* Redux */
import { useFindNotificationsQuery } from "redux/notifications/notification.api";

/* Utils */
import templates from "components/content/notifications/templates";
import moment from "moment";

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		right: 3,
		top: 3,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: "0 4px",
	},
}));

const priorities = {
	low: 2,
	medium: 1,
	high: 0
}

const NotificationsButton = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const { data: notifications } = useFindNotificationsQuery({ limit: 3, offset: 0 });

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const sortByPriority = (items) => [...items].sort((a, b) => priorities[a.priority] - priorities[b.priority]);
	const sortByTime = (items) =>
		[...items].sort((a, b) => Date.parse(new Date(a.createdAt)) - Date.parse(new Date(b.createdAt))).reverse();

	const generateNotificationListWithContent = (items) => {
		if (!items || !items.length) return [];
		const sorted = sortByPriority(sortByTime(items));
		const formatted = [];
		for (const item of sorted) {
			if (!(item.template in templates)) continue;
			formatted.push({
				...item,
				content: {
					...templates[item.template],
					replacements: item?.content,
					timestamp: moment(item.createdAt).format("DD MMM, YYYY [at] HH:mm"),
				},
			});
		}
		return formatted;
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<>
			<IconButton aria-describedby={id} size="small" onClick={handleClick}>
				<StyledBadge size="small" badgeContent={notifications?.total} color="primary">
					<NotificationsIcon />
				</StyledBadge>
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 42,
					horizontal: "right",
				}}
			>
				<NotificationList items={generateNotificationListWithContent(notifications?.items || [])} />
			</Popover>
		</>
	);
};

export default NotificationsButton;
