import React, { createContext, useEffect, useState, useMemo } from "react";
import defLogo from "assets/images/logo.svg";

import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import useImage from "@acromove/hooks/use-image";

const initialSettings = {
	direction: "ltr",
	responsiveFontSizes: true,
	theme: "light",
	colors: null
};

const restoreSettings = () => {
	let settings = null;

	try {
		const storedData = window.localStorage.getItem("settings");
		if (storedData) {
			settings = JSON.parse(storedData);
		} else {
			settings = {
				direction: "ltr",
				responsiveFontSizes: true,
				// theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
				theme: "light",
			};
		}
	} catch (err) {
		// If stored data is not a strigified JSON this will fail,
		// that's why we catch the error
	}

	return {...settings, theme: "light"};
};

const storeSettings = (settings) => {
	window.localStorage.setItem("settings", JSON.stringify(settings));
};

export const SettingsContext = createContext({
	settings: initialSettings,
	saveSettings: () => {},
});

export const SettingsProvider = (props) => {
	const { children } = props;
	const [settings, setSettings] = useState(initialSettings);
	const scope = useSelector(state => state.auth.scope);
	const {url: logo} = useImage(scope?.settings?.logo);

	useEffect(() => {
		const restoredSettings = restoreSettings();
		if (restoredSettings) {
			setSettings(restoredSettings);
		}
	}, []);

	const saveSettings = (updatedSettings) => {
		setSettings(updatedSettings);
		storeSettings(updatedSettings);
	};

	useEffect(() => {
		if(scope && scope?.settings?.colors){
			saveSettings({...settings, colors: scope?.settings?.colors})
		}
	}, [scope])

	useEffect(() => {
		console.log(logo);
		if(logo){
			saveSettings({...settings, logo})
		}
		else {
			saveSettings({ ...settings, logo: defLogo})
		}
	}, [logo])

	return (
		<SettingsContext.Provider
			value={useMemo(
				() => ({
					settings,
					saveSettings,
				}),
				[settings]
			)}
		>
			{children}
		</SettingsContext.Provider>
	);
};

SettingsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default {
	SettingsConsumer,
	SettingsProvider,
	SettingsContext,
	storeSettings,
	restoreSettings
}
