import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

/**
 * Chart Header component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Chart Header Component.
 */
const ChartHeader = ({ title, children, sx }) => (
	<Box
		sx={{
			alignItems: "center",
			display: "flex",
			flexWrap: "wrap",
			p: "0px 20px",
			...sx,
		}}
	>
		<Typography variant="h6" sx={{ mr: "auto" }} color="text.secondary">
			{title}:
		</Typography>
		{children}
	</Box>
);

/**
 * Chart Header Properties.
 *
 * @typedef {object} Props
 * @property {string} title - Title displayed in Chart Header.
 * @property {React.ReactNode} [children = ""] - Chart Header Children.
 * @property {function | object | array} [sx = {}] - Prop used to change Chart Header Wrapper CSS properties.
 */


ChartHeader.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node,

	/**
	 * @type {import("@mui/system").SxProps<import("@mui/system").Theme>}
	 */
	sx: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
		PropTypes.func,
		PropTypes.object,
	]),
};

ChartHeader.defaultProps = {
	children: "",
	sx: {},
};

export default ChartHeader;
