import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "@acromove/utils/helpers";
import typePermissions from "components/content/acro-dashboard/group-types/permissions"

const groupTypesAPI = createApi({
    reducerPath: "group-types/api",
    baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("group-types")),
    keepUnusedDataFor: 30,
    tagTypes: ["FindOneGroupType", "FindGroupTypes"],
    endpoints: build => ({
        findGroupTypes: build.query({
            query: ({limit, offset, sortItem, sortDir, search}) => ({
                url: "/find",
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: (response) => ({
                total: response.payload.total,
                items: response.payload.items.map(item => ({
                    ...item,
                    id: item.id,
                    url: `/acromove/group_types/view/${item.id}-${item.name.replace(/ /g, "_")}`
                }))
                
            }),
            providesTags: ["FindGroupTypes"]
        }),

        findOneGroupType: build.query({
            query: ({id}) => ({
                url: `/find/${id}`
            }),
            transformResponse: (response) => ({
                name: response.payload.name, 
                ...typePermissions.reduce((acc, item) => {
                    if(response.payload?.permissions_aliases){
                        acc[item.name] = response.payload?.permissions_aliases?.includes(item.alias) //eslint-disable-line
                        
                    } else {
                        acc[item.name] = false //eslint-disable-line

                    }
                    return acc
                }, {})}),
            providesTags: ["FindOneGroupType"]
        }),

        createGroupType: build.mutation({
            query: (data) => ({
               url: "/create",
               method: "POST",
               body: data
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["GroupsFind"]
        }),

        editGroupType: build.mutation({
            query: (data) => ({
                url: `/edit/${data.Id}`,
                method: "PATCH",
                body: data.value
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["FindGroupTypes", "FindOneGroupType"]
        }),

        removeGroupType: build.mutation({
            query: ({id}) => ({
                url: `/remove/${id}`,
                method: "DELETE",
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["FindGroupTypes"]
        })
    })
})

export const {
    useFindGroupTypesQuery,
    useLazyFindGroupTypesQuery,
    useFindOneGroupTypeQuery,
    useLazyFindOneGroupTypeQuery,
    useCreateGroupTypeMutation,
    useEditGroupTypeMutation,
    useRemoveGroupTypeMutation
} = groupTypesAPI

export default groupTypesAPI