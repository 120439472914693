import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "@acromove/utils/helpers";
import qs from "qs";
import transforms from "./transformResponse"

const sitesAPI = createApi({
	reducerPath: "sites/api",
	baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("sites")),
	keepUnusedDataFor: 30,
	tagTypes: ["SitesFind", "SitesFineOne"],
	endpoints: (build) => ({
		findSites: build.query({
			query: ({ limit, offset, sortItem, sortDir, search, filters }) => ({
				url: "find",
				params: qs.stringify({
					limit,
					offset,
					sortItem,
					sortDir,
					search,
					filters,
				}),
			}),
			transformResponse: (response) => ({
				total: response.payload.total,
				items: response.payload.items.map((item) => ({
					...item,
					countryName: item?.country?.name,
					cityName: item?.city?.name,
					url: `/sites/view/${item.id}-${item.name}`,
				})),
			}),
			providesTags: ["SitesFind"],
		}),
		findAllSites: build.query({
			query: ({ cityId, countryId }) => ({
				url: "find",
				params: qs.stringify({
					limit: 999,
					offset: 0,
					sortItem: "name",
					sortDir: "asc",
					filters: {
						city_id: cityId,
						country_id: countryId,
					},
				}),
			}),
			transformResponse: (response) => ({
				total: response.payload.total,
				items: response.payload.items.map((item) => ({
					...item,
					countryName: item?.country?.name,
					cityName: item?.city?.name,
					url: `/sites/view/${item.id}-${item.name}`,
				})),
			}),
			providesTags: ["SitesFind"],
		}),
		findOneSite: build.query({
			query: ({ id }) => ({
				url: `find/${id}`,
			}),
			transformResponse: (response) => ({
				...response.payload,
				spaces: response.payload.spaces.map(transforms.addIsSpaceHasNotices)
			}),
			providesTags: ["SitesFineOne"]
		}),
		createSiteDraft: build.mutation({
			query: (data) => ({
				url: "draft",
				method: "POST",
				body: data,
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["SitesFind", "SitesFineOne"],
		}),
		updateSiteDraft: build.mutation({
			query: (data) => ({
				url: `draft/${data.id}`,
				method: "PATCH",
				body: data,
			}),
			transformResponse: (response) => response.payload,
			// invalidatesTags: ["SitesFind", "SitesFineOne"],
		}),
		applySite: build.mutation({
			query: (data) => ({
				url: `apply/${data.id}`,
				method: "PATCH",
				body: data,
			}),
			transformResponse: (response) => ({...response.payload, meta: {message: response.message}}),
			invalidatesTags: ["SitesFind", "SitesFineOne"],
		}),
		getSite: build.query({
			query: ({ id }) => ({
				url: `get/${id}`,
			}),
			transformResponse: (response) => response.payload,
		}),
		importSites: build.mutation({
			query: (data) => {
				const formData = new FormData();
				for (const key in data) {
					formData.append(key, data[key]);
				}
				return {
					url: `import`,
					method: "POST",
					body: formData,
				};
			},
			transformResponse: (response) => response.payload,
			invalidatesTags: ["SitesFind"],
		}),
	}),
});

export const {
	useFindSitesQuery,
	useLazyFindSitesQuery,
	useCreateSiteDraftMutation,
	useFindAllSitesQuery,
	useLazyFindAllSitesQuery,
	useImportSitesMutation,
	useApplySiteMutation,
	useFindOneSiteQuery,
	useLazyFindOneSiteQuery,
	useUpdateSiteDraftMutation,
	useGetSiteQuery,
	useLazyGetSiteQuery
} = sitesAPI;

export default sitesAPI;
