const ALL_ROLES = [
    "acromove_admin:owner",
    "acromove_admin",
    "admin",
    "admin:view_only",
    "admin:owner",
    "accountant",
    "accountant:view_only",
    "user",
    "user:view_only",
]

export default {ALL_ROLES};