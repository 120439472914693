import React from "react";
import constants from "@acromove/constants";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import SitesCreateContainer from "containers/sites/admin/create";

const SitesCreatePage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={constants.ALL_ROLES}>
            <SitesCreateContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default  SitesCreatePage;