import React, { useEffect } from "react";
import PropTypes from "prop-types";

// /* Common Components */
import Dialog from "@acromove/components/dialog";
import GroupForm from "components/content/clients/group-form";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const GroupFormDialog = (props) => {
	const { submit, data, isLoading, open, onClose, isSuccess, mode } = props;
	const { t } = useTranslation("clients_groups_info");

	const schema = yup.object({
		name: yup.string().required(t("group.name.required")),
		type: yup.string().required(t("group.type.required"))
	});

	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			type: data?.type,
		},
	});

	useEffect(() => {
		if (isSuccess) {
			onClose()
			form.reset()
		}
	}, [isSuccess]);

	const handleClose = (isAgree) => {
		if (isAgree) {
			form.handleSubmit(submit)();
			if (form.formState.errors) {
				open; //eslint-disable-line
			}
		} else onClose();
	};

	return (
		<Dialog loading={isLoading} maxWidth="md" fullWidth open={open} onClose={onClose} hideButtons title={t("group_dialog.title")}>
			<GroupForm 
				submit={handleClose}
				editMode
				isLoading={isLoading}
				isSuccess={isSuccess}
				mode={mode}
				form={form}
				/>
		</Dialog>
	);
};

GroupFormDialog.propTypes = {
	data: PropTypes.shape({
		name: PropTypes.string,
		description: PropTypes.string,
		type: PropTypes.string,
	}),
	submit: PropTypes.func,
	isLoading: PropTypes.bool,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	isSuccess: PropTypes.bool,
	mode: PropTypes.string.isRequired
};

GroupFormDialog.defaultProps = {
	data: {
		name: "",
		description: "",
		type: "tenant",
	},
	isLoading: false,
	submit: () => null,
	onClose: () => null,
	open: false,
	isSuccess: false,
};

export default GroupFormDialog;
