import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../../hooks/use-auth";

/**
 * AuthGuard component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} AuthGuard.
 */
const AuthGuard = (props) => {
	const { children, slug } = props;
	const auth = useAuth();
	return auth.isAllowed(`pages:${slug}`) ? children : <Navigate to="/404"/>;
};

/**
 * AuthGuard Properties.
 *
 * @typedef {object} Props
 * @property {React.ReactNode} children - Property to define elements to be displayed according to the auth.
 * @property {string} slug
 */

AuthGuard.propTypes = {
	children: PropTypes.node.isRequired,
	slug: PropTypes.string.isRequired,
};

export default AuthGuard;
