import { useTranslation } from "react-i18next";

const useItemsTranslation = (ns) => {
    const { t } = useTranslation(ns);

    return (items, path, slug, key) => items.map(item => {
        const formattedPath = path.includes("{{slug}}") ? path.replace("{{slug}}", item[slug]) : `${path}.${item[slug]}`
        return {
            ...item,
            [key]: t(`${formattedPath}`)
        }
    })
}

export default useItemsTranslation;