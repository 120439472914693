/* eslint-disable */
import React from "react";
// import PropTypes from "prop-types";
import Dialog from "@acromove/components/dialog";
import { Box, Button, Typography } from "@mui/material";

const MachineCommandsDialog = (props) => {
	const { open, setOpen, device, t, executeCommand } = props;
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			title={t("dev_controls")}
			maxWidth="sm"
			sx={{ minWidth: "500px" }}
			labelOk="OK"
		>
			<Box>
				{device?.acrocloud?.controls.map((item) => (
					<Box
						sx={{
							width: "100%",
							maxHeight: "50px",
							display: "flex",
							flexDirection: "row",
							mt: 1,
							mb: 1,
							alignItems: "center",
							justifyContent: "space-between",
						}}
						key={item?.id}
					>
						<Typography fontWeight={500}>{item?.name}</Typography>
						<Button variant="contained" onClick={() => executeCommand(item?.id, device.serial)}>
							{t("restart")}
						</Button>
					</Box>
				)) || <Typography>{t("no_cmds")}</Typography>}
			</Box>
		</Dialog>
	);
};

// MachineCommandsDialog.propTypes = {
//     device: PropTypes.shape({}).isRequired,
//     executeCommand: PropTypes.func().isRequired,
//     open: PropTypes.bool.isRequired,
//     setOpen: PropTypes.func.isRequired,
//     t: PropTypes.func.isRequired
// }

export default MachineCommandsDialog;
