import React, { useState } from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/system";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { MapContainer, Marker, TileLayer, Tooltip, CircleMarker } from "react-leaflet";
import Polyline from "components/common/polyline-arrow";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";

import gpsPointIcon from "assets/icons/gps_point.png";
import TileControls from "./tiles-controls";
import DraggableMarker from "./map/draggable-marker";

const TILES = {
	simple: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
	topo: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
	satelite: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
};

const markerIcon = new L.Icon({
	iconAnchor: [20, 40],
	popupAnchor: [0, -35],
	iconSize: [35, 35],
	iconUrl: gpsPointIcon,
	iconRetinaUrl: gpsPointIcon,
});

const ResourcesMap = (props) => {
	const { groups, displayTooltip, marker, polyline, center, zoom, onDraggableChange, draggableDefPos, draggable = false } = props;
	const theme = useTheme();
	const [selectedTile, setSelectedTile] = useState("satelite");
	const [activeResources, setActiveResources] = useState(groups.map((gr) => gr.slug));
	const { t } = useTranslation("common");
	const handleToggleResources = (res) => () => {
		if (activeResources.includes(res)) {
			setActiveResources((prev) => prev.filter((r) => r !== res));
		} else {
			setActiveResources((prev) => [...prev, res]);
		}
	};

	const getPoints = (rawGroups) => {
		const result = [];
		console.log(rawGroups)
		for (const group of rawGroups) {
			const iconRecolor = (typeof group.icon === "function") ?  group.icon(theme.palette.text.active) : group.icon
			const icon = new L.Icon({
				iconAnchor: [10, 10],
				popupAnchor: [0, -35],
				iconSize: [20, 20],
				iconUrl: iconRecolor,
				iconRetinaUrl: iconRecolor,
			});

			console.log(group)

			for (const point of group.points) {
				if (!Array.isArray(point)) {
					result.push({
						icon,
						position: point.position,
						slug: group.slug,
						tooltip: point.tooltip,
						type: "dot",
					});
					continue;
				}
				result.push({
					icon,
					position: point,
					slug: group.slug,
					tooltip: group.tooltip,
					type: "dot",
				});
				if (group.type === "area") {
					result.push({
						icon,
						position: point,
						slug: group.slug,
						tooltip: group.tooltip,
						type: group?.type || "area",
						radius: group?.radius || 0,
					});
				}
			}
		}
		return result.filter((p) => activeResources.includes(p.slug));
	};

	return (
		<>
			<MapContainer
				scrollWheelZoom={false}
				center={center}
				zoom={zoom}
				key={selectedTile}
				style={{
					height: `400px`,
					borderRadius: "8px",
					zIndex: 1,
				}}
			>
				<TileLayer url={TILES[selectedTile]} />
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end",
						alignItems: "flex-end",
						ml: -4,
						position: "relative",
						width: "100%",
						height: "100%",
					}}
				>
					<TileControls onSelect={setSelectedTile} selected={selectedTile} />
				</Box>
				{polyline && (
					<Polyline
						color={theme.palette.primary.dark}
						positions={getPoints(groups).map((point) => point.position)}
						arrowheads={{
							size: "30px",
						}}
					/>
				)}
				{marker && <Marker icon={markerIcon} position={marker} />}
				{draggable && <DraggableMarker icon={markerIcon} defPos={draggableDefPos} onChange={onDraggableChange} />}
				{getPoints(groups).map((point) =>
					point.type === "area" ? (
						<CircleMarker
							key={`${point.slug}_${point.label}_${point.position.join()}`}
							icon={point.icon}
							center={point.position}
							radius={point.radius}
							pathOptions={{
								color: theme.palette.primary.main,
							}}
						>
							{displayTooltip && (
								<Tooltip>
									<Box>
										{t("tooltip.site")}: {point.tooltip.site}
										<br />
										{t("tooltip.state")}: {point.tooltip.state}
										<br />
										{t("tooltip.cpu_load")}: {point.tooltip.cpu_load}
										<br />
										{t("tooltip.ram_load")}: {point.tooltip.ram_load}
										<br />
										{t("tooltip.storage")}: {point.tooltip.storage}
									</Box>
								</Tooltip>
							)}
						</CircleMarker>
					) : (
						<Marker
							key={`${point.slug}_${point.label}_${point.position.join()}`}
							icon={point.icon}
							position={point.position}
						>
							{displayTooltip && (
								<Tooltip>
									<Box>
										{t("tooltip.site")}: {point.tooltip.site}
										<br />
										{t("tooltip.state")}: {point.tooltip.state}
										<br />
										{t("tooltip.cpu_load")}: {point.tooltip.cpu_load}
										<br />
										{t("tooltip.ram_load")}: {point.tooltip.ram_load}
										<br />
										{t("tooltip.storage")}: {point.tooltip.storage}
									</Box>
								</Tooltip>
							)}
						</Marker>
					)
				)}
			</MapContainer>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					alignItems: "center",
					marginTop: "0px",
				}}
			>
				{groups.map((gr) => (
					<Button
						key={gr.label}
						startIcon={<img width={15} height={15} src={(typeof gr.icon === "function") ? gr.icon(theme.palette.text.active) : gr.icon} alt="icon" />}
						onClick={handleToggleResources(gr.slug)}
						sx={{
							filter: activeResources.includes(gr.slug) ? "unset" : "grayscale(1)",
						}}
					>
						<Typography color="text.secondary">{gr.label}</Typography>
					</Button>
				))}
			</Box>
		</>
	);
};

ResourcesMap.propTypes = {
	zoom: PropTypes.number,
	center: PropTypes.arrayOf(PropTypes.number),
	polyline: PropTypes.bool,
	marker: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.number)]),
	groups: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			slug: PropTypes.string,
			icon: PropTypes.string,
			type: PropTypes.oneOf(["area", "dot"]),
			points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
			tooltip: PropTypes.shape({
				site: PropTypes.string,
				state: PropTypes.string,
				cpu_load: PropTypes.string,
				ram_load: PropTypes.string,
				storage: PropTypes.string,
			}),
		})
	),
	displayTooltip: PropTypes.bool,
	onDraggableChange: PropTypes.func,
	draggable: PropTypes.bool,
	draggableDefPos: PropTypes.arrayOf(PropTypes.number)
};

ResourcesMap.defaultProps = {
	zoom: 10,
	polyline: false,
	marker: false,
	groups: [],
	displayTooltip: false,
	center: [37.983760833740234, 23.727840423583984],
	draggableDefPos: [37.983760833740234, 23.727840423583984],
	onDraggableChange: () => {},
	draggable: false
};

export default ResourcesMap;
