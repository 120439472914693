import React from "react";
import PropTypes from "prop-types";
import SimpleMarker from "./simple-marker";
import AreaMarker from "./area-marker";

const MapMarker = (props) => {
	const {type, ...rest} = props;
	switch (type) {
		case "area":
			return <AreaMarker {...rest}/>
		case "dot":
			return <SimpleMarker {...rest}/>
		default:
			return <SimpleMarker {...rest}/>
	}
}

MapMarker.propTypes = {
	type: PropTypes.string.isRequired
}

export default MapMarker
