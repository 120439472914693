import React from "react";
import PropTypes from "prop-types";

/* MUI */
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
// import SettingsIcon from "@mui/icons-material/Settings";
// import SearchIcon from "@mui/icons-material/Search";
import GroupIcon from "@mui/icons-material/Group";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MUISelect from '@mui/material/Select';

/* Content Components */
import NotificationsButton from "components/content/notifications/notification-button";

/* Components */
import Breadcrumbs from "./breadcrumbs";
import UserMiniProfile from "./user-mini-profile";

/* Utils */
import { useMediaQuery } from "@mui/material";
import useAuth from "@acromove/authentication/hooks/use-auth";
import { useNavigate } from "react-router-dom";


const AppTopBar = (props) => {
    const {drawerWidth, onDrawerToggle} = props;
    const auth = useAuth()
	const navigate = useNavigate()

    const isDownMd = useMediaQuery((theme) => `${theme.breakpoints.down('lg')}`);

    const handleScopeChange = (e) => {
        auth.selectScope(e.target.value)
    }

	return (
		<AppBar
			position="fixed"
			sx={{
				bgcolor: "background.paper",
				width: { lg: `calc(100% - ${drawerWidth}px)` },
				ml: { sm: `${drawerWidth}px` },
			}}
		>
			<Toolbar sx={{ minHeight: { md: "52px" } }}>
				<IconButton
					aria-label="open drawer"
					edge="start"
					size="large"
					onClick={onDrawerToggle}
					sx={{ mr: 2, display: isDownMd ? "flex" : "none" }}
				>
					<MenuIcon />
				</IconButton>
				<Box
					sx={{ width: "100%", gap: 1 }}
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box>
						<Breadcrumbs />
					</Box>

					<Box sx={{ gap: 1, }} display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                        {!!(auth.user?.groups?.length) && 
                            <FormControl size="small" sx={{width: 200}}>
                                <MUISelect
									sx={{
										color: "primary.main",
										"&.MuiSelect-icon": {
											color: "primary.main"
										}
									}}
                                    labelId="scope-label"
                                    id="scope-simple-select"
                                    value={auth.user.scope}
                                    onChange={handleScopeChange}
                                    >
                                        {
                                            auth.user.groups.map(gr => <MenuItem sx={{color: "primary.main"}} key={gr._key} value={gr._key}>{gr.name}</MenuItem>)
                                        }
                                </MUISelect>
                            </FormControl>
                        }
						{/* <IconButton size="small">
							<SearchIcon />
						</IconButton> */}
						<IconButton onClick={() => navigate("/clients")} size="small">
							<GroupIcon />
						</IconButton>
						<NotificationsButton />
						{/* <IconButton size="small">
							<SettingsIcon />
						</IconButton> */}
						<UserMiniProfile />
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	);
};


AppTopBar.propTypes = {
    drawerWidth: PropTypes.number.isRequired,
    onDrawerToggle: PropTypes.func.isRequired
}

export default AppTopBar;
