/* React */
import React from "react";
import { createRoot } from "react-dom/client";

/* Utils. */
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import "moment-timezone";

/* Material UI */
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from '@mui/x-license-pro';

/* Redux */
import { Provider as ReduxProvider } from "react-redux";
import store from "./redux";

/* Theme */
import createTheme from "./theme/acromove";

/* Custom Context */
import { SettingsConsumer, SettingsProvider } from "@acromove/contexts/settings";

/* Components */
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./i18n";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

LicenseInfo.setLicenseKey('57db7a9560009af47909ea16a77df288Tz00NzkwMixFPTE2OTAzNjUxODM0MjIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

root.render(
	<BrowserRouter>
		<ReduxProvider store={store}>
			<SettingsProvider>
				<SettingsConsumer>
					{({ settings }) => (
						<ThemeProvider
							theme={createTheme({
								direction: settings.direction,
								responsiveFontSizes: settings.responsiveFontSizes,
								mode: settings.theme,
								colors: settings?.colors,
								logo: settings?.logo
							})}
						>
							<CssBaseline />
							<Toaster position="top-right" />
							<App />
						</ThemeProvider>
					)}
				</SettingsConsumer>
			</SettingsProvider>
		</ReduxProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
