import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "@acromove/utils/helpers";
import qs from "qs";

const machinesAPI = createApi({
	reducerPath: "machines/api",
	baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("machines")),
	keepUnusedDataFor: 30,
	tagTypes: ["MachinesFind"],
	endpoints: (build) => ({
		findUnassignMachines: build.query({
			query: ({ limit, offset, sortItem, sortDir, search }) => ({
				url: "find/unassign",
				params: qs.stringify({
					limit,
					offset,
					sortItem,
					sortDir,
					search,
				}),
			}),
			transformResponse: (response) => ({
				total: response.payload.total,
				items: response.payload.items.map((item) => ({
					...item,
					name: `${item.nickname}(${item.serial})`
				})),
			}),
			providesTags: ["MachinesFind"],
		}),
		assignToCluster: build.mutation({
			query: ({serial, clusterId}) => ({
				url: `/assign/${serial}/to/cluster/${clusterId}`,
				method: "PATCH",
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["MachinesFind"],
		}),
		unassignFromCluster: build.mutation({
			query: ({serial, clusterId}) => ({
				url: `/unassign/${serial}/from/cluster/${clusterId}`,
				method: "DELETE",
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["MachinesFind"],
		}),
		restartMachine: build.mutation({
			query: ({serial, controlId}) => ({
				url: `/restart/${controlId}/on/${serial}`,
				method: "PUT",
			}),
			transformResponse: (response) => response.payload,
		})
	}),
});

export const {
	useLazyFindUnassignMachinesQuery,
	useFindUnassignMachinesQuery,
	useAssignToClusterMutation,
	useUnassignFromClusterMutation,
	useRestartMachineMutation
} = machinesAPI;

export default machinesAPI;
