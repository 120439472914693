import React from "react";
import PropTypes from "prop-types";

/* Components */
import PowerSource from "./items/power_source";

/* MUI */
import Button  from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

/* Utils */
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useFormContext from "@acromove/components/form/hooks/use-form-context";

const PowerSources = ({ spaceIndx }) => {
	const { t } = useTranslation("sites_create", { keyPrefix: "sites.spaces[].power_sources[]" });
	const { form } = useFormContext();

	const watchLabel = (index) => form.watch(`spaces[${spaceIndx}].power_sources[${index}].label`);
	const watchSource = (index) => form.watch(`spaces[${spaceIndx}].power_sources[${index}].source`);

	const {
		fields = [],
		append,
		remove,
	} = useFieldArray({ control: form.control, name: `spaces[${spaceIndx}].power_sources`, keyName: "uid" });

	return (
		<>
			<Button
				variant="contained"
				onClick={() => append({ label: "", source: "ac_power", capacity: "", voltage: "", fuse_max: "", images: [], $removedImages: [] })}
			>
				<AddIcon sx={{ mr: 1 }} />
				{t("add")}
			</Button>
			<>
				{fields.map((field, index) => (
					<PowerSource
						t={t}
						field={field}
						watchLabel={watchLabel}
						watchSource={watchSource}
						spaceIndx={spaceIndx}
						index={index}
						remove={remove}
					/>
				))}
			</>
		</>
	);
};

PowerSources.propTypes = {
	spaceIndx: PropTypes.number.isRequired,
};

export default PowerSources;
