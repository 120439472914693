import {useEffect, useState} from "react";
import { useLocation, useParams } from "react-router-dom";

const useOriginPath = () => {
    const location = useLocation();
    const params = useParams();
    const [origin, setOrigin] = useState(location.pathname);

    const getOrigin = (p, urlParams) => {
		const path = decodeURI(p)
        let formattedOrigin = decodeURI(path);
        for (const param in urlParams) {
            const part = `/${urlParams[param]}`;
            if(path.includes(part)){
                formattedOrigin = formattedOrigin.replace(part, `/:${param}`)
            }
        }
        setOrigin(formattedOrigin)
    }

    useEffect(() => {
        getOrigin(location.pathname, params)
    }, [location, params])

    return {originPath: origin, params, location};
}

export default useOriginPath;
