import {createSlice} from "@reduxjs/toolkit";

const initialState = { 
  error: null,
  loading: true,
  notification: null,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleLoading(state, action){
      state.loading = action.payload
    },
    clearError(state){
      state.error = null;
    },
    clearNotification(state){
      state.notification = null;
    },
    setError(state, action){
      state.error = action.payload;
    }
  },
})

export const { 
    toggleLoading,
    clearNotification,
    clearError,
    setError
} = appSlice.actions

export default appSlice.reducer