import React, { useState, useEffect } from "react";

/* MUI */
import Button from "@mui/material/Button";

/* Components */
import Container from "components/layouts/dashboard/ui/container";
import DataGridTable from "@acromove/components/data-grid";
import DataGridProvider from "@acromove/components/data-grid/data-grid-provider";
import ProtectedComponent from "@acromove/authentication/components/protected/component";
import GroupTypeDetails from "components/content/acro-dashboard/group-types/group-type-details";
import Dialog from "@acromove/components/dialog";
import { groupTypes } from "components/table-columns/sys_admin";
import typePermissions from "components/content/acro-dashboard/group-types/permissions"

/* Redux */
import { useFindGroupTypesQuery, useCreateGroupTypeMutation } from "redux/group-types/group-types.api";

/* Utils */
import { useTranslation } from "react-i18next";
import useItemsTranslation from "@acromove/hooks/use-items-translation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const GroupTypesContainer = () => {
	const { t } = useTranslation("acro_admin_group-types");
	const itemsTranslator = useItemsTranslation("acro_admin_group-types");
	const [open, setOpen] = useState(false);

	const [createGroupType, { data, isSuccess, isLoading }] = useCreateGroupTypeMutation();

	const onGroupTypeSubmit = (values) => {
		console.log(values);
		const permissionAliases = typePermissions
			.map((item) => (values[item.name] ? item.alias : false))
			.filter((item) => item);
		createGroupType({ name: values.name, permissions_aliases: permissionAliases });
	};

	const schema = yup.object({
		name: yup.string().required(t("group_types.name.required")),
	});

	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			name: data?.name,
			groupsAddAllFields: data?.permissions_aliases?.includes("groups-add_all_fields"),
			groupsEditAllFields: data?.permissions_aliases.includes("groups-edit_all_fields"),
			usersUnassignFromGroup: data?.permissions_aliases.includes("users-unassign-from-group"),
			usersAssignToGroup: data?.permissions_aliases.includes("users-assign-to-group"),
		},
	});

	const handleCreate = () => {
		if (open) {
			form.handleSubmit(onGroupTypeSubmit)();
			if (!Object.keys(form.formState.errors).length) {
				setOpen(true)
			}
		} 
	};

	useEffect(() => {
		if(isSuccess){
			setOpen(false)
			form.reset(data)
		} 
	}, [isSuccess])

	useEffect(() => {
		form.reset(data);
	}, [form.reset, data]);

	return (
		<>
			<Dialog
				loading={isLoading}
				maxWidth="md"
				fullWidth
				open={open}
				onClose={() => setOpen(false)}
				hideButtons
				title={t("add_title")}
			>
				<ProtectedComponent slug="group_types:add">
					<GroupTypeDetails
						mode="add"
						form={form}
						isLoading={isLoading}
						isSuccess={isSuccess}
						submit={handleCreate}
						permissions={typePermissions}
					/>
				</ProtectedComponent>
			</Dialog>
			<Container>
				<DataGridProvider useHook={useFindGroupTypesQuery} initSort="name">
					<DataGridTable
						collapsible
						search
						action={
							<ProtectedComponent slug="group_types:add">
								<Button variant="contained" onClick={() => setOpen(true)}>
									{t("add")}
								</Button>
							</ProtectedComponent>
						}
						pinnedColumns={{
							left: ["name"],
							right: ["url"]
						}}
						title={t("title")}
						columns={itemsTranslator(groupTypes, "table", "field", "headerName")}
					/>
				</DataGridProvider>
			</Container>
		</>
	);
};

export default GroupTypesContainer;
