
/**
 * 
 * @param {"row" | "column"} direction 
 * @param {"flex-start" | "center" | "flex-end" | "space-between"} justify 
 * @param {"flex-start" | "center" | "flex-end"} align 
 * @returns {object}
 */
const flex = (direction = "row", justify = "flex-start", align = "flex-start") => ({
    display: "flex",
    flexDirection: direction,
    justifyContent: justify,
    alignItems: align,
})

const fontWeightMap = {
    medium: 500,
    semiBold: 600
}

/**
 * 
 * @param {"secondary" | "active" | "primary"} color 
 * @param {number} size 
 * @param {"medium" | "semiBold"} weight 
 * @returns {object}
 */
const font = (color = "secondary", size = 14, weight = "medium") => ({
    color: `text.${color}`,
    fontSize: size,
    fontWeight: fontWeightMap[weight]
})

export default {
    flex,
    font
}