import React, { useState, useEffect } from "react";

/* MUI */
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";

/* Components */
import Container from "components/layouts/dashboard/ui/container";
import Section from "components/layouts/dashboard/ui/section";
import GroupTypeDetails from "components/content/acro-dashboard/group-types/group-type-details";
import ProtectedComponent from "@acromove/authentication/components/protected/component";
import Dialog from "@acromove/components/dialog";
import typePermissions from "components/content/acro-dashboard/group-types/permissions"

/* Redux */
import { useFindOneGroupTypeQuery, useEditGroupTypeMutation, useRemoveGroupTypeMutation } from "redux/group-types/group-types.api";

/* Utils */
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";


const GroupTypeContainer = () => {
	const { t } = useTranslation("acro_admin_group-types");
	const { groupTypeId } = useParams();
	const Id = parseInt(groupTypeId.split("-")[0], 10);
	const [editMode, setEditMode] = useState(false);
	const [openRemove, setOpenRemove] = useState(false);
	const navigate = useNavigate();

	const { data, isLoading } = useFindOneGroupTypeQuery({ id: Id });
	const [editGroupType, { isLoading: editLoading, isSuccess: editSuccess }] = useEditGroupTypeMutation({ id: Id });
	const [removeGroupType, {isSuccess: removeSuccess}] = useRemoveGroupTypeMutation({ id: Id });

	const schema = yup.object({
		name: yup.string().required(t("group_types.name.required")),
	});

	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: data || {
			name: "", 
			...typePermissions.reduce((acc, item) => {
				acc[item.name] = false //eslint-disable-line
				return acc
			}, {})
		},
	});

	useEffect(() => {
		form.reset(data);
	}, [form.reset, data]);

	useEffect(() => {
		if (removeSuccess) {
			navigate("/acromove/group_types")
		}
	}, [removeSuccess]);

	const handleCancelEdit = () => {
		if (!editMode) {
			setEditMode(true);
		} else if (editMode) {
			form.reset(data);
			setEditMode(false);
		}
	};

	const onGroupTypeEditSubmit = async (values) => {
		const permissionAliases = typePermissions
			.map((item) => (values[item.name] ? item.alias : false))
			.filter((item) => item);
		await editGroupType({ value: { name: values.name, permissions_aliases: permissionAliases }, Id });
        form.reset(data)
	};

	const onGroupTypeRemoveSubmit = () => {
		removeGroupType({ id: Id })
	}

	const handleClose = (isAgree) => {
		if (isAgree) {
			form.handleSubmit(onGroupTypeRemoveSubmit)()	
		} else setOpenRemove(false);
	};

	const handleSaveEdit = async () => {
		const result = await form.trigger()
		if (result) {
			form.handleSubmit(onGroupTypeEditSubmit)();
			setEditMode(!editMode)
		}
	};

	return (
		<>
		<Dialog
		loading={isLoading}
		maxWidth="sm"
		labelCancel={t("cancel")}
		labelOk={t("confirm")}
		fullWidth
		open={openRemove}
		onClose={handleClose}
		title={t("remove_title")}
		>
			{t("remove_confirmation")}
		</Dialog>
		<Container>
			<Container.Header title="Back" back={-1} />
			<Section
				collapsible
				title={t("group_types.title.label")}
				loading={isLoading}
				action={
					<ProtectedComponent slug="group_types:edit">
						<Button onClick={handleCancelEdit} startIcon={editMode ? <CancelIcon /> : <BorderColorIcon />}>
							{editMode ? t("cancel") : t("edit")}
						</Button>
					</ProtectedComponent>
				}
			>
				<ProtectedComponent slug="group_types:findOne">
					<GroupTypeDetails
						mode="edit"
						editMode={editMode}
						form={form}
						isLoading={editLoading}
						isSuccess={editSuccess}
						submit={handleSaveEdit}
						submitRemove={() => setOpenRemove(true)}
						permissions={typePermissions}
					/>
				</ProtectedComponent>
			</Section>
		</Container>
		</>
	);
};

export default GroupTypeContainer;
