import { useTheme } from "@mui/styles";
import L from "leaflet";
import { useState, useEffect } from "react";

 const useFormatGroups = (groups) => {
	const theme = useTheme()
	const [formattedGroups, setFormattedGroups] = useState([])

	useEffect(() => {
		const local = []
		for (const group of groups) {
			const iconRecolor = (typeof group.icon === "function") ?  group.icon(theme.palette.text.active) : group.icon
			const icon = new L.Icon({
				iconAnchor: [10, 10],
				popupAnchor: [0, -35],
				iconSize: [20, 20],
				iconUrl: iconRecolor,
				iconRetinaUrl: iconRecolor,
			});

			local.push({
				...group,
				mapIcon: icon,
				navIcon: iconRecolor
			})
		}
		setFormattedGroups(local)
	}, [groups])

	return formattedGroups;
}

export default useFormatGroups
