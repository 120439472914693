import React from "react";
import PropTypes from "prop-types";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const SortUp = ({sortingOrder: _, ...other}) => (<ArrowDropUpIcon {...other} />)

SortUp.propTypes = {
    sortingOrder: PropTypes.string
}

SortUp.defaultProps = {
    sortingOrder: null
}


export default SortUp;