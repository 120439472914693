import React from "react";

/* Components */
import Form from "@acromove/components/form";
import Input from "@acromove/components/form/input";
import ProtectedComponent from "@acromove/authentication/components/protected/component";
import Section from "components/layouts/dashboard/ui/section";
import DragNDropDialog from "@acromove/components/form/drag-n-drop-dialog";

/* MUI */
import Grid from "@mui/material/Grid";

/* Utils */
import { useTranslation } from "react-i18next";

const SiteInfo = () => {
	const { t } = useTranslation("sites_create");
	return (
		<Section
			title={t("section_titles.site_info.label")}
			action={
				<ProtectedComponent slug="sites:import">
					<Form.Field name="images">
						<DragNDropDialog fileTypes={["jpg", "png"]} multiple removedImagesKey="$removedImages" />
					</Form.Field>
				</ProtectedComponent>
			}
		>
			<Grid container spacing={1} sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", mt: 2 }}>
				<Grid item md={3}>
					<Form.Field name="name">
						<Input size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
				<Grid item md={3}>
					<Form.Field name="demographic">
						<Input size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
				<Grid item md={3}>
					<Form.Field name="latitude">
						<Input disabled size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
				<Grid item md={3}>
					<Form.Field name="longitude">
						<Input disabled size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
			</Grid>
		</Section>
	);
};

export default SiteInfo;
