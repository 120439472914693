import React from "react";
import constants from "@acromove/constants";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import UserProfileContainer from "containers/user/profile";

const UserProfilePage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={constants.ALL_ROLES}>
            <UserProfileContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default UserProfilePage;