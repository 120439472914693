/* eslint-disable */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "../../utils/helpers"


const uploadsAPI = createApi({
    reducerPath: "uploads/api",
    baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("uploads")),
    endpoints: build => ({
        getImage: build.query({
            query: ({type, fileName}) => ({
                url: `images/${type}/${fileName}`
            }),
            transformResponse: (response) => response.payload
        }),
		uploadImages: build.mutation({
            query: ({entity, entity_address, images}) => {
				const formData = new FormData();
				formData.append("entity", entity)
				formData.append("entity_address", entity_address)
				for (const img of images) {
					formData.append("images", img)
				}
				return {
					method: "POST",
					body: formData
				}
			},
            transformResponse: (response) => response.payload
        }),
		uploadImage: build.mutation({
            query: ({entity, entity_address, image}) => {
				const formData = new FormData();
				formData.append("entity", entity)
				formData.append("entity_address", entity_address)
				formData.append("image", image)
				return {
					method: "PUT",
					body: formData
				}
			},
            transformResponse: (response) => response.payload
        }),
        removeAssets: build.mutation({
            query: ({ids}) => ({
                body: {ids},
                method: "DELETE",
            })
        })
    })
})



export const {
    useLazyGetImageQuery,
	useUploadImagesMutation,
    useRemoveAssetsMutation
} = uploadsAPI

export default uploadsAPI;
