import React from "react";
import PropTypes from "prop-types";

import formContext from "./contexts/form";
import Box from "@mui/material/Box";
import FormField from "./field";
import FormGroup from "./group";
import useFormGroupInternal from "./hooks/use-form-group-internal";

const Form = (props) => {
    const {name, children, sx, form, i18n, alias, fieldNamePrefix, disabled, mode, onSubmit, localeName} = props;
	useFormGroupInternal(alias || `${name}${fieldNamePrefix ? `.${fieldNamePrefix}` : ""}`, form)
    return(
        <formContext.Provider value={{ name, form, disabled, fieldNamePrefix, i18n, mode, localeName }}>
            <Box onSubmit={onSubmit} component="form" sx={sx}>
                    {children}
            </Box>
        </formContext.Provider>
    );
}

Form.propTypes = {
	alias: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
	fieldNamePrefix: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    form: PropTypes.shape({
		control:  PropTypes.shape({})
	}).isRequired,
    sx: PropTypes.shape({}),
    i18n: PropTypes.string.isRequired,
    mode: PropTypes.string,
    onSubmit: PropTypes.func,
    localeName: PropTypes.string
}

Form.defaultProps = {
	alias: false,
	fieldNamePrefix: false,
    sx: {},
    disabled: false,
    mode: "edit",
    onSubmit: () => null,
    localeName: null
}

Form.Field = FormField;
Form.Group = FormGroup;

export default Form;
