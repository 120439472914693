import React from "react";
import constants from "@acromove/constants";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";
import { Navigate } from "react-router-dom";

const HomePage = () => (
    <RoleSwitcher redirect="/login">
		<RoleSwitcher.Role value={constants.ALL_ROLES}>
			<Navigate to="/sites" />
		</RoleSwitcher.Role>
	</RoleSwitcher>
)

export default HomePage;