import React from "react";
import PropTypes from "prop-types";

/* MUI */
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

/* Utils */
import {useNavigate} from "react-router-dom";

/**
 * Table Icon Link component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Table Icon Link Component.
 */
const TableIconLink = ({value, baseUrl, icon, type, action}) => {
	const navigate = useNavigate()
	return (
	<Box sx={{ px: "10px", display: "flex", justifyContent: "flex-end" }}>
		{
			type === "link" ?
			<IconButton onClick={() => navigate(`${baseUrl}/${value}`)}>
				{icon}
			</IconButton>
			:
			<IconButton onClick={action} >
				{icon}
			</IconButton>
		}
	</Box>
)}

/**
 * Table Icon Link Properties.
 *
 * @typedef {object} Props
 * @property {React.ReactNode} icon - Table Link Icon,
 * @property {string | number} [value = ""] - Table Link Value.
 * @property {string} [baseUrl = ""] - Base Url passed to Link.
 * @property {"button" | "link"} [type = "link"] - Defines type of Table Icon Link either it's button or link.
 * @property {function} [action = () => null] - Action passed to component when type is "button".
 */

TableIconLink.propTypes = {
    icon: PropTypes.node.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	baseUrl: PropTypes.string,
	type: PropTypes.oneOf(["button", "link"]),
	action: PropTypes.func
}

TableIconLink.defaultProps ={
	value: "",
	baseUrl: "",
	type: "link",
	action: () => null
}


export default TableIconLink