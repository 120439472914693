import React from "react";
import PropTypes from "prop-types";

/* Components */
import Form from "@acromove/components/form";
import Input from "@acromove/components/form/input";
import Select from "@acromove/components/form/select";
import Section from "components/layouts/dashboard/ui/section";

/* MUI */
// import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, InputAdornment } from "@mui/material";

/* Utils */
import useFormNotices from "@acromove/hooks/use-form-warnings-parser";
import useFormContext from "@acromove/components/form/hooks/use-form-context";
import DragNDropDialog from "@acromove/components/form/drag-n-drop-dialog";

const PowerSource = (props) => {
	const { t, field, watchLabel, watchSource, spaceIndx, index } = props;
    const {form} = useFormContext()
    useFormNotices(form, {
        t,
		field: `spaces[${spaceIndx}].power_sources[${index}]`,
    })
	return (
		<Section
			key={field.id}
			title={watchLabel(index)}
			collapsible
			action={
				<Form.Field name={`spaces[${spaceIndx}].power_sources[${index}].images`}>
					<DragNDropDialog
						fileTypes={["jpg", "png"]}
						multiple
						removedImagesKey={`spaces[${spaceIndx}].power_sources[${index}].$removedImages`}
						iconButton
					/>
				</Form.Field>
			}
		>
			<Grid
				container
				spacing={1}
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					flexWrap: "nowrap",
					mt: 1,
				}}
			>
				<Grid item md={4}>
					<Form.Field name={`spaces[${spaceIndx}].power_sources[${index}].label`}>
						<Input label={t("label.label")} size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
				<Grid item md={4} lg={4}>
					<Form.Field name={`spaces[${spaceIndx}].power_sources[${index}].source`}>
						<Select
							label={t("source.label")}
							size="medium"
							sx={{ width: "100%" }}
							options={[
								{ label: t("source.options.ac_power.label"), value: "ac_power" },
								{ label: t("source.options.generator.label"), value: "generator" },
								{ label: t("source.options.battery.label"), value: "battery" },
								{ label: t("source.options.custom.label"), value: "custom" },
							]}
						/>
					</Form.Field>
				</Grid>
				{watchSource(index) === "custom" && (
					<Grid item md={4}>
						<Form.Field name={`spaces[${spaceIndx}].power_sources[${index}].custom_source`}>
							<Input label={t("custom_source.label")} size="medium" sx={{ width: "100%" }} />
						</Form.Field>
					</Grid>
				)}
			</Grid>
			<Grid
				container
				spacing={1}
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					flexWrap: "nowrap",
					mt: 1,
				}}
			>
				<Grid item md={4}>
					<Form.Field name={`spaces[${spaceIndx}].power_sources[${index}].capacity`}>
						<Input label={t("capacity.label")} size="medium" sx={{ width: "100%" }} />
					</Form.Field>
				</Grid>
				<Grid item md={4}>
					<Form.Field name={`spaces[${spaceIndx}].power_sources[${index}].voltage`}>
						<Input
							endAdornment={<InputAdornment position="end">V</InputAdornment>}
							label={t("voltage.label")}
							size="medium"
							sx={{ width: "100%" }}
						/>
					</Form.Field>
				</Grid>
				<Grid item md={4}>
					<Form.Field name={`spaces[${spaceIndx}].power_sources[${index}].fuse_max`}>
						<Input
							endAdornment={<InputAdornment position="end">W</InputAdornment>}
							label={t("fuse_max.label")}
							size="medium"
							sx={{ width: "100%" }}
						/>
					</Form.Field>
				</Grid>
			</Grid>
		</Section>
	);
};

PowerSource.propTypes = {
	spaceIndx: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    watchLabel: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    field: PropTypes.shape({
		id: PropTypes.number.isRequired
	}).isRequired,
    // remove: PropTypes.func.isRequired,
    watchSource: PropTypes.func.isRequired
}

export default PowerSource;
