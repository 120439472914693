import React from "react";

import TableIconLink from "@acromove/components/table/cells/table-icon-link";
import TableStatusCell from "@acromove/components/data-grid/cells/table-status-cell";

/* Assets */
import ArrowRightIcon from "components/icons/arrow-right";
import DataGridIconLink from "@acromove/components/data-grid/cells/datagrid-icon-link";
import DataGridIconTooltipCell from "@acromove/components/data-grid/cells/datagrid-icon-tooltip-cell";


export const sitesMain = [
	{
		Header: "Site Name",
		accessor: "site_name",
		sortable: true,
	},
	{
		Header: "Site State",
		accessor: "site_state",
	},
	{
		Header: "Region",
		accessor: "region",
	},
	{
		Header: "Zone",
		accessor: "zone",
	},
	{
		Header: "__empty",
		accessor: "id",
		Cell: (
			props // eslint-disable-line
		) => (
			<TableIconLink
				icon={<ArrowRightIcon color="primary" />}
				baseUrl="/sites/browse/view"
				value={`${props.value}-${props.row.original.site_name.replace(/ /g, "_")}`} // eslint-disable-line
			/>
		),
	},
];

export const mySitesMain = [
	{
		headerName: "name",
		field: "name",
		minWidth: 200,
		// maxWidth: 100,
		sortable: true,
	},
	{
		headerName: "status",
		field: "status",
		minWidth: 200,
		sortable: false,
	},
	{
		headerName: "countryName",
		field: "countryName",
		minWidth: 200,
		sortable: false,
	},
	{
		headerName: "cityName",
		field: "cityName",
		minWidth: 200,
		sortable: false,
	},
	// {
	//     Header: "Pending Actions",
	//     accessor: "pending",
	// },
	// {
	// 	Header: "Region",
	// 	accessor: "region",
	// },
	// {
	// 	Header: "Zone",
	// 	accessor: "zone",
	// },
	// {
	// 	Header: "__empty",
	// 	accessor: "id",
	// 	Cell: (
	// 		props // eslint-disable-line
	// 	) => (
	// 		<TableIconLink
	// 			icon={<ArrowRightIcon color="primary" />}
	// 			baseUrl="/sites/my/view"
	// 			value={`${props.value}-${props.row.original.site_name.replace(/ /g, "_")}`} // eslint-disable-line
	// 		/>
	// 	),
	// },
	{
		headerName: "url",
		field: "url",
		flex: 0.5,
		align: "right",
		sortable: false,
		renderCell: DataGridIconLink
	}
];

export const mySitesSpaces = (props) => {
	const { openSpace } = props;
	return [
		{
			headerName: "has_notices",
			field: "has_notices",
			sortable: false,
			renderCell: DataGridIconTooltipCell
		},
		{
			headerName: "name",
			field: "name",
			minWidth: 300,
			sortable: true,
		},
		{
			headerName: "type",
			field: "type",
			sortable: false,
		},
		{
			headerName: "location",
			field: "location",
			sortable: false,
		},
		{
			headerName: "access_type",
			field: "access_type",
			minWidth: 150,
			sortable: false,
		},
		{
			headerName: "id",
			field: "id",
			flex: 0.5,
			align: "right",
			sortable: false,
			renderCell: (cellProps) => {
				const { row } = cellProps;
				return (
					<DataGridIconLink
						func={() => {
							openSpace(row.uid)
						}}
					/>
				);
			},
		},
	];
};
export const siteCluster = [
	{
		Header: "__empty",
		accessor: "status",
		maxWidth: "5px",
		width: "5px",
		Cell: (
			props // eslint-disable-line
		) => <TableStatusCell status={props?.row?.original?.status} />, // eslint-disable-line
	},
	{
		Header: "List Item",
		accessor: "list_item",
		width: "20%",
	},
	{
		Header: "State",
		accessor: "state",
	},
	{
		Header: "__empty",
		accessor: "id",
		Cell: (
			props // eslint-disable-line
		) => (
			<TableIconLink
				icon={<ArrowRightIcon color="primary" />}
				// baseUrl="/sites/my/view"
				// value={null} // eslint-disable-line
			/>
		),
	},
];

export const siteAdditional = [
	{
		Header: "Service Category",
		accessor: "category",
		width: "20%",
	},
	{
		Header: "Additional Service",
		accessor: "service",
	},
];

export default {
	sitesMain,
	mySitesMain,
	siteCluster,
	siteAdditional,
};
