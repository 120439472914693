const container = {
    width: "100vw",
    height: "100vh",
    flexDirection: "row",
    flexWrap: {
        lg: "nowrap",
        md: "wrap",
    },
    justifyConten: {
        lg: "center",
        md: "flex-start",
    },
    alignItems: {
        lg: "center",
        md: "flex-start",
    },
}

const logoArea = {
    clipPath: {
        xs: "url(#myCurve)",
        md: "unset",
    },
    flexBasis: {
        md: "50%",
        xs: "100%",
    },
    bgcolor: "#211D1C",
    height: {
        md: "100%",
        xs: "50%",
    },
    position: "relative",
}

const logo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
}

const fromArea = {
    flexBasis: {
        md: "50%",
        xs: "100%",
    },
    height: {
        md: "100%",
        xs: "50%",
    },
}

const fromWrap = {
    width: "80%",
    m: "0 auto",
    height: "100%",
}

const from = {
    gap: 2.5,
    width: "100%",
}

const inputsWrap = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    fieldset: {
        border: "none",
    },
}

const qrWrap = {
    display: {
        md: "block",
        xs: "none",
    },
}

export default { container, logoArea, logo, fromArea, fromWrap, from, inputsWrap, qrWrap }