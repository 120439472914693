import SmartRouter from "@acromove/utils/smart-router";
import DashboardLayout from "components/layouts/dashboard";

import ClientsGroupsPage from "pages/clients";
import ClientsCreateGroupPage from "pages/clients/create";
import ClientsViewGroupPage from "pages/clients/view-group";
import ClientsViewUserPage from "pages/clients/view-user";

const router = new SmartRouter();


router.addRoute("/clients", {
    title: "Clients",
    Layout: DashboardLayout, 
    accessSlug: "clients",
    Container: ClientsGroupsPage,
})

router.addRedirect("/clients/users/view", "/clients")

router.addRoute("/clients/users/view/:userId", {
    title: "View User",
    Container: ClientsViewUserPage,
    Layout: DashboardLayout, 
    accessSlug: "clients_users_view", 
    childOf: "/clients",
    ignoreOnNav: true,
})

router.addRoute("/clients/groups", {
    title: "Groups",
    Container: ClientsGroupsPage, 
    Layout: DashboardLayout, 
    accessSlug: "clients_groups",
    childOf: "/clients", 
    ignoreOnNav: true,
})

router.addRoute("/clients/groups/create", {
    title: "Create Group",
    Container: ClientsCreateGroupPage, 
    Layout: DashboardLayout, 
    accessSlug: "clients_groups_create",
    childOf: "/clients",
    ignoreOnNav: true, 
})

router.addRedirect("/clients/view", "/clients")

router.addRoute("/clients/view/:groupId", {
    title: "View Group",
    Container: ClientsViewGroupPage,
    Layout: DashboardLayout, 
    accessSlug: "clients_groups_view", 
    childOf: "/clients/groups",
    ignoreOnNav: true,
})


export default router;