import React from "react";
import PropTypes from "prop-types";

/* MUI */
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

/* Utils */
import useField from "./hooks/use-filed";
import moment from "moment";

/**
 * Select Timezone component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Form Controlled Select Timezone Component.
 */
const SelectTimezone = (props) => {
	const { label, name: fieldName, control, defaultValue, disabled, ...rest } = props;
    
    const field = useField({
        fromProps: {
            label,
            name: fieldName,
            defaultValue,
            disabled,
			control,
        }
    })

    const { onChange, onBlur, name, value, ref } = field.controller.field;
	const { error } = field.controller.fieldState;

	return (
		<Autocomplete
            helperText={error?.message}
            error={error}
            onChange={(e, a) => {
                onChange({
                    ...e,
                    target: {
                        ...e.target,
                        value: a.value
                    }
                })
            }}
            onBlur={onBlur}
            name={name}
            value={value}
			disablePortal
            disabled={disabled}
            ref={ref}
			id={`timezone-selector-${fieldName}`}
			options={moment.tz.names().map(tz => ({label: tz, value: tz}))}
			renderInput={(params) => <TextField {...params} label={label} />}
            {...rest}
		/>
	);
};

/**
 * Select Timezone Properties.
 *
 * @typedef {object} Props
 * @property {string} label - Select Timezone Label.
 * @property {object} control - Form control options.
 * @property {string} name - Form Select Timezone Name.
 * @property {string | number | null} [defaultValue = "null"] - Default Value for Select Timezone.
 */

SelectTimezone.propTypes = {
    control: PropTypes.shape({}),
    label: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(null)]),
    disabled: PropTypes.bool,
}

SelectTimezone.defaultProps = {
    control: {},
    label: "",
    name: "",
    defaultValue: null,
    disabled: false,
}


export default SelectTimezone;
