import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";

/* Components */
import SimpleStat from "./SimpleStat";


/**
 * Stats Box component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Stats Box Component to map Simple Stats if needed.
 */
const StatsBox = ({ label, stat, multiply, stats, append }) => (
	<Grid
		container
		sx={{
			borderRadius: "8px",
			border: (theme) => `1px solid ${theme.palette.divider}`,
			p: 1,
			textAlign: "center",
			justifyContent: "space-between",
			mt: "30px",
		}}
	>
		{multiply ? (
			stats.map((s) => (
				<SimpleStat key={s.label} label={s.label} value={s.stat} labelPosition="top" sx={{ marginRight: "auto" }} />
			))
		) : (
			<SimpleStat label={label} value={stat} labelPosition="top" />
		)}
		{append}
	</Grid>
);

/**
 * SimpleBox Properties.
 *
 * @typedef {object} Props
 * @property {string} [label = ""] - Label of the component.
 * @property {string} [stat = ""] - value provided to Simple Stat.
 * @property {true | false} [multiply = "false"] - property used to define whether it's array or single component is provided.
 * @property {object[]} stats - provided when array of stats is used.
 * @property {string} stats.label
 * @property {string} stats.stat
 * @property {React.ReactNode} append - used to append elements to the component.
 */

StatsBox.propTypes = {
	label: PropTypes.string,
	stat: PropTypes.string,
	multiply: PropTypes.bool,
	stats: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			stat: PropTypes.string.isRequired,
		})
	),
	append: PropTypes.node,
};

StatsBox.defaultProps = {
	label: "",
	stat: "",
	multiply: false,
	stats: [],
	append: null,
};

export default StatsBox;
