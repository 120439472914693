import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
import baseThemeOptions from './base-theme-options';
import darkThemeOptions from './dark-theme-options';
import lightThemeOptions from './light-theme-options';
import { tint, shade } from 'tint-shade-color';
import acromoveLogo  from "assets/images/logo.svg"
import _ from "lodash"


const createTheme = (config) => {
  console.log(config)
  let theme = createMuiTheme(baseThemeOptions,
    config.mode === 'dark' ? darkThemeOptions : lightThemeOptions,
    {
      direction: config.direction
    });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }
  
  if(config.colors){
    theme.palette.primary = {
      main: config.colors.accentColor,
      light: tint(config.colors.accentColor, 0.3),
      dark: shade(config.colors.accentColor, 0.2),
      shade: "#FA9579",
      contrastText: '#FFFFFF'
    }
    theme.palette.sideMenu.background = config.colors.navigationBg
    theme.palette.text.active = config.colors.accentColor
    theme.palette.sideMenu.divider = config.colors.navigationDivider
    theme.palette.sideMenu.text = config.colors.navigationFontColor
  }

  _.set(theme, "assets.logo", config?.logo ? config?.logo : acromoveLogo)

  return theme;
};

export default createTheme