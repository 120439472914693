import React from "react";

/* MUI */
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

/* Components */
import Chart from "@acromove/components/chart";
import StatsBox from "@acromove/components/statistics/StatsBox";

/* Utils */
import { useTranslation } from "react-i18next";
import useItemsTranslation from "@acromove/hooks/use-items-translation";
import sh from "@acromove/utils/styles-helpers";

/* Mock */
import mock from "mock/acro-admin-dashboard";

const ResourcesStats = () => {
	const { t } = useTranslation("acro_admin_dashboard");
	const itemsTranslator = useItemsTranslation("acro_admin_dashboard");

	const data = {
		...mock.physicalResourcesStatsChartData,
		series: itemsTranslator(
			mock.physicalResourcesStatsChartData.series,
			"physical_resources_stats.chart",
			"slug",
			"name"
		),
	};

	return (
		<Grid container>
			<Grid item sm={12} md={4} lg={4} sx={{width: "100%"}}>
				<Grid container sx={{ p: "0px 20px", flexDirection: "column", width: "100%" }}>
					<Grid item>
						<StatsBox
							multiply
							stats={itemsTranslator(
								mock.physicalResourcesStats.small,
								"physical_resources_stats.stats",
								"slug",
								"label"
							)}
						/>
					</Grid>
					<Grid item>
						<StatsBox
							label={t(`physical_resources_stats.stats.${mock.physicalResourcesStats.big.slug}`)}
							stat={mock.physicalResourcesStats.big.stat}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item sm={12} md={8} lg={8}>
				<Chart.Provider xAxisCategories={data.xaxis.dataPoints} series={data.series}>
					<Chart.Header title={t("physical_resources_requests.chart.title")}>
						<Chart.Toolbar tools={["download"]} />
					</Chart.Header>
					<Chart
						type="area"
						height={300}
						options={{
							fill: {
								gradient: {
									opacityFrom: 0.4,
									opacityTo: 0.1,
									shadeIntensity: 1,
									stops: [0, 100],
									type: "vertical",
								},
								type: "gradient",
							},
							stroke: {
								curve: "smooth",
								lineCap: "butt",
								width: 3,
							},
						}}
					/>
					<Box sx={[sh.flex("row", "flex-end", "center"), { width: "100%" }]}>
						<Chart.Legends />
					</Box>
				</Chart.Provider>
			</Grid>
		</Grid>
	);
};

export default ResourcesStats;
