import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import React from "react";

const MapGroupControls = (props) => {
	const { groups, onChange, selected } = props;
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				alignItems: "center",
				marginTop: "0px",
			}}
		>
			{groups.map((group) => (
				<Button
					key={group.label}
					startIcon={<img width={15} height={15} src={group.navIcon} alt="icon" />}
					onClick={() => onChange(group.slug)}
					sx={{
						filter: selected.includes(group.slug) ? "unset" : "grayscale(1)",
					}}
				>
					<Typography color="text.secondary">{group.label}</Typography>
				</Button>
			))}
		</Box>
	);
};

MapGroupControls.propTypes = {
	groups: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			slug: PropTypes.string,
			icon: PropTypes.string,
			navIcon: PropTypes.string,
		})
	).isRequired,
	onChange: PropTypes.func.isRequired,
	selected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MapGroupControls;
