import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import useDebounce from "../../hooks/use-debounce";

export const dataContext = createContext({});

/**
 * Table Data Provider Component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Table Data Provider.
 */

const DataGridProvider = (props) => {
	const { useHook, children, hookProps, sx, initSort } = props;
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(5);
	const [search, setSearch] = useState("");
	const [sortDir, setSortDir] = useState("ASC");
	const [sortItem, setSortedItem] = useState(initSort);
	const debounce = useDebounce(search);
	const { data, isFetching, refetch } = useHook({
		offset,
		limit,
		search: debounce,
		sortDir,
		sortItem,
		...hookProps,
	});

	const handlePageChange = (page) => {
		setOffset((page + 1) * limit - limit);
	};

	const handlePageSizeChange = (size) => setLimit(size);

	const handleSortModelChange = (value) => {
		setSortedItem(value[0].field);
		setSortDir(value[0].sort.toUpperCase());
	};

	const handleSearch = (value) =>
		value && value !== "" ? setSearch(value) : setSearch(null);

	useEffect(() => {
        setSearch("")
    }, [hookProps]);

	useEffect(() => {
		refetch()
	}, [])

	return (
		<dataContext.Provider
			sx={sx}
			value={{
				data: data?.items || [],
				total: data?.total || 0,
				loading: isFetching,
				pageSize: limit,
				page: parseInt(offset / limit, 10),
				onPageSizeChange: handlePageSizeChange,
				onPageChange: handlePageChange,
				onSearch: handleSearch,
				handleSortModelChange,
				searchText: search,
				sortDir,
				sortItem,
			}}
		>
			{children}
		</dataContext.Provider>
	);
};

/**
 * Table Data Provider Properties.
 *
 * @typedef {object} Props
 * @property {function} useHook - Hook passed to Provider.
 * @property {React.ReactNode} children - Children Elements.
 * @property {object} [hookProps = {}] - Additional hook props to be passed if needed (f/ex: item id or key defined in parent component).
 * @property {function | object | array} [sx = {}] - Additional CSS options.
 */

DataGridProvider.propTypes = {
	useHook: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	hookProps: PropTypes.shape({}),
	initSort: PropTypes.string,
	sx: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.func,
				PropTypes.object,
				PropTypes.bool,
			])
		),
		PropTypes.func,
		PropTypes.object,
	]),
};

DataGridProvider.defaultProps = {
	initSort: null,
	hookProps: {},
	sx: {},
};

export default DataGridProvider;
