import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";

/* Utils */
import sh from "@acromove/utils/styles-helpers";

const NoData = (props) => {
	const { icon, text, butText, onClick } = props;
	return (
		<Grid
			container
			sx={{
				width: "100%",
				height: "calc(100vh - 44px - 32px - 140px)",
				position: "relative",
				...sh.flex("column", "center", "center"),
			}}
		>
			<Grid container sx={{ gap: 2, width: "100%", ...sh.flex("column", "center", "center") }}>
				<Grid item sx={sh.flex("column", "center", "center")}>
					{icon}
					<Typography color="text.secondary" fontSize={20} fontWeight={400}>
						{text}
					</Typography>
				</Grid>
				<Grid item>
					<Button variant="contained" startIcon={<AddIcon />} onClick={onClick}>
						{butText}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

NoData.propTypes = {
	text: PropTypes.string.isRequired,
	butText: PropTypes.string.isRequired,
	icon: PropTypes.element.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default NoData;
