import Chart from "./chart";
import ChartProvider from "./provider";
import ChartHeader from "./chart-header";
import ChartToolbar from "./chart-toolbar";
import ChartLegends from "./chart-legends";


Chart.Provider = ChartProvider;
Chart.Header = ChartHeader;
Chart.Toolbar = ChartToolbar;
Chart.Legends = ChartLegends;

export default Chart;