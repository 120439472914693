import {useContext, useState} from "react";
import fieldContext from "../contexts/fields"
import { useController } from "react-hook-form";

const useField = (props) => {
    const {fromProps} = props;
    const ctx = useContext(fieldContext);
    const [showHelper, setShowHelper] = useState(false)


	const controller = useController({
		name: ctx?.name || fromProps.name,
		control: ctx?.control || fromProps.control,
		defaultValue: fromProps.defaultValue,
	});

	const getHelperText = () => {
		const helperText = ctx?.helperText || fromProps.helperText
		let text = showHelper && helperText;
		if(controller.fieldState.error){
			text = controller.fieldState.error?.message || controller.fieldState.error?.type
		}
		return text
	}

	const isFieldDisabled = () => {
		if(ctx){
			if(ctx?.disabled?.status && ctx?.disabled?.reason === "acl"){
				return true
			} 
			if(ctx?.disabled?.status && ctx?.disabled?.reason === "form"){
				return fromProps.disabled || ctx?.disabled.status
			}
		}
		return fromProps.disabled
	}

    return {
		...ctx,
		name: ctx?.name || fromProps.name,
		label: ctx?.label || fromProps.label,
		placeholder: ctx?.placeholder || fromProps.placeholder,
        controller,
		helperText: !!(ctx?.helperText || fromProps.helperText),
        helperTextMsg: getHelperText(),
        disabled: isFieldDisabled(),
        showHelper,
        setShowHelper
    }
}

export default useField;