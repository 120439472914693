import React from "react";
import PropTypes from "prop-types";

/* MUI */
import { Button, Divider, List, ListItem, Typography, Box, Grid, Avatar, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import SearchIcon from "@mui/icons-material/Search";

/* Components */
import Section from "components/layouts/dashboard/ui/section";
import SimpleStat from "@acromove/components/statistics/SimpleStat";

/* Utils */
import useImage from "@acromove/hooks/use-image";
import { shade } from "tint-shade-color";

/* Assets */
import transparentBg from "assets/transparent.jpg";
import defLogo from "assets/images/logo.svg";

const SitePreview = (props) => {
	const { logo, accentColor, navBg, navFontColor, navDivider, disabled } = props;

	console.log(accentColor)

	const { url } = useImage(logo);

	return (
		<Grid
			container
			sx={{
				height: "100%",
				borderRadius: "8px",
				padding: 1,
				paddingTop: 2,
				border: "1px solid",
				borderColor: "text.grayLight",
				position: "relative",
				opacity: disabled ? 0.5 : 1,
				backgroundImage: `url(${transparentBg})`,
				backgroundRepeat: "repeat",
			}}
		>
			<Box
				sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: disabled ? 1000 : -1 }}
			/>
			<Typography
				sx={{
					position: "absolute",
					top: "-15px",
					backgroundColor: "#ffffff",
					fontWeight: "500",
					mx: 1,
					px: 1,
					color: "text.secondary",
				}}
			>
				Preview
			</Typography>
			<Grid item md={3}>
				<Grid
					item
					sx={{
						height: "60px",
						backgroundColor: navBg,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<img
						src={url || defLogo}
						style={{ margin: "0 auto", objectFit: "contain" }}
						width="210"
						height="36"
						alt="logotype"
					/>
				</Grid>
				<Divider sx={{ borderColor: navDivider }} />
				<Grid item sx={{ backgroundColor: navBg, height: "calc(100% - 68px)" }}>
					<List sx={{ pl: 2 }}>
						<ListItem sx={{ color: accentColor, fontWeight: 500 }}>Selected Nav Item</ListItem>
						<ListItem sx={{ color: navFontColor, fontWeight: 500 }}>Unselected Nav Item</ListItem>
						<ListItem sx={{ color: navFontColor, fontWeight: 500 }}>Unselected Nav Item</ListItem>
						<ListItem sx={{ color: navFontColor, fontWeight: 500 }}>Unselected Nav Item</ListItem>
					</List>
				</Grid>
			</Grid>
			<Grid item md={9}>
				<Grid item sx={{ height: "60px", backgroundColor: "background.default" }}>
					<Box
						sx={{
							height: "100%",
							gap: 1,
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
							mr: 1,
						}}
					>
						<IconButton size="small">
							<SearchIcon />
						</IconButton>
						<IconButton size="small">
							<GroupIcon />
						</IconButton>
						<IconButton size="small">
							<SettingsIcon />
						</IconButton>
						<Avatar sx={{ width: "25px", height: "25px" }} />
					</Box>
				</Grid>
				<Divider sx={{ borderColor: "text.grayLight", boxShadow: "0px 1px 5px rgba(110, 116, 139, 0.12)" }} />
				<Grid item sx={{ p: 1, backgroundColor: "background.default", height: "calc(100% - 68px)" }}>
					<Section
						title="Preview Section"
						action={
							<Button
								variant="contained"
								onClick={null}
								sx={[{
										"&:hover": {
											backgroundColor: accentColor && shade(accentColor, 0.2),
										},
										backgroundColor: accentColor
									},
								]}
							>
								TEST
							</Button>
						}
					>
						<Grid
							container
							gap={1}
							sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", width: "100%" }}
						>
							<Grid item md={6}>
								<SimpleStat value="16/10" textColor={accentColor} label="Stat Preview" bordered />
							</Grid>
							<Grid item md={6}>
								<SimpleStat value="12/10" textColor={accentColor} label="Stat Preview" bordered />
							</Grid>
						</Grid>
					</Section>
				</Grid>
			</Grid>
		</Grid>
	);
};


SitePreview.propTypes = {
	logo: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.shape({}), PropTypes.oneOf([null])]),
	accentColor: PropTypes.string.isRequired,
	navBg: PropTypes.string.isRequired,
	navFontColor: PropTypes.string.isRequired,
	navDivider: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
};

SitePreview.defaultProps = {
	logo: null
}

export default SitePreview;
