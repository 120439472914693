import React from "react";
import PropTypes from "prop-types";

/* MUI */
import InputLabel from '@mui/material/InputLabel';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MUISelect from '@mui/material/Select';
import FormHelperText from "@mui/material/FormHelperText";

/* Utils */
import sh from "../../utils/styles-helpers"
import useField from "./hooks/use-filed";

/**
 * Select component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Form Controlled Select Component.
 */
const Select = (props) => {
    const {options, label, name: fieldName, defaultValue, helperText, size, disabled, control, ...rest} = props;
   
    const field = useField({
        fromProps: {
            label,
            name: fieldName,
            defaultValue,
            disabled,
			helperText,
			control,
        }
    })
	
    const { onChange, onBlur, name, value = "", ref } = field.controller.field;
	const { error } = field.controller.fieldState;

	return (
        <Box sx={[sh.flex("row", "flex-start", "flex-start"), {width: "100%"}]}>
            <FormControl 
                size={size}
                error={!!error}
                name={name} 
                disabled={field.disabled}
                {...rest} 
                >
                {field.label && <InputLabel id={`${name}-simple-select-label`}>{field.label}</InputLabel>}
                <MUISelect
                    defaultValue={defaultValue}
                    ref={ref}
                    labelId={`${name}-simple-select-label`}
                    id={`${name}-simple-select`} 
                    value={value || ""}
                    label={field.label}
                    onChange={onChange}
                    onBlur={onBlur}
                    >
                        {
                            options.map(op => <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>)
                        }
                </MUISelect>
                {(error || field.helperText) && <FormHelperText>{field.helperTextMsg}</FormHelperText>}
            </FormControl>
            {
				field.helperText && 
				<IconButton sx={{
					mt: 1,
					ml: 1
				}} size={size} color="primary" onClick={() => field.setShowHelper(!field.showHelper)}>
					<InfoOutlinedIcon />
				</IconButton>
			}
        </Box>
	);
};

/**
 * Select Properties.
 *
 * @typedef {object} Props
 * @property {"small" | "medium"} [size = "medium"] - Size of Select.
 * @property {string | true | false | null} [helperText = "null"] - Helper Text of the Radio.
 * @property {object} control - Form control options.
 * @property {string} name - Form Select name.
 * @property {string | false} [label = "false"]  - Label on top of Radio.
 * @property {object} options - Select Options.
 * @property {number | string | null} options.value - Value.
 * @property {string} options.label - Label.
 * @property {string | number | null} [defaultValue = "null"] - Select default Value
 */

Select.propTypes = {
	size: PropTypes.oneOf(["small", "medium"]),
    helperText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.oneOf([null])]),
    control: PropTypes.shape({}),
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(null)]).isRequired
    })).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string,
    defaultValue: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(null)]),
    disabled: PropTypes.bool,
}

Select.defaultProps = {
    name: "",
    control: {},
    size: "medium",
    helperText: null,
    defaultValue: null,
    label: false,
    disabled: false,
}


export default Select;
