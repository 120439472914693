/* eslint-disable */
import React, { useState } from "react";
// import PropTypes from "prop-types";
import { Box, Button, Grid, Typography, Divider } from "@mui/material";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { useTranslation } from "react-i18next";
import MachineCommandsDialog from "./machine-commands-dialog";
import defImg from "assets/Device.png"
import _ from "lodash";

const STATS = [
	{path: "acrocloud.cpu"},
	{path: "acrocloud.gpu"},
	{path: "acrocloud.mem", formatter: (value) => `${value} MB`},
	{path: "acrocloud.storage", formatter: (value) => `${value} MB`},
	{path: "last_state.batteryLevel", formatter: (value) => `${value.toFixed(2)}%`},
	{path: "last_state.temperature", formatter: (value) => `${value.toFixed(2)}°C`},
	{path: "last_state.humid", formatter: (value) => `${value.toFixed(2)}%`},
	{path: "last_state.pressure", formatter: (value) => `${value.toFixed(2)}HPa`},
]

const getStatValue = (device, stat) => {
	let value = "N/A"
	if(_.has(device, stat.path)){
		value = _.get(device, stat.path) || "N/A"
		if(stat.formatter && value !== "N/A"){
			value = stat.formatter(value)
		}
		return value
	}
	return value;
}

const MAIN_SIZE = 20;
const MEDIUM_SIZE = 16;
const SMALL_SIZE = 15;

const MachineCard = (props) => {
	const { device, executeCommand } = props;
	const { t } = useTranslation("clusters", { keyPrefix: "view" });
	const [controlsOpen, setControlsOpen] = useState(false);
	const [img, setImg] = useState(device?.model?.still?.url || defImg)

	return (
		<>
			<MachineCommandsDialog
				open={controlsOpen}
				setOpen={setControlsOpen}
				device={device}
				t={t}
				executeCommand={executeCommand}
			/>
			<Grid
				container
				gap={1}
				sx={{
					borderRadius: "10px",
					backgroundColor: "background.default",
					width: "49.5%",
					overflow: "hidden",
					maxHeight: "270px",
					minHeight: "270px",
					flexWrap: "nowrap",
					mb: 1,
				}}
			>
				<Grid item>
					<img height="210" onError={() => setImg(defImg)} src={img}/>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							mb: "auto",
						}}
						>
							<Button
								size="small"
								color="primary"
								variant="contained"
								sx={{ width: "90%" }}
								onClick={() => setControlsOpen(true)}
							>
								<SettingsApplicationsIcon />
								{t("controls")}
							</Button>
						</Box>
				</Grid>
				<Grid gap={1} item md={10} sx={{ display: "flex", flexDirection: "column", ml: 2, mt: 2 }}>
					<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
						<Typography fontWeight={500} fontSize={MAIN_SIZE} sx={{ color: "text.primary" }}>
							{t("machine.name")}:
						</Typography>
						<Typography fontWeight={400} fontSize={MAIN_SIZE} sx={{ color: "text.secondary" }}>
							{`${device?.nickname} (${device?.acrocloud?.serial})` || "N/A"}
						</Typography>
					</Box>
					<Divider />
					<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
						<Typography fontWeight={500} fontSize={MEDIUM_SIZE} sx={{ color: "text.primary" }}>
							{t("machine.model")}:
						</Typography>
						<Typography fontWeight={400} fontSize={MEDIUM_SIZE} sx={{ color: "text.secondary" }}>
							{device?.model?.name || "N/A"}
						</Typography>
					</Box>
					<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
						<Typography fontWeight={500} fontSize={MEDIUM_SIZE} sx={{ color: "text.primary" }}>
							{t("machine.sku")}:
						</Typography>
						<Typography fontWeight={400} fontSize={MEDIUM_SIZE} sx={{ color: "text.secondary" }}>
							{device?.sku?.name || "N/A"}
						</Typography>
					</Box>
					<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
						<Typography fontWeight={500} fontSize={MEDIUM_SIZE} sx={{ color: "text.primary" }}>
							{t("machine.location")}:
						</Typography>
						<Typography fontWeight={400} fontSize={MEDIUM_SIZE} sx={{ color: "text.secondary" }}>
							{
								(device?.last_state?.location?.address && device?.last_state?.location?.lat && device?.last_state?.location?.long) ?
								`${device.last_state.location.address} [${device.last_state.location.lat.toFixed(6)}, ${device.last_state.location.long.toFixed(6)}]`
								:
								"N/A"
							}
						</Typography>
					</Box>
					<Divider />

					<Grid container sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }} gap={1}>
						{
							STATS.map(stat => (
								<Grid item md={3} gap={2} sx={{ display: "flex", flexBasis: "33.33%", flexDirection: "column" }}>
									<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
										<Typography fontWeight={500} fontSize={SMALL_SIZE} sx={{ color: "text.primary" }}>
											{t(`machine.${stat.path}`)}:
										</Typography>
										<Typography fontWeight={400} fontSize={SMALL_SIZE} sx={{ color: "text.secondary" }}>
										{getStatValue(device, stat)}
										</Typography>
									</Box>
								</Grid>
							))
						}
						{/* <Grid item md={3} gap={2} sx={{ display: "flex", flexDirection: "column" }}>
							<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
								<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
									{t("machine.cpu")}:
								</Typography>
								<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
									{device?.acrocloud?.cpu || "N/A"}
								</Typography>
							</Box>
							<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
								<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
									{t("machine.gpu")}:
								</Typography>
								<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
									{device?.acrocloud?.gpu || "N/A"}
								</Typography>
							</Box>
						</Grid>
						<Grid item md={3} gap={2} sx={{ display: "flex", flexDirection: "column" }}>
							<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
								<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
									{t("machine.ram")}:
								</Typography>
								<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
									{device?.acrocloud?.mem || "N/A"}
								</Typography>
							</Box>
							<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
								<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
									{t("machine.storage")}:
								</Typography>
								<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
									{device?.acrocloud?.storage || "N/A"}
								</Typography>
							</Box>
						</Grid>
						<Grid item md={3} gap={2} sx={{ display: "flex", flexDirection: "column" }}>
							<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
								<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
									Battery Level:
								</Typography>
								<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
									{device?.last_state?.batteryLevel || "N/A"}
								</Typography>
							</Box>
							<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
								<Typography fontWeight={500} fontSize={16} sx={{ color: "text.primary" }}>
									Env. Temperature:
								</Typography>
								<Typography fontWeight={400} fontSize={16} sx={{ color: "text.secondary" }}>
									{device?.last_state?.temperature || "N/A"}
								</Typography>
							</Box>
						</Grid> */}
					</Grid>
				</Grid>

			</Grid>
		</>
	);
};


// MachineCard.propTypes = {
//     device: PropTypes.shape({}).isRequired,
//     executeCommand: PropTypes.func().isRequired,
// }

export default MachineCard;
