import React from "react";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import Typography from "@mui/material/Typography";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import {Link} from "react-router-dom"
import MUILink from "@mui/material/Link";
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from "lodash";

const Breadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs();

    const formateLabel = (p) => {
		const path = decodeURI(p)
		let value = path.slice(path.lastIndexOf("/")+1).replace(/_/g, " ");
        if(value.includes("-")){
			value = value.split("-").slice(1).join()
        }
        return value === "" ? "Home" : _.capitalize(value);
    }

    const isSm = useMediaQuery((theme) => `${theme.breakpoints.down('sm')}`);

	return (
		!isSm && <MUIBreadcrumbs aria-label="breadcrumb">
            {breadcrumbs.map(({  key }, i) => (
                i === breadcrumbs.length - 1 ?
                    <Typography key={key} color={ i === breadcrumbs.length - 1 ?  "text.primary" : "text.secondary"}>{formateLabel(key)}</Typography>
                :
                <MUILink underline="hover" key={key} to={key} component={Link}>
                    <Typography color={ i === breadcrumbs.length - 1 ?  "text.primary" : "text.secondary"}>{formateLabel(key)}</Typography>
                </MUILink>
            ))}
		</MUIBreadcrumbs>
	);
};

export default Breadcrumbs;
