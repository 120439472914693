import React from "react";

/* MIU */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import PendingIcon from '@mui/icons-material/Pending';

export const deviceStatusMap = {
    safe: "success.main",
    true: "success.main",
    charged: "success.main",
    warning: "warning.main",
    discharging: "warning.main",
    charging: "warning.main",
    null: "text.secondary",
    discharged: "error.main",
    alarm: "error.main",
    false: "error.main"
}


export const tableStatusMap = {
	success: {
		icon: (
			<CheckCircleIcon
				fontSize="small"
				sx={{ color: "success.main", backgroundColor: "success.shade", borderRadius: "100%" }}
			/>
		),
		color: "success.main",
		backgroundColor: "success.shade",
	},
	warning: {
		icon: (
			<ErrorIcon
				fontSize="small"
				sx={{ color: "warning.secondary", backgroundColor: "warning.shade", borderRadius: "100%" }}
			/>
		),
		color: "warning.secondary",
		backgroundColor: "warning.shade",
	},
	error: {
		icon: (
			<CancelIcon
				fontSize="small"
				sx={{ color: "error.main", backgroundColor: "error.shade", borderRadius: "100%" }}
			/>
		),
		color: "error.main",
		backgroundColor: "error.shade",
	},
	review: {
		icon: (
			<RemoveRedEyeIcon
				fontSize="small"
				sx={{ color: "neutral.100", backgroundColor: "text.secondary", borderRadius: "100%" }}
			/>
		),
		color: "neutral.100",
		backgroundColor: "text.secondary",
	},
	pending: {
		icon: (
			<PendingIcon
				fontSize="small"
				sx={{ color: "text.secondary", backgroundColor: "warning.main", borderRadius: "100%" }}
			/>
		),
		color: "text.secondary",
		backgroundColor: "warning.main",
	},
};


export const siteStatusMap = {
    maintenance: {
        icon: <InfoRoundedIcon fontSize="small" sx={{color: "warning.main", backgroundColor: "primary.shade", borderRadius: "100%"}}/>,
        text: "Site under maintenance"
    },
	up: {
        icon: <InfoRoundedIcon fontSize="small" sx={{color: "success.main", backgroundColor: "primary.shade", borderRadius: "100%"}}/>,
        text: "Site is up"
    },
	down: {
        icon: <InfoRoundedIcon fontSize="small" sx={{color: "error.main", backgroundColor: "primary.shade", borderRadius: "100%"}}/>,
        text: "Site is down"
    }
}

export const tableIconStatusMap = {
    info: {
        icon: <WarningIcon fontSize="small" sx={{color: "warning.main"}}/>,
    },
	warning: {
        icon: <InfoIcon fontSize="small" sx={{color: "success.main" }}/>,
    },
	error: {
        icon: <WarningIcon fontSize="small" sx={{color: "error.main"}}/>,
    }
}

export default {
    deviceStatusMap,
    tableStatusMap,
    siteStatusMap,
	tableIconStatusMap
}