import React from "react";

/* Content Components */
import Toolbar from "components/content/acro-dashboard/toolbar";
import ResourcesMap from "components/content/acro-dashboard/resources-map";
import ResourcesStats from "components/content/acro-dashboard/resources-stats";
import ResourcesRequests from "components/content/acro-dashboard/resources-requests";
import Messages from "components/content/acro-dashboard/messages";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";
import Section from "components/layouts/dashboard/ui/section";

/* Utils */
import { useTranslation } from "react-i18next";
import useItemsTranslation from "@acromove/hooks/use-items-translation";

/* Mock */
import mock from "mock/acro-admin-dashboard";

const AcroAdminDashboardContainer = () => {
	const { t } = useTranslation("acro_admin_dashboard");
	const itemsTranslator = useItemsTranslation("acro_admin_dashboard");

	return (
		<Container>
			<Container.Header title={t("title")}>
				<Toolbar />
			</Container.Header>

			<Section title="Site Browser" collapsible>
				<ResourcesMap groups={itemsTranslator(mock.mapGroups, "site_browser.group", "slug", "label")} displayTooltip/>
			</Section>

			<Section title={t("physical_resources_stats.title")} collapsible>
				<ResourcesStats />
			</Section>

			<Section title={t("physical_resources_requests.title")} collapsible>
				<ResourcesRequests />
			</Section>

			<Section title={t("device_system_alarms.title")} collapsible>
				<Messages />
			</Section>
		</Container>
	);
};

export default AcroAdminDashboardContainer;
