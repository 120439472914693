import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "../../utils/helpers" 


const authAPI = createApi({
    reducerPath: "auth/api",
    baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("")),
    endpoints: build => ({
        loginUser: build.mutation({
            query: ({ username, password }) => ({
                url: "auth/login",
                method: "POST",
                body: {
                    username,
                    password
                }
            })
        }),
        me: build.query({
            query: () => ({
                url: "user/me"
            }),
            providesTags: ["UserMe"]
        }),
        updateMe: build.mutation({
            query: (data) => {
                const formData = new FormData();
                for (const key in data) {
                    if (key === "device_notifications") {
                        formData.append(key, JSON.stringify(data[key]))
                        continue;
                    }
                    formData.append(key, data[key])
                }
                return {
                    url: "user/me",
                    method: "POST",
                    body: formData,
                }
            },
            transformResponse: (response) => response.payload
        }),
        updateMyPassword: build.mutation({
            query: (data) => ({
                url: "user/me/password",
                method: "POST",
                body: data,

            })
            ,
            transformResponse: (response) => response.payload
        }),
        logout: build.query({
            query: () => ({
                url: "user/logout",
            })
        }),
        selectScope: build.query({
            query: ({key}) => ({
                url: `user/select-group/${key}`,
            })
        }),

        register: build.mutation({
            query: (data) => ({
                url: "auth/register",
                method: "POST",
                body: data,
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["UserMe"],
        }),

        forgot: build.query({
            query: ({username}) => ({
                url: `auth/forget-password/${username}`,
            })
        }),

        reset: build.mutation({
            query: ({password, resetToken}) => ({
                url: "auth/reset-password",
                params: {
                    resetToken,
                },
                method: "POST",
                body: {password}
            }),
            transformResponse: (response) => response.payload
        })
    })
})



export const {
    useLoginUserMutation,
    useMeQuery,
    useLazyMeQuery,
    useUpdateMeMutation,
    useLazyLogoutQuery,
    useUpdateMyPasswordMutation,
    useLazySelectScopeQuery,
    useRegisterMutation,
    useForgotQuery,
    useLazyForgotQuery,
    useResetMutation
} = authAPI

export default authAPI;