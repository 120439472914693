import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";

const FrontImage = ({ asset, rest }) => (
	<Box
		sx={{
			width: "100%",
            height: "100%",
			position: "relative",
			"& div": { width: "100%", height: "100%" },
		}}
	>
		<Box
			sx={{
				position: "absolute",
				top: 0,
				left: 0,
				transform: "scale(1.1)",
				backgroundImage: `url(${URL.createObjectURL(asset)})`,
				filter: "blur(20px)",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		/>
		<img
			key={asset.filename}
			src={URL.createObjectURL(asset)}
			alt={asset.filename}
			style={{
				width: "100%",
				height: "100%",
				zIndex: 10,
				objectFit: "contain",
				objectPosition: "center",
				position: "absolute",
				top: 0,
				left: 0,
			}}
			{...rest}
		/>
	</Box>
);

FrontImage.propTypes = {
	asset: PropTypes.shape({
        filename: PropTypes.string,
    }).isRequired,
    rest: PropTypes.shape({})
};

FrontImage.defaultProps = {
    rest: {}
}

export default FrontImage;
