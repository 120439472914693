import React from "react";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Content Components */
import Details from "components/content/user/profile/details";
import PasswordChange from "components/content/user/profile/password-change";

/* Utils */
import {useTranslation} from "react-i18next";

const UserProfileContainer = () => {
    const {t} = useTranslation("user_profile");
	return (
		<Container>
			<Container.Header title={t("btns.back")} back={-1} />
			<Details />
			<PasswordChange />
		</Container>
	);
};

export default UserProfileContainer;
