import React from "react";

import DataGridIconLink from "@acromove/components/data-grid/cells/datagrid-icon-link";
import DataGridMenu from "@acromove/components/data-grid/cells/datagrid-menu_button";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* Assets */
import { Switch } from "@mui/material";

export const groupMain = [
	{
		headerName: "id",
		field: "id",
		minWidth: 100,
		maxWidth: 100,
		sortable: false,
	},
	{
		headerName: "Group Name",
		field: "name",
		minWidth: 150,
	},
	// {
	// 	Header: "Group Tenant",
	// 	accessor: "group_tenant",
	// },
	// {
	// 	Header: "Group Admin",
	// 	accessor: "group_admin",
	// },
	{
		headerName: "Type",
		field: "type",
		valueGetter: (params) => params.row.settings.type.name,
		minWidth: 100,
	},
	// {
	// 	Header: "Spent",
	// 	accessor: "spent",
	// },
	// {
	// 	Header: "Quota",
	// 	accessor: "quota",
	// },
	{
		headerName: "url",
		field: "url",
		sortable: false,
		flex: 0.5,
		align: "right",
		renderCell: DataGridIconLink,
	},
];

export const groupChildUsers = (props) => {
	const {onUserUnassign} = props
	const navigate = useNavigate();
	const { t } = useTranslation("clients_groups_info")
	return [
		{
			headerName: "username",
			field: "username",
			minWidth: 300,
			sort: "desc",
		},
		{
			headerName: "firstName",
			field: "firstName",
			minWidth: 150,
			sortable: false,
		},
		{
			headerName: "lastName",
			field: "lastName",
			minWidth: 150,
			sortable: false,
		},
		{
			headerName: "role",
			field: "role",
			minWidth: 150,
			sortable: false,
		},
		{
			headerName: "disabled",
			field: "disabled",
			minWidth: 150,
			sortable: false,
			renderCell: (params) => {
				const { row } = params;
				return <Switch size="small" checked={row.disabled} />;
			},
		},
		{
			headerName: "url",
			field: "url",
			flex: 0.5,
			align: "right",
			sortable: false,
			renderCell: (cellProps) => {
				const { row } = cellProps;
				const menu = [
					{ protected: false, onClick: () => navigate(row.url), text: t("view_edit") },
					{ protected: "users:assign-to-group", onClick: () => navigate(`?username=${row.id}`), text: t("assign_to"), color: "success.main" },
					{ protected: "users:unassign-from-group", onClick: () => onUserUnassign(row.id), text: t("unassign_group"), color: "error.main" },
				];

				return <DataGridMenu menu={menu} />;
			},
		},
	];
};

export const groupUnassignedChildUsers = (props) => {
	const {onUserAssign} = props
	const navigate = useNavigate();
	const { t } = useTranslation("clients_groups_info")
	return [
		{
			headerName: "username",
			field: "username",
			minWidth: 300,
			sort: "desc",
		},
		{
			headerName: "firstName",
			field: "firstName",
			minWidth: 150,
			sortable: false,
		},
		{
			headerName: "lastName",
			field: "lastName",
			minWidth: 150,
			sortable: false,
		},
		{
			headerName: "role",
			field: "role",
			minWidth: 150,
			sortable: false,
		},
		{
			headerName: "disabled",
			field: "disabled",
			minWidth: 150,
			sortable: false,
			renderCell: (params) => {
				const { row } = params;
				return <Switch size="small" checked={row.disabled} disabled/>;
			},
		},
		{
			headerName: "url",
			field: "url",
			flex: 0.5,
			align: "right",
			sortable: false,
			renderCell: (cellProps) => {
				const { row } = cellProps;
				const menu = [
					{ protected: false, onClick: () => navigate(row.url), text: t("view_edit") },
					{ protected: "users:assign-to-group", onClick: () => onUserAssign(row.id), text: t("assign_group"), color: "success.main" },
				];

				return <DataGridMenu menu={menu} />;
			},
		},
	];
};

export const groupChildGroups = [
	{
		headerName: "id",
		field: "id",
		maxWidth: 200,
		sortable: false,
	},
	{
		headerName: "name",
		field: "name",
		minWidth: 250,
	},
	// {
	// 	Header: "Group Tenant",
	// 	accessor: "group_tenant",
	// },
	// {
	// 	Header: "Group Admin",
	// 	accessor: "group_admin",
	// },
	{
		headerName: "type",
		field: "type",
		valueGetter: (params) => params.row.settings.type.name,
		minWidth: 150,
		sortable: false,
	},
	// {
	// 	Header: "Spent",
	// 	accessor: "spent",
	// },
	// {
	// 	Header: "Quota",
	// 	accessor: "quota",
	// },
	{
		headerName: "url",
		field: "url",
		flex: 0.5,
		align: "right",
		sortable: false,
		renderCell: DataGridIconLink
	},
];

export const userLogs = [
	{
		Header: "username",
		accessor: "username",
	},
	{
		Header: "fullname",
		accessor: "fullname",
	},
	{
		Header: "role",
		accessor: "role",
	},
	{
		Header: "entity",
		accessor: "entity",
	},
	{
		Header: "action",
		accessor: "action",
	},
	{
		Header: "timestamp",
		accessor: "timestamp",
	},
];

export default {
	groupMain,
	groupChildUsers,
	groupChildGroups,
	groupUnassignedChildUsers,
};
