const getInitials = (firstname = "", lastname = "") => {
    const firstName = firstname.slice(0, 1).toUpperCase();
    const lastName = lastname.slice(0, 1).toUpperCase();
    return `${firstName}${lastName}`
}

const sleep = (ms) => new Promise((resolve) => {
    setTimeout(() => resolve(true), ms)
})

const getApiBaseUrl = (uri) => {
    let baseApiUrl = `${process.env.REACT_APP_API_BASE_URL}${uri}`;
    const withOutProtocol = baseApiUrl.replace("http://", "").replace("https://", "")
    const asChar = withOutProtocol.split("").slice(withOutProtocol.indexOf("/"), withOutProtocol.split("").length)
    const params = [];
    for (let index = 0; index < asChar.length; index++) {
        const char = asChar[index];
        if(char === ":"){
            const found = asChar.slice(index+1, asChar.indexOf("/", index + 1)).join("")
            params.push(found)
            index += found.length
        }
        
    }
    for (const param of params) {
        const value = localStorage.getItem(param) || process.env[`REACT_APP_${param.toUpperCase()}`];
        baseApiUrl = baseApiUrl.replace(`:${param}`, value)
    }
    return baseApiUrl
}

const getBaseQueryWithAuthorization = (uri) => ({
    baseUrl: getApiBaseUrl(uri),
    prepareHeaders: (headers) => {
        const token = localStorage.getItem("accessToken")
        if (token) {
            headers.set("Authorization", `Bearer ${token}`)
        }
        return headers
    },
    credentials: "include"
})

const findAverageLocation = (points) => points.reduce((acc, d, i, arr) => {
    const current = acc;
    const [lat, lon] = d || [0, 0];
    if (!lat || !lon) {
        if (i === arr.length - 1 && current[2]) {
            return current.map((sum) => sum / (current[2] - 1));
        } 
        return current;
    }
    current[0] += lat; 
    current[1] += lon;	
    current[2] = (current[2] || 1) + 1;
    if (i === arr.length - 1) {
        return current.map((sum) => sum / (current[2] - 1));
    }
    return current;
}, [0, 0])

export default {
    getInitials,
    getBaseQueryWithAuthorization,
    sleep,
    findAverageLocation
}