import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

import treeViewContext from "./context";
import TreeItem, { treeItemClasses,  } from "@mui/lab/TreeItem";
import { alpha, styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * Tree View Item Component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Tree View Item.
 */

const StyledTreeItem = styled((props) => <TreeItem {...props} />)(({ theme }) => ({
	[`& .${treeItemClasses.iconContainer}`]: {
		"& .MuiSvgIcon-root": {
			fontSize: "24px",
			opacity: 0.3
		}
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 15,
		paddingLeft: 18,
		borderLeft: `1px dotted ${alpha(theme.palette.text.primary, 0.4)}`,
	},
	[`& .${treeItemClasses.content}`]: {
		padding: 2,
		fontWeight: 500,
		mx: 1,
		borderRadius: "8px",
		[`& .${treeItemClasses.label}`]: {
				padding: 1,
				fontWeight: 500,
			},
	},
	[`& .${treeItemClasses.root}`]: {
		padding: 1,
	},
	[`& .${treeItemClasses.selected}`]: {
		borderRadius: "8px",
		[`& .${treeItemClasses.label}`]: {
			padding: 1,
			fontWeight: 700,
			borderRadius: "8px",
		},
	},
	[`& .${treeItemClasses.expanded}`]: {
		[`& .${treeItemClasses.iconContainer}`]: {
			"& .MuiSvgIcon-root": {
				fontSize: "24px",
				opacity: 1
			}
		},
	},
}));

const Item = (props) => {
	const { name, id } = props;
	const { limit, onSelect, useLazyLoadChild, idKey, formateData, expanded } = useContext(treeViewContext);
	const [children, setChildren] = useState([]);
	const [offset, setOffset] = useState(0);
	const [fetchChild, { data, isLoading, isSuccess }] = useLazyLoadChild();


    useEffect(() => {
        if(expanded.includes(id) && !(data?.items || []).length){
            fetchChild({[idKey]: id, offset: 0, limit})
        }
    }, [expanded])


	const handleLoadMore = () => {
		fetchChild({ [idKey]: id, offset: offset + limit, limit });
		setOffset(offset + limit);
	};

	useEffect(() => {
		if (isSuccess && data.items) {
			setChildren([...children, ...data.items.map(formateData)]);
		}
	}, [data, isSuccess]);

	return (
		<StyledTreeItem onClick={() => onSelect(id)} nodeId={id} label={name}>
			{isLoading && <CircularProgress size={10} />}
			{children.map((child) => (
				<Item key={child.id} name={child.name} id={child.id} />
			))}
			{children.length !== (data?.total || 0) && (
				<Button onClick={handleLoadMore}>Load more ({(data?.total || 0) - children.length})</Button>
			)}
		</StyledTreeItem>
	);
};

/**
 * Tree View Item Properties.
 *
 * @typedef {object} Props
 * @property {string} name - Tree item element name.
 * @property {string | number} id - Tree item element id.
 */

Item.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Item;
