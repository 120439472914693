import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/* Utils */
import sh from "../../utils/styles-helpers";

/**
 * Empty component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Empty Component.
 */
const EmptyComponent = (props) => {
	const { emptyText, emptyIcon } = props;

	return (
		<Grid
			container
			sx={{
				width: "100%",
                height: "20vh",
				position: "relative",
				...sh.flex("column", "center", "center"),
			}}
		>
			<Grid container sx={{ gap: 2, width: "100%", ...sh.flex("column", "center", "center") }}>
				<Grid item xs={12} sm={12} md={12} lg={12} sx={sh.flex("column", "center", "center")}>
					{emptyIcon}
					<Typography color="text.secondary" fontSize={20} fontWeight={400}>
						{emptyText}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

/**
 * Empty Component Properties.
 *
 * @typedef {object} Props
 * @property {string} emptyText- Text Displayed when Table is Empty,
 * @property {React.ReactNode} emptyIcon - Icon for Empty Table.
 */


EmptyComponent.propTypes = {
	emptyText: PropTypes.string.isRequired,
	emptyIcon: PropTypes.element.isRequired
};


export default EmptyComponent;
