import React from "react";
import PropTypes from "prop-types";

/* MUI */
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useTheme } from "@mui/material/styles";

/* Components */
import NotificationItem from "./notification-item";
import NoContent from "components/common/no-content";

/* Utils */
import sh from "@acromove/utils/styles-helpers";

import {useMarkMultiplyNotificationsAsReadMutation} from "redux/notifications/notification.api"

import { useTranslation } from "react-i18next";

const NotificationList = ({ items }) => {
	const theme = useTheme();
	const [markAsReadMultiply] = useMarkMultiplyNotificationsAsReadMutation();
	const { t } = useTranslation("notifications");
	return (
		<List sx={{ width: "100%", minWidth: 400, bgcolor: "background.paper", borderRadius: 1 }}>
			<Box component="li" sx={[sh.flex("row", "space-between", "center"), { px: 4 }]}>
				<Typography color="text.secondary" fontWeight={theme.typography.fontWeightBold}>
					{t("main.label")}
				</Typography>
				<Button
					disabled={!items.length}
					size="small"
					onClick={() => markAsReadMultiply({ids: [...items.map((item) => item.id)]})}
					startIcon={<DoneAllIcon />}
				>
					{t("main.mark_as_read_all")}
				</Button>
			</Box>
			{items.map((item, i) => (
				<React.Fragment key={item.id}>
					<NotificationItem
						itemId={item.id}
						severity={item.content.severity}
						template={item.template}
						actions={item.content.actions}
						timestamp={item.content.timestamp}
						replacements={item.content.replacements}
					/>
					{i !== items.length - 1 && (
						<Divider sx={{ ml: 0 }} key={`d_${item.uid}`} variant="inset" component="li" />
					)}
				</React.Fragment>
			))}
			<NoContent label={t("main.no_notifications")} hidden={items.length} />
		</List>
	);
};

NotificationList.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			priority: PropTypes.string.isRequired,
			content: PropTypes.shape({
				severity: PropTypes.string.isRequired,
				message: PropTypes.string.isRequired,
				actions: PropTypes.func.isRequired,
				timestamp: PropTypes.string.isRequired,
			}).isRequired,
			template: PropTypes.string.isRequired,
		})
	).isRequired,
};

export default NotificationList;
