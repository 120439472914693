import React from "react";
import constants from "@acromove/constants";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import ClustersViewContainer from "containers/clusters/view";

const  ClustersViewPage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={constants.ALL_ROLES}>
            <ClustersViewContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default  ClustersViewPage;