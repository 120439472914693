import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/* Common Components */
import Switcher from "../switcher";

/* Utils */
import sh from "../../utils/styles-helpers";
import { siteStatusMap, tableStatusMap, deviceStatusMap } from "./status-map";
import { useTranslation } from "react-i18next";

/**
 * Status Statistic component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} different Status Statistic component deppending on type.
 */
const StatusStat = (props) => {
	const { value, label, sublabel, status, type, statusText } = props;
	const { t } = useTranslation("common");

	return (
		<Switcher value={type}>
			<Switcher.Item name="device">
				<Box
					sx={{
						width: "100%",
						p: 1.5,
						...sh.flex("column", "flex-start", "flex-start"),
						gap: 1.5,
						border: "1px solid",
						borderColor: "background.gray",
						borderRadius: "8px",
					}}
				>
					<Typography color="text.secondary" fontWeight="600" fontSize={16} sx={{ opacity: 1 }}>
						{label}
					</Typography>
					<Typography color={type === "device" && deviceStatusMap[value]} fontWeight="700" fontSize={24}>
						{status}
					</Typography>
					<Typography color="text.grayLight" fontWeight="300" fontSize={12}>
						{sublabel}
					</Typography>
				</Box>
			</Switcher.Item>

			<Switcher.Item name="site">
				<Box sx={{ ...sh.flex("column", "flex-start", "flex-start"), gap: 2, p: 1 }}>
					<Typography color="text.secondary" sx={{ lineHeight: "24px", pt: 1, fontWeight: 500 }}>
						{t("site_state")}
					</Typography>
					<Box sx={sh.flex("row", "flex-start", "center")}>
						{type === "site" && siteStatusMap[status].icon}
						<Typography color="text.secondary" sx={{ ml: 1, opacity: 0.7 }}>
							{type === "site" && siteStatusMap[status].text}
						</Typography>
					</Box>
				</Box>
			</Switcher.Item>

			<Switcher.Item name="table">
				<Box
					sx={{
						...sh.flex("row", "flex-start", "center"),
						p: 1,
						borderRadius: "8px",
						backgroundColor: type === "table" && tableStatusMap[status].backgroundColor,
					}}
				>
					{type === "table" && tableStatusMap[status].icon}
					<Typography color={type === "table" && tableStatusMap[status].color} sx={{ ml: 1 }}>
						{statusText}
					</Typography>
				</Box>
			</Switcher.Item>
		</Switcher>
	);
};

/**
 * StatusStat Properties.
 *
 * @typedef {object} Props
 * @property {"device" | "table" | "site"} type - Type of Component. 
 * "device" - used for displaying Device Status, label and sublabel are optional, value is required.
 * "table" - used for displaying status of table/section, statusText is optional.
 * "site" - used to define site status.
 * @property {string} status - Property to define status. To see list of statuses and status maps check
 * {@link deviceStatusMap} - for "device" type statuses.
 * {@link tableStatusMap} - for "table" type statuses.
 * {@link siteStatusMap} - for "site" type statuses
 * @property {string | number | true | false} value - Property required when using "device" type of component. To find out values check {@link deviceStatusMap}
 * @property {string} [label = ""] - Property used only with "device" type of components.
 * @property {string} [sublabel = ""] - Property used only with "device" type of components.
 * @property {string} [statusText = ""] - Property used only with "table" type of components.
 */

StatusStat.propTypes = {
	type: PropTypes.oneOf(["device", "table", "site"]).isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	label: PropTypes.string,
	status: PropTypes.string.isRequired,
	sublabel: PropTypes.string,
	statusText: PropTypes.string,
};

StatusStat.defaultProps = {
	value: "",
	label: "",
	sublabel: "",
	statusText: "",
};

export default StatusStat;
