import React, { useState } from "react";
import PropTypes from "prop-types";

/* Components */
import TabPanel from "@acromove/components/tab/tab-panel";
import GeneralInfo from "./forms/general-info";
import EnvCharacter from "./forms/env-characteristics";
import Cages from "./forms/cages";
import Networking from "./forms/networking";
import PowerSources from "./forms/power-sources";
import Dialog from "@acromove/components/dialog";
import { Badge, Box, Tab, Tabs } from "@mui/material";

/* Utils */
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import useFormContext from "@acromove/components/form/hooks/use-form-context";


const SpacesInfo = (props) => {
	const { spaceIndx, remove, update } = props
	const { t } = useTranslation("sites_create");
	const [tabValue, setTabValue] = useState("general");

	const {form} = useFormContext()

	const open = form.watch(`spaces[${spaceIndx}].dialogOpen`)
	const isNew = form.watch(`spaces[${spaceIndx}].newEntry`)

	const handleClose = async (isAgree) => {
		if(!isAgree && isNew){
			remove()
			return
		}
		if(!isAgree){
			form.resetField(`spaces[${spaceIndx}]`)
			return
		}
		const res = await form.trigger(`spaces[${spaceIndx}]`)
		if(res){
			update(spaceIndx, {
				...form.getValues(`spaces[${spaceIndx}]`),
				dialogOpen: false,
				newEntry: false
			})
			form.setValue(`spaces[${spaceIndx}].dialogOpen`, false)
			form.setValue(`spaces[${spaceIndx}].newEntry`, false)
		} else {
			toast.error("error")
		}
	}


	return (
		<Dialog key={`${spaceIndx}-dialog`} fullWidth maxWidth="lg" title={t("section_titles.space.label")} open={open} onClose={handleClose}>
				<Box>
					<Tabs
						value={tabValue}
						variant="fullWidth"
						onChange={(_, newValue) => setTabValue(newValue)}

						scrollButtons={false}
						aria-label="tab-panel-spaces"
					>
						<Tab label={
							<Badge sx={{ml: 2}} invisible={!form.getValues(`spaces[${spaceIndx}].has_notices.notices.general`)} variant="dot" color="error">
								{t("dialog_titles.general_info.label")}
							</Badge>
						} value="general" />
						<Tab label={
							<Badge sx={{ml: 2}} invisible={!form.getValues(`spaces[${spaceIndx}].has_notices.notices.environmental`)} variant="dot" color="error">
								{t("dialog_titles.environmental.label")}
							</Badge>
						} value="environmental" />
						<Tab label={
							<Badge sx={{ml: 2}} invisible={!form.getValues(`spaces[${spaceIndx}].has_notices.notices.cages`)} variant="dot" color="error">
								{t("dialog_titles.cages.label")}
							</Badge>
						} value="cages" />
						<Tab label={
							<Badge sx={{ml: 2}} invisible={!form.getValues(`spaces[${spaceIndx}].has_notices.notices.power_sources`)} variant="dot" color="error">
								{t("dialog_titles.power_sources.label")}
							</Badge>
						} value="power_sources" />
						<Tab label={
							<Badge sx={{ml: 2}} invisible={!form.getValues(`spaces[${spaceIndx}].has_notices.notices.networks`)} variant="dot" color="error">
								{t("dialog_titles.networks.label")}
							</Badge>
						} value="networks" />
					</Tabs>
					<Box>
						<TabPanel value={tabValue} index="general">
							<GeneralInfo spaceIndx={spaceIndx} open={open} />
						</TabPanel>
						<TabPanel value={tabValue}  index="environmental">
							<EnvCharacter spaceIndx={spaceIndx} />
						</TabPanel>
						<TabPanel value={tabValue} index="cages">
							<Cages spaceIndx={spaceIndx} />
						</TabPanel>
						<TabPanel value={tabValue}  index="power_sources">
							<PowerSources spaceIndx={spaceIndx}/>
						</TabPanel>
						<TabPanel value={tabValue} index="networks">
							<Networking spaceIndx={spaceIndx} />
						</TabPanel>
					</Box>
				</Box>

		</Dialog>
	);
};

SpacesInfo.propTypes = {
	spaceIndx: PropTypes.number.isRequired,
	remove: PropTypes.func.isRequired,
	update: PropTypes.func.isRequired
}

export default SpacesInfo;
