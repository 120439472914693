import React, { useState } from "react";
import PropTypes from "prop-types";

/* MUI */
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";

/* Components */
import DrawerContent from "./ui/drawer-content";
import AppTopBar from "./ui/app-top-bar";

/* Utils */
import { Helmet } from "react-helmet";
import useOriginPath from "@acromove/hooks/use-origin-path";
import routes from "router/router";
import { useTranslation } from "react-i18next";

const drawerWidth = 280;

const DashboardLayout = (props) => {
	const { window, children } = props;
	const [mobileOpen, setMobileOpen] = useState(false);

	const { t } = useTranslation("router");

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const {originPath, params} = useOriginPath();

	const container = window !== undefined ? () => window().document.body : undefined;


	return (
		<Box sx={{ display: "flex", maxWidth: "100%" }}>
			<Helmet>
				<title>{t(originPath.replace(/:/g, "__"), params)}</title>
			</Helmet>
			<AppTopBar drawerWidth={drawerWidth} onDrawerToggle={handleDrawerToggle}/>
			<Box
				component="nav"
				sx={{
					width: { lg: drawerWidth },
					flexShrink: { md: 0 },
				}}
				aria-label="mailbox folders"
			>
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					PaperProps={{
						sx: {
							bgcolor: "sideMenu.background",
						},
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block", lg: "none" },
						"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
					}}
				>
					<DrawerContent items={routes.navElements} />
				</Drawer>
				<Drawer
					variant="permanent"
					PaperProps={{
						sx: {
							bgcolor: "sideMenu.background",
						},
					}}
					sx={{
						display: { xs: "none", lg: "block" },
						"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
					}}
					open
				>
					<DrawerContent items={routes.navElements} />
				</Drawer>
			</Box>
			<Box component="main" sx={{ flexGrow: 1, p: 3, width: { lg: `calc(100% - ${drawerWidth}px)` } }}>
				<Toolbar sx={{ minHeight: { md: "52px" } }} />
				{children}
			</Box>
		</Box>
	);
};

DashboardLayout.propTypes = {
	children: PropTypes.node.isRequired,
	window: PropTypes.func,
};

DashboardLayout.defaultProps = {
	window: undefined,
};

export default DashboardLayout;
