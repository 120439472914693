import React from "react";
import { Avatar, Box, Card, CardHeader, Typography, IconButton } from "@mui/material";
import PhysicalRes from "components/icons/physical-res";
import VirtualRes from "components/icons/virtual-res";
import ArrowRightIcon from "components/icons/arrow-right";
import moment from "moment";

const now = new Date();

const reviews = [
	{
		id: "5f0366cd843161f193ebadd4",
		title: "How to manage your working time in this pandemic",
		author: {
			avatar: "https://i.pravatar.cc/300",
			name: "Marcus Finn",
			email: "tonysoap@mail.com",
		},
		type: "virtual",
		comment:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ...",
		createdAt: moment(now).format("DD.MM.YYYY"),
		value: 5,
	},
	{
		id: "to33twsyjphcfj55y3t07261",
		title: "How to manage your working time in this pandemic",
		author: {
			avatar: "https://i.pravatar.cc/300",
			name: "Miron Vitold",
			email: "tonysoap@mail.com",
		},
		type: "physical",
		comment:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ...",
		createdAt: moment(now).format("DD.MM.YYYY"),
		value: 2,
	},
	{
		id: "6z9dwxjzkqbmxuluxx2681jd",
		title: "How to manage your working time in this pandemic",
		author: {
			avatar: "https://i.pravatar.cc/300",
			name: "Carson Darrin",
			email: "tonysoap@mail.com",
		},
		type: "virtual",
		comment:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ...",
		createdAt: moment(now).format("DD.MM.YYYY"),
		value: 4,
	},
];

const Messages = () => (
	<Box
		sx={{
			minHeight: "100%",
			// p: 3,
		}}
	>
		{reviews.map((review) => (
			<Card
				key={review.id}
				sx={{
					"& + &": {
						mt: 2,
					},
				}}
			>
				<CardHeader
					sx={{
						".MuiCardHeader-avatar": {
							alignSelf: "flex-start",
						},
					}}
					avatar={
						<Avatar
							src={review.author.avatar}
							sx={{
								width: "56px",
								height: "56px",
								borderRadius: "14px",
								alignSelf: "flex-start",
							}}
						/>
					}
					subheader={
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								flexWrap: "nowrap",
							}}
						>
							<Box sx={{ flexBasis: "70%" }}>
								<Typography color="text.primary" variant="h5">
									{review.title}
								</Typography>
								<Typography sx={{ mt: "20px" }} color="text.primary" variant="body1">
									{review.comment}
								</Typography>
							</Box>
							<Box sx={{ flexBasis: "30%", justifyContent: "flex-end", display: "flex", alignItems: "center" }}>
								{review.type === "virtual" ? (
									<VirtualRes sx={{ widht: "20px", height: "20px", mr: "16px" }} />
								) : (
									<PhysicalRes sx={{ widht: "20px", height: "20px", mr: "16px" }} />
								)}
								<IconButton
									sx={{
										display: "flex",
										justifyContent: "flex-end",
										alignItems: "center",
									}}
								>
									<ArrowRightIcon color="primary" />
								</IconButton>
							</Box>
						</Box>
					}
					title={
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "flex-start",
								justifyContent: "space-between",
							}}
						>
							<Typography color="text.secondary" variant="body2">
								{review.author.email}
							</Typography>
							<Typography color="text.secondary" variant="body2">
								{review.createdAt}
							</Typography>
						</Box>
					}
				/>
			</Card>
		))}
	</Box>
);

export default Messages;
