import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";
import { Button, Box } from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";

/* Common Components */
import Input from "@acromove/components/form/input";
import Form from "@acromove/components/form";
import Checkbox from "@acromove/components/form/checkbox";
import ProtectedComponent from "@acromove/authentication/components/protected/component";

/* Utils */
import { useTranslation } from "react-i18next";

const GroupTypeDetails = (props) => {
	const { mode, submit, isLoading, editMode, form, permissions, submitRemove } = props;
	const { t } = useTranslation("acro_admin_group-types");

	return (
		<>
			<Form
				name="group_types"
				i18n="acro_admin_group-types"
				mode={mode}
				form={form}
				isLoading={isLoading}
				disabled={mode === "edit" ? !editMode : isLoading}
				sx={{ display: "flex", flexDirection: "row", mt: 6 }}
			>
				<Grid container gap={4} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
					<Form.Field name="name">
						<Grid item md={12}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Grid>
					</Form.Field>
					<Grid item md={12}>
						{permissions.map((item) => (
							<Checkbox
								disabled={mode === "edit" ? !editMode : false}
								color="success"
								control={form.control}
								name={item.name}
								label={t(`table.${item.alias}`)}
								key={item.name}
								helperText={t(`helperText.${item.alias}`)}
							/>
						))}
					</Grid>
				</Grid>
			</Form>
			<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginLeft: "auto", mt: 1 }}>
				{editMode && (
					<ProtectedComponent slug="group_types:remove">
						<Button
							startIcon={<DeleteIcon />}
							sx={{ mr: 1, display: "flex", alignSelf: "flex-end" }}
							color="error"
							variant="contained"
							onClick={submitRemove}
						>
							{t("delete")}
						</Button>
					</ProtectedComponent>
				)}
				{(editMode || (!editMode && mode === "add")) && (
					<Button
						startIcon={<SaveAsIcon />}
						type="submit"
						variant="contained"
						onClick={submit}
						sx={{ display: "flex", alignSelf: "flex-end" }}
					>
						{mode === "edit" ? t("save") : t("save_create")}
					</Button>
				)}
			</Box>
		</>
	);
};

GroupTypeDetails.propTypes = {
	permissions: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			alias: PropTypes.string,
		})
	).isRequired,
	mode: PropTypes.string.isRequired,
	submit: PropTypes.func,
	submitRemove: PropTypes.func,
	isLoading: PropTypes.bool.isRequired,
	editMode: PropTypes.bool,
	form: PropTypes.shape({
		control: PropTypes.shape({})
	}).isRequired,
};

GroupTypeDetails.defaultProps = {
	editMode: false,
	submit: () => null,
	submitRemove: () => null,
};

export default GroupTypeDetails;
