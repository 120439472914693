import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "@acromove/utils/helpers";

const locationsAPI = createApi({
	reducerPath: "locations/api",
	baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("services/locations/countries")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindCitiesInCountry"],
	endpoints: (build) => ({
		findCountries: build.query({
			query: ({ limit, offset, sortItem, sortDir, search }) => ({
				url: "/find",
				params: {
					limit,
					offset,
					sortItem,
					sortDir,
					search,
				},
			}),
			transformResponse: (response) => ({
				total: response.payload.total,
				items: response.payload.items,
			}),
			invalidatesTags: ["FindCitiesInCountry"],
		}),
		findCitiesInCountry: build.query({
			query: ({ limit, offset, sortItem, sortDir, search, countryId }) => ({
				url: `find/cities/${countryId}`,
				params: {
					limit,
					offset,
					sortItem,
					sortDir,
					search,
				},
			}),
			transformResponse: (response) => ({
				total: response.payload.total,
				items: response.payload.items,
			}),
			providesTags: ["FindCitiesInCountry"],
		}),
	}),
});

export const {
	useFindCountriesQuery,
	useLazyFindCountriesQuery,
	useFindCitiesInCountryQuery,
	useLazyFindCitiesInCountryQuery,
} = locationsAPI;

export default locationsAPI;
