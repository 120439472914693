import React, {Suspense} from "react";

/* Router */
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AppRouter from "router";

/* Components */
import SplashScreen from "components/common/splash-screen";

/* Redux */
import { useSelector, useDispatch } from "react-redux";
import { toggleLoading } from "redux/app.slice";

/* Contexts */
import { AuthProvider } from "@acromove/authentication/context/jwt";

import {useLazyFindNotificationsQuery} from "redux/notifications/notification.api"
import routesSource from "router/router";

const publicRotes = routesSource.routerElements.filter(route => !route.protected).map(r => r.path)

const App = () => {
	const { loading } = useSelector((state) => state.app);
	const [fetchNotifications] = useLazyFindNotificationsQuery({limit: 3, offset: 0})
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const handleAction = (action, status) => {
		if(status === "start"){
			dispatch(toggleLoading(true))
		} else if (status.startsWith("end")){
			dispatch(toggleLoading(false))
		}
		if(action === "getMe" && status === "end:error"){
			if(!publicRotes.includes(location.pathname)){
				navigate("/")
			}
		}
	}

	return (
		<AuthProvider
			services={[fetchNotifications]}
			onAction={handleAction}
			>
			<Suspense fallback={<SplashScreen />}>
				{loading ? (
					<SplashScreen />
				) : (
					<AppRouter>
						<Outlet />
					</AppRouter>
				)}
			</Suspense>
		</AuthProvider>
	);
};

export default App;
