import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* MUI */
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Checkbox, Tooltip, Typography, Box, Button } from "@mui/material";

/* Components */
import SiteMainInfo from "./forms/main";
import SpacesInfo from "./spaces-info";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SpaceCard from "./space-card";
import Section from "components/layouts/dashboard/ui/section";
import Form from "@acromove/components/form";

/* Utils */
import sh from "@acromove/utils/styles-helpers";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@acromove/utils/validators";
import useFormNotices from "@acromove/hooks/use-form-warnings-parser";
import spaceSchema from "./schemas/general-info-draft.schema";
import { useNavigate } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";


const spaceDefaultValue = {
	name: "",
	type: "open",
	type_desc: "",
	location: "parking",
	location_desc: "",
	access_type: "stairs",
	access_type_desc: "",
	images: [],
	firefight_equipment: {
		co2: false,
		water_jets: false,
		dust: false,
		none: false,
		other: false,
	},
	security_features: {
		private_security: false,
		fire_alarm: false,
		smoke_sensor: false,
		intruder_alarm: false,
		pir_sensors: false,
		magnetic_locks: false,
		other: false,
	},
	environmental: {
		windows_area: "",
		doors_area: "",
		air_vents_area: "",
		ac_capacity: "",
		heater_capacity: "",
		temperature_min: "",
		temperature_max: "",
		temperature_mean: "",
	},
	networks: [],
	cages: [],
	power_sources: [],
};

const SitesCreateForm = (props) => {
	const { data, submitSite, id, applySite, loading } = props;
	const [required, setRequired] = useState(false);
	const [apply, setApply] = useState(true);
	const { t } = useTranslation("sites_create");
	const navigate = useNavigate();
	const schema = yup.object({
		name: yup.string().required(t("sites.name.required")),
		spaces: yup.array().of(spaceSchema(t)),
	});
	const siteForm = useForm({
		resolver: yupResolver(schema),
		defaultValues: data || {
			name: "",
			demographic: "",
			latitude: 37.983760833740234,
			longitude: 23.727840423583984,
			country_id: "",
			country: null,
			city_id: "",
			city: null,
			spaces: [],
			$removedImages: [],
			images: [],
		},
	});

	const {
		fields: spaces,
		append,
		remove,
		update,
	} = useFieldArray({ control: siteForm.control, name: "spaces", keyName: "uid" });

	const findCurrentSpaceIndx = (uid) => spaces.findIndex((s) => s.uid === uid);

	const notices = useFormNotices(siteForm, {
		t,
		initialPath: "sites",
	});

	const sendDraft = async (values) => {
		const result = await submitSite({
			...values,
			country_id: values?.country_id?.id,
			city_id: values?.city_id?.id,
		});
		console.log(result);
		if (result?.data) {
			navigate(`/sites/view/${result.data.id}-${result.data.name}`);
			setApply(true);
		}
	};

	const sendApply = async () => {
		await applySite({ id });
	};

	const applyValidation = async () => {
		const readyForm = await siteForm.trigger();
		if (readyForm) {
			siteForm.handleSubmit(sendApply)();
		}
	};

	const draftValidation = async () => {
		const readyForm = await siteForm.trigger();
		if (readyForm) {
			siteForm.handleSubmit(sendDraft)();
		}
	};

	const openSpace = (uid) => {
		if (!uid) {
			append({ ...spaceDefaultValue, assets_address: uuidV4(), dialogOpen: true, newEntry: true });
		} else {
			const spaceIndex = findCurrentSpaceIndx(uid);
			siteForm.setValue(`spaces[${spaceIndex}].dialogOpen`, true);
		}
	};

	useEffect(() => {
		if (siteForm.formState.isDirty) {
			setApply(false);
		}
	}, [siteForm.formState]);

	useEffect(() => {
		if (data) {
			siteForm.reset(data);
			setApply(true);
		}
	}, [data]);

	return (
		<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
			<Form name="sites" mode="add" i18n="sites_create" form={siteForm}>
				<Box component="form" sx={[{ width: "100%" }, sh.flex("column", "flex-start", "flex-end")]}>
					<SiteMainInfo notices={notices} form={siteForm}>
						{spaces.map((space, index) => (
							<SpacesInfo spaceIndx={index} space={space} update={update} remove={() => remove(index)} />
						))}
					</SiteMainInfo>
					<Section
						title={t("section_titles.spaces.label")}
						action={
							<Button variant="contained" onClick={() => openSpace()}>
								<AddIcon sx={{ mr: 1 }} />
								{t("add_space")}
							</Button>
						}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 2,
								p: 2,
								backgroundColor: "background.gray",
								borderRadius: "8px",
							}}
						>
							{spaces.length ? (
								spaces.map((space, index) => (
									<SpaceCard form={siteForm} space={space} spaceIndx={index} openSpace={openSpace} />
								))
							) : notices?.spaces ? (
								<Alert sx={{ m: 1 }} severity="error">
									{notices.spaces.join(" & ")}
								</Alert>
							) : (
								t("no_spaces")
							)}
						</Box>
					</Section>
				</Box>
			</Form>
			<Box sx={{ display: "flex", alignSelf: "flex-end" }}>
				<LoadingButton
					disabled={!siteForm.formState.isDirty}
					loading={loading}
					variant="contained"
					onClick={draftValidation}
				>
					{t("draft")}
				</LoadingButton>
			</Box>
			{id && (
				<Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "flex-start",
						}}
					>
						<Checkbox checked={required} onChange={() => setRequired(!required)} />
						<Typography>{t("license")}</Typography>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<LoadingButton
							loading={loading}
							variant="contained"
							disabled={!(required && apply)}
							onClick={applyValidation}
						>
							{t("apply")}
						</LoadingButton>
						<Tooltip sx={{ ml: 2, cursor: "pointer" }} title={t("apply_tooltip")}>
							<HelpOutlineIcon color="primary" />
						</Tooltip>
					</Box>
				</Box>
			)}
		</Box>
	);
};

SitesCreateForm.propTypes = {
	data: PropTypes.shape({
		spaces: PropTypes.arrayOf(PropTypes.shape({})),
		notices: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	submitSite: PropTypes.func.isRequired,
	applySite: PropTypes.func,
	id: PropTypes.number,
	loading: PropTypes.bool.isRequired,
};

SitesCreateForm.defaultProps = {
	data: null,
	applySite: () => {},
	id: null,
};

export default SitesCreateForm;
