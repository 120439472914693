/* eslint-disable */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "@acromove/utils/helpers";

const themeAPI = createApi({
    reducerPath: "theme/api",
    baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("")),
    keepUnusedDataFor: 30,
    endpoints: build => ({
        getTheme: build.query({
            query: () => ({
                url: "theme",
            }),
            transformResponse: (response) => response.payload,
        }),
    })
})


export const {
    useGetThemeQuery,
    useLazyGetThemeQuery
} = themeAPI

export default themeAPI