import React, { useState, useEffect } from "react";

/* MUI */
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Common Components */
import TreeViewProvider from "@acromove/components/tree-view";
import Switcher from "@acromove/components/switcher";
import DataGridTable from "@acromove/components/data-grid";
import DataGridProvider from "@acromove/components/data-grid/data-grid-provider";
import GroupDetails from "components/content/clients/group-details";

/* Utils */
import { useTranslation } from "react-i18next";
import useItemsTranslation from "@acromove/hooks/use-items-translation";
import { groupMain } from "components/table-columns/clients/groups";
import useAuth from "@acromove/authentication/hooks/use-auth";

/* Redux */
import {
	useLazyFindChildGroupsQuery,
	useFindGroupsQuery,
	useFindChildGroupsQuery,
	useFindOneGroupQuery,
	useEditGroupMutation,
} from "redux/clients/clients.api";

const ClientGroupsContainer = () => {
	const [view, setView] = useState("tree");
	const [tableKey, setTableKey] = useState("root");
	const { t } = useTranslation("clients_groups");
	const itemsTranslator = useItemsTranslation("clients_groups");
	const auth = useAuth();
	const authKey = auth.scope._key;
	const defineKey = tableKey === "root" ? authKey : tableKey;

	const { data, refetch, isLoading } = useFindOneGroupQuery({ key: defineKey });
	const [editGroup, { isLoading: editLoading, isSuccess: editSuccess }] = useEditGroupMutation({ key: defineKey });

	const handleChange = (event) => {
		setView(event.target.value);
	};

	const onSelect = (key) => {
		setTableKey(key);
	};

	const onGroupEditSubmit = async (value) => {
		await editGroup({ value, Id: defineKey });
	};

	useEffect(() => {
		refetch();
	}, [tableKey, defineKey, data]);

	return (
		<Container loading={isLoading} skeleton={["section:2"]}>
			<Container.Header title={t("title")}>
				<Box component="form" sx={{ display: "flex", alignSelf: "flex-end", flexDirection: "row", gap: 3 }}>
					<Select
						sx={{ minWidth: "200px", color: "black" }}
						size="small"
						value={view}
						onChange={handleChange}
					>
						<MenuItem value="table">{t("view_table")}</MenuItem>
						<MenuItem value="tree">{t("view_tree")}</MenuItem>
					</Select>
					{/* <ProtectedComponent slug="groups:add">
						<Button
							sx={{ lineHeight: 0, minWidth: "200px" }}
							variant="contained"
							startIcon={<AddIcon />}
							onClick={() => navigate("/clients/groups/create")}
						>
							{t("add_group")}
						</Button>
					</ProtectedComponent> */}
				</Box>
			</Container.Header>

			<Switcher value={view}>
				<Switcher.Item name="table">
					<DataGridProvider useHook={useFindGroupsQuery} hookProps={{ key: tableKey }} initSort="name">
						<DataGridTable
							collapsible
							search
							title={t("title")}
							columns={itemsTranslator(groupMain, "table", "field", "headerName")}
						/>
					</DataGridProvider>
				</Switcher.Item>

				<Switcher.Item name="tree">
					<Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", width: "100%" }}>
						<TreeViewProvider
							onSelect={onSelect}
							name={auth.scope.name}
							sx={{ height: 500, flexGrow: 1, minWidth: 400, maxWidth: 400, overflowY: "auto" }}
							formateData={(item) => ({ name: item.name, id: item._key })}
							useLazyLoadChild={useLazyFindChildGroupsQuery}
						/>
						<Box sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", width: "100%" }}>
							<GroupDetails
								mode="findOne"
								submit={onGroupEditSubmit}
								data={data}
								groupKey={tableKey}
								isLoading={editLoading}
								isSuccess={editSuccess}
								detailsButton
							/>

							<DataGridProvider
								useHook={useFindChildGroupsQuery}
								hookProps={{ key: tableKey }}
								initSort="name"
							>
								<DataGridTable
									collapsible
									search
									title={t("title")}
									columns={itemsTranslator(groupMain, "table", "field", "headerName")}
								/>
							</DataGridProvider>
						</Box>
					</Box>
				</Switcher.Item>
			</Switcher>
		</Container>
	);
};

export default ClientGroupsContainer;
