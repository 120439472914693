import React, { useContext } from "react";
import PropTypes from "prop-types";
import ApexChart from "react-apexcharts";
import { ChartContext } from "./provider";

/**
 * Chart component.
 *
 * @type {React.FC<Props>}
 * @see {@link  https://apexcharts.com/docs/series/} for more information.
 * @returns {React.ReactElement} Chart Component.
 */
const Chart = ({ type, height, width, options: extraOptions }) => {
	const { options, series, selectedSeries, chartRef } = useContext(ChartContext);
	return (
		<ApexChart
			ref={chartRef}
			width={width || "100%"}
			height={height}
			options={{...options, ...extraOptions}}
			series={series.filter((item) => selectedSeries.includes(item.name))}
			type={type}
		/>
	);
};

/**
 * Chart Properties.
 *
 * @typedef {object} Props
 * @property {string} type - One of ApexChart Types. {@link https://apexcharts.com/docs/chart-types/line-chart/} for more inormation.
 * @property {number | string | null} [height = "null"] - Prop used to change Chart Toolbar Wrapper CSS properties.
 * @property {number | string | null} [width = "null"] - Prop used to change Chart Toolbar Wrapper CSS properties.
 * @property {object} [options = {}] - Apex Chart Options of Chart.
 */

Chart.propTypes = {
	type: PropTypes.string.isRequired,
	height: PropTypes.oneOfType([PropTypes.number,  PropTypes.string, PropTypes.oneOf([null])]),
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
	options: PropTypes.shape({})
};

Chart.defaultProps = {
	height: null,
	width: null,
	options: {}
};

export default Chart;
