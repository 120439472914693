import React, { useContext } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import useAuth from "@acromove/authentication/hooks/use-auth";
import formContext from "@acromove/components/form/contexts/form";
import fieldContext from "./contexts/fields";
import helpers from "general/helpers";

const FormField = (props) => {
	const { name, children } = props;
	const auth = useAuth();
	const formCTX = useContext(formContext);
	const { t, i18n } = useTranslation(formCTX.i18n);
	const baseI18nPath = `${formCTX?.localeName || formCTX.name}.${helpers.sliceIndexes(
		name
	)}`;
	const fieldRules = auth.findFiledRule(
		formCTX.name,
		formCTX.fieldNamePrefix ? `${formCTX.fieldNamePrefix}.${name}` : name,
		formCTX.mode
	);
	if (!formCTX) return null;
	if (!fieldRules.includes("field_view")) return null;
	const isFiledDisabled = {
		status: fieldRules.includes("field_edit") ? formCTX.disabled : true,
		reason: fieldRules.includes("field_edit") ? "form" : "acl",
	};

	return (
		<fieldContext.Provider
			value={{
				name,
				disabled: isFiledDisabled,
				control: formCTX.form.control,
				label: i18n.exists(`${formCTX.i18n}:${baseI18nPath}.label`)
					? t(`${baseI18nPath}.label`)
					: null,
				placeholder: i18n.exists(
					`${formCTX.i18n}:${baseI18nPath}.placeholder`
				)
					? t(`${baseI18nPath}.placeholder`)
					: null,
				helperText: i18n.exists(
					`${formCTX.i18n}:${baseI18nPath}.helperText`
				)
					? t(`${baseI18nPath}.helperText`)
					: null,
			}}
		>
			{children}
		</fieldContext.Provider>
	);
};

FormField.propTypes = {
	name: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default FormField;
