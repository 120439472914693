import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const assetsAPI = createApi({
    reducerPath: "assets/api",
    baseQuery: fetchBaseQuery({
		baseUrl: process.env?.REACT_APP_S3_ENDPOINT || "http://localhost:8887/bucket/acrotrace",
		prepareHeaders: (headers) => {
			const token = localStorage.getItem("accessToken")
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		},
		credentials: "include"
	}),
    endpoints: build => ({
        getInfo: build.query({
            query: ({ filename}) => ({
                url: `info/${filename}`
            }),
            transformResponse: (response) => response
        }),
    })
})



export const {
	useGetInfoQuery,
	useLazyGetInfoQuery
} = assetsAPI

export default assetsAPI;
