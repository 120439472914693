import {  isRejectedWithValue, isFulfilled } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

/**
 * Log a warning and show a toast!
 */
const rtkQueryErrorLogger = (i18n, ns, validationKey = "error.validation") => () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if(action?.payload?.data?.message){
            const entity = action.type.split("/")[0]
            const pathToLocale = `${ns}:${entity}.${action.payload.data.message}`;
            if(
                action.payload.data.message === validationKey &&
                action.payload.data.payload &&
                Array.isArray(action.payload.data.payload) &&
                action.payload.data.payload.length
                ){
                for (const field of action.payload.data.payload) {
                    toast.error(i18n.t(`${pathToLocale}.${field}`))
                }
            } else {
                try {
                    const message = i18n.t(`${ns}:${entity}.${action.payload.data.message}`)
                    if(message !== pathToLocale.split(":")[1]) {
                        toast.error(message)
                    }

                } catch (error) {
                    console.log(error);
                }
            }
        }

    }

    return next(action)
}

const rtkQuerySuccessLogger = (i18n, ns) => () => (next) => async (action) => {
    if(isFulfilled(action)){
        const entity = action.type.split("/")[0]
		const path = action.payload?.meta?.message  || "success"
        const pathToLocale = `${ns}:${entity}.${path}.${action.meta.arg.endpointName}`;
        const message = i18n.t(pathToLocale)
        if(pathToLocale.replace(`${ns}:`, "") !== message){
            console.log(pathToLocale.replace(`${ns}:`, ""), message);
            toast.success(message)
        }
    }

    return next(action)
}

export default {
    rtkQueryErrorLogger,
    rtkQuerySuccessLogger
}
