import React from "react";
import constants from "@acromove/constants";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import ClustersContainer from "containers/clusters";

const ClustersPage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={constants.ALL_ROLES}>
            <ClustersContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default ClustersPage;