import React, { useEffect } from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";

/* Common Components */
import Input from "@acromove/components/form/input";
import Select from "@acromove/components/form/select";
import Switch from "@acromove/components/form/switch";
import Dialog from "@acromove/components/dialog";
import SelectTimezone from "@acromove/components/form/select-timezone";
import Form from "@acromove/components/form";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@acromove/utils/validators";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

const UserFormDialog = (props) => {
	const { submit, data, isLoading, open, onClose, isSuccess } = props;
	const { t } = useTranslation("clients_groups_info");

	const schema = yup.object({
		firstName: yup.string().required(t("users.firstName.required")),
		lastName: yup.string().required(t("users.lastName.required")),
		role: yup.string().required(t("users.role.required")),
		username: yup.string().required(t("users.username.required")),
		timezone: yup.string().required(t("users.timezone.required")),
		notation: yup.string().required(t("users.notation.required")),
		password: yup.string().required(t("users.password.required")),
		confirmPassword: yup
			.string()
			.equalTo(yup.ref("password"), t("users.confirmPassword.equalTo"))
			.required(t("users.confirmPassword.required")),
	});

	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			role: data?.role,
			timezone: data?.timezone || moment.tz.guess(),
			notation: data?.notation,
			disabled: data?.disabled,
			username: "",
			password: "",
			confirmPassword: "",
		},
	});

	useEffect(() => {
		if (isSuccess) {
			onClose();
			form.reset();
		}
	}, [isSuccess]);

	const handleClose = (isAgree) => {
		if (isAgree) {
			form.handleSubmit(submit)();
			if (form.formState.errors) {
				open; //eslint-disable-line
			}
		} else onClose();
	};

	return (
		<Dialog loading={isLoading} maxWidth="md" fullWidth open={open} onClose={handleClose} title={t("users.title.label")}>
			<Form
				name="users"
				i18n="clients_groups_info"
				form={form}
				disabled={isLoading}
				sx={{ display: "flex", flexDirection: "row", mt: 6 }}
			>
				<Grid container gap={4} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
					<Form.Field name="firstName">
						<Grid item md={12}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Grid>
					</Form.Field>

					<Form.Field name="lastName">
						<Grid item md={12}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Grid>
					</Form.Field>

					<Form.Field name="username">
						<Grid item md={12}>
							<Input size="medium" sx={{ width: "100%" }} type="email" />
						</Grid>
					</Form.Field>

					<Form.Field name="password">
						<Grid item md={12}>
							<Input size="medium" sx={{ width: "100%" }} type="password" />
						</Grid>
					</Form.Field>

					<Form.Field name="confirmPassword">
						<Grid item md={12}>
							<Input size="medium" sx={{ width: "100%" }} type="password" />
						</Grid>
					</Form.Field>

					<Form.Field name="role">
						<Grid item md={12}>
							<Select
								size="medium"
								sx={{ width: "100%" }}
								options={[
									{ label: "Acromove Admin Owner", value: "acromove_admin:owner" },
									{ label: "Acromove Admin", value: "acromove_admin" },
									{ label: "Admin Owner", value: "admin:owner" },
									{ label: "Admin", value: "admin" },
									{ label: "Admin View Only", value: "admin:view_only" },
									{ label: "Accountant", value: "accountant" },
									{ label: "Accountant View Only", value: "accountant:view_only" },
									{ label: "User", value: "user" },
									{ label: "User View Only", value: "user:view_only" },
								]}
							/>
						</Grid>
					</Form.Field>

					<Form.Field name="timezone">
						<Grid item md={12}>
							<SelectTimezone size="medium" />
						</Grid>
					</Form.Field>

					<Form.Field name="notation">
						<Grid item md={12}>
							<Select
								size="medium"
								sx={{ width: "100%" }}
								options={[
									{ label: "Metric", value: "metric" },
									{ label: "Imperial", value: "imperial" },
								]}
							/>
						</Grid>
					</Form.Field>

					<Form.Field name="disabled">
						<Grid item md={12}>
							<Switch />
						</Grid>
					</Form.Field>
				</Grid>
			</Form>
		</Dialog>
	);
};

UserFormDialog.propTypes = {
	data: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		role: PropTypes.string,
		email: PropTypes.string,
		timezone: PropTypes.string,
		notation: PropTypes.string,
		disabled: PropTypes.bool,
		confirmPassword: PropTypes.string,
		password: PropTypes.string,
	}),
	submit: PropTypes.func,
	isLoading: PropTypes.bool,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	isSuccess: PropTypes.bool,
};

UserFormDialog.defaultProps = {
	data: {
		firstName: "",
		lastName: "",
		role: "user",
		email: "",
		timezone: "",
		notation: "metric",
		disabled: false,
		confirmPassword: "",
		password: "",
	},
	isLoading: false,
	submit: () => null,
	onClose: () => null,
	open: false,
	isSuccess: false,
};

export default UserFormDialog;
