import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "@acromove/utils/helpers";

const clientsAPI = createApi({
    reducerPath: "clients/api",
    baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("clients")),
    keepUnusedDataFor: 30,
    tagTypes: ["GroupsFind", "UsersFind", "FindOneUser", "FindUserGroups", "ChildGroupsFind", "ChildUsersFind", "FindUnassignUsers"],
    endpoints: build => ({

         /* Groups */
        findGroups: build.query({
            query: ({limit, offset, sortItem, sortDir, search}) => ({
                url: "groups/find",
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: (response) => ({
                total: response.payload.total,
                items: response.payload.items.map(item => ({
                    ...item,
                    id: item._id.split("/")[1],
                    url: `/clients/view/${item._id.split("/")[1]}-${item.name.replace(/ /g, "_")}`
                }))
            }),
            providesTags: ["GroupsFind"]
        }),

        createGroup: build.mutation({
            query: (data) => ({
               url: "groups/create",
               method: "POST",
               body: data
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["GroupsFind"]
        }),
        findChildGroups: build.query({
            query: ({limit, offset, sortItem, sortDir, search, key}) => ({
                url: key === "root" ? "groups/find" : `groups/${key}/groups/find`,
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: (response) => ({
                total: response.payload.total,
                items: response.payload.items.map(item => ({
                    ...item,
                    id: item._id.split("/")[1],
                    url: `/clients/view/${item._id.split("/")[1]}-${item.name.replace(/ /g, "_")}`
                })),
            }),
            providesTags: ["ChildGroupsFind"]
        }),

        createChildGroup: build.mutation({
            query: (data) => ({
                url: `groups/${data.Id}/groups/create`,
                method: "POST",
                body: data
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["GroupsFind", "ChildGroupsFind"]
        }),

        createChildUser: build.mutation({
            query: (data) => ({
                url: `groups/${data.Id}/users/create`,
                method: "POST",
                body: data
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["GroupsFind", "ChildUsersFind"]
        }),

        findOneGroup: build.query({
            query: ({key}) => ({
                url: `groups/find/${key}`
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["ChildUsersFind", "ChildGroupsFind", "GroupsFind", "FindUnassignUsers"]
        }),

        findChildUsers: build.query({
            query: ({limit, offset, sortItem, sortDir, search, key}) => ({
                url: `groups/${key}/users/find`,
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: (response) => ({
                total: response.payload.total,
                items: response.payload.items.map(item => ({
                    ...item,
                    id: item._id.split("/")[1],
                    url: `/clients/users/view/${item._id.split("/")[1]}-${item.username.replace(/ /g, "_")}`
                })),
            }),
            providesTags: ["ChildUsersFind"],
        }),

        findUnassignedUsers: build.query({
            query: ({limit, offset, sortItem, sortDir, search, key}) => ({
                url: `groups/unassign_users_${key}/users/find`,
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: (response) => ({
                total: response.payload.total,
                items: response.payload.items.map(item => ({
                    ...item,
                    id: item._id.split("/")[1],
                    url: `/clients/users/view/${item._id.split("/")[1]}-${item.username.replace(/ /g, "_")}`
                })),
            }),
            providesTags: ["FindUnassignUsers"],
        }),

        editGroup: build.mutation({
            query: (data) => {
                const formData = new FormData();
                for (const key in data.value) {
                    formData.append(key, data.value[key])
                    console.log(formData)
                }
                return {
                    url: `groups/edit/${data.Id}`,
                    method: "PATCH",
                    body: formData
                }
            },
            transformResponse: (response) => response.payload,
            invalidatesTags: ["GroupsFind"]
        }),

        /* Users */
        findUsers: build.query({
            query: ({limit, offset, sortItem, sortDir, search}) => ({
                url: "users/find",
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: (response) => ({
                total: response.payload.total,
                items: response.payload.items.map(item => ({
                    ...item,
                    id: item._id.split("/")[1],
                    url: `/clients/users/view/${item._id.split("/")[1]}-${item.username.replace(/ /g, "_")}`
                }))
                
            }),
            providesTags: ["UsersFind"]
        }),

        assignManyUsers: build.mutation({
            query: (data) => ({
                url: `users/assign-to/group/${data.groupKey}`,
                method: "PATCH",
                body: data
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["GroupsFind", "UsersFind", "FindUnassignUsers"]
        }),

        unassignManyUsers: build.mutation({
            query: (data) => ({
                url: `users/unassign-from/group/${data.groupKey}`,
                method: "DELETE",
                body: data
            }), 
            transformResponse: (response) => response.payload,
            invalidatesTags: ["GroupsFind", "UsersFind", "FindUnassignUsers"]
        }),

        assignOneUser: build.mutation({
            query: (data) => ({
                url: `users/assign-to/group/${data.groupKey}/${data.userKey}`,
                method: "PATCH",
                body: data
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: [ "FindUnassignUsers", "ChildUsersFind"]
        }),

        unassignOneUser: build.mutation({
            query: (data) => ({
                url: `users/unassign-from/group/${data.groupKey}/${data.userKey}`,
                method: "DELETE",
                body: data
            }), 
            transformResponse: (response) => response.payload,
            invalidatesTags: [ "FindUnassignUsers", "ChildUsersFind"]
        }),

        findOneUser: build.query({
            query: ({key}) => ({
                url: `users/find/${key}`
            }),
            transformResponse: (response) => response.payload,
            providesTags: ["FindOneUser"]
        }),

        editUser: build.mutation({
            query: (data) => {
                const formData = new FormData();
                for (const key in data.value) {
                    if (key === "device_notifications") {
                        formData.append(key, JSON.stringify(data.value[key]))
                        continue;
                    }
                    formData.append(key, data.value[key])
                }
                return {
                    url: `users/edit/${data.userKey}`,
                    method: "PATCH",
                    body: formData
                }
            },
            transformResponse: (response) => response.payload,
            invalidatesTags: ["UsersFind", "FindOneUser"]
        }),

        findUserGroups: build.query({
            query: ({limit, offset, sortItem, sortDir, search, key}) => ({
                url: `users/${key}/groups/find`,
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: (response) => ({
                total: response.payload.total,
                items: response.payload.items.map(item => ({
                    ...item,
                    id: item._key,
                    url: `/clients/view/${item._key}-${item.name.replace(/ /g, "_")}`
                }))
            }),
        }),
    })
})


export const {
    useFindGroupsQuery,
    useLazyFindGroupsQuery,
    useCreateGroupMutation,
    useFindChildGroupsQuery,
    useLazyFindChildGroupsQuery,
    useCreateChildGroupMutation,
    useCreateChildUserMutation,
    useFindOneGroupQuery,
    useLazyFindOneGroupQuery,
    useFindChildUsersQuery,
    useLazyFindChildUsersQuery,
    useEditGroupMutation,
    useFindUsersQuery,
    useLazyFindUsersQuery,
    useAssignManyUsersMutation,
    useUnassignManyUsersMutation,
    useAssignOneUserMutation,
    useUnassignOneUserMutation,
    useFindOneUserQuery,
    useLazyFindOneUserQuery,
    useEditUserMutation,
    useFindUserGroupsQuery,
    useLazyFindUserGroupsQuery,
    useFindUnassignedUsersQuery,
    useLazyFindUnassignedUsersQuery
} = clientsAPI

export default clientsAPI