import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/* Utils */
import sh from "../../utils/styles-helpers";

const positionOrderMap = {
	bottom: 2,
	top: 0,
};

/**
 * Simple Statistic component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Simple Statistic Component.
 */
const SimpleStat = (props) => {
	const { value, label, labelPosition, small, icon, textColor, fontWeight, fontSize, bordered, sx, sublabel } = props;
	return (
		<Box
			sx={{
				p: small ? 0.5 : 1.5,
				...sh.flex("column", "flex-start", "flex-start"),
				gap: small ? 0.5 : 1.5,
				border: bordered && "1px solid",
				borderColor: "background.gray",
				borderRadius: "8px",
				...sx,
			}}
		>
			<Typography
				color="text.secondary"
				fontWeight="500"
				fontSize={small ? 12 : 14}
				sx={{ order: positionOrderMap[labelPosition], opacity: small ? 0.5 : 1 }}
			>
				{label}
			</Typography>
			<Typography color={textColor} fontWeight={fontWeight} fontSize={fontSize}>
				{icon && <img src={icon} width={18} alt="icon" />}
				{value}
			</Typography>
			{sublabel && (
				<Typography color="text.grayLight" fontWeight="300" fontSize={12}>
					{sublabel}
				</Typography>
			)}
		</Box>
	);
};

/**
 * SimpleStat Properties.
 *
 * @typedef {object} Props
 * @property {string | number} [value = ""] - Value provided to component.
 * @property {string} [label = ""] - Label of the component.
 * @property {"top" | "bottom"} [labelPosition = "bottom"] - Defines Label position related to value.
 * @property {true | false} [small = "false"] - Property used to control size of container padding, gap and fontSize.
 * @property {true | false} [bordered = "false"] - Property that defines if component has border or not.
 * @property {React.ReactNode} [icon = ""] - Provides an icon to component.
 * @property {string} [textColor = "text.active"] - Color of the text.
 * @property {number} [fontWeight = 600]
 * @property {number} [fontSize = 32]
 * @property {object} [sx = {}] - Property to rewrite components Container CSS properties.
 * @property {string} [sublabel = ""] - Sublabel of the component. 
 */

SimpleStat.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.string,
	/**
	 * @type {"top"|"bottom"}
	 * @description Position of label
	 */
	labelPosition: PropTypes.oneOf(["top", "bottom"]),
	small: PropTypes.bool,
	bordered: PropTypes.bool,
	icon: PropTypes.node,
	textColor: PropTypes.string,
	fontWeight: PropTypes.number,
	fontSize: PropTypes.number,
	sx: PropTypes.shape({}),
	sublabel: PropTypes.string,
};

SimpleStat.defaultProps = {
	value: "",
	label: "",
	labelPosition: "bottom",
	small: false,
	bordered: false,
	icon: "",
	textColor: "text.active",
	fontWeight: 600,
	fontSize: 32,
	sx: {},
	sublabel: "",
};

export default SimpleStat;
