import React, { useEffect, useState } from "react";

/* MUI */
import Button from "@mui/material/Button";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CancelIcon from '@mui/icons-material/Cancel';

import Section from "components/layouts/dashboard/ui/section";
import Input from "@acromove/components/form/input";
import SelectTimezone from "@acromove/components/form/select-timezone";
import Checkbox from "@acromove/components/form/checkbox";
import AvatarUploader from "@acromove/components/form/avatar-uploader";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import sh from "@acromove/utils/styles-helpers";
import useAuth from "@acromove/authentication/hooks/use-auth";
import helpers from "general/helpers";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

/* Redux */
import { useDispatch } from "react-redux";
import { useUpdateMeMutation } from "@acromove/redux/auth/api";
import { setUser } from "@acromove/redux/auth/slice";

const Details = () => {
	const { user } = useAuth();
	const { t } = useTranslation("user_profile");
	const [updateMe, { isLoading, data: updatedData }] = useUpdateMeMutation();
	const [editMode, setEditMode] = useState(false);
	const dispatch = useDispatch();
	const schema = yup.object({
		firstName: yup.string().required(t("details.form.firstname.validation.required")),
		lastName: yup.string().required(t("details.form.lastname.validation.required")),
		timezone: yup.string().required(t("details.form.timezone.validation.required")),
	});

	const { control, handleSubmit, formState, reset } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			avatar: user?.avatar || null,

			username: user.username,

			firstName: user.firstName || "",
			lastName: user.lastName || "",

			device_notifications: {
				alarm: user.device_notifications.alarm,
				info: user.device_notifications.info,
				warning: user.device_notifications.warning,
			},

			role: user.role,

			timezone: user.timezone || moment.tz.guess(),
		},
	});

	const { errors } = formState;

	const onSubmit = async (values) => {
		await updateMe(values);
	};

	const handleCancelEdit = () => {
		if (!editMode) {
			setEditMode(true);
		} else if (editMode) {
			reset();
			setEditMode(false);
		}
	};

	const handleSaveEdit = () => {
		if (editMode) {
			handleSubmit(onSubmit)();
			if (!Object.keys(errors).length) {
				setEditMode(!editMode);
			}
			return;
		}
		setEditMode(!editMode);
	};

	useEffect(() => {
		if (updatedData) {
			dispatch(setUser(updatedData));
		}
	}, [updatedData]);

	return (
		<Section
			loading={isLoading}
			title={t("details.title")}
			action={
				<Button onClick={handleCancelEdit} startIcon={editMode ? <CancelIcon /> : <BorderColorIcon />}>
					{editMode ? t("btns.cancel") : t("btns.edit")}
				</Button>
			}
		>
			<Box component="form">
				<Grid container gap={4}>
					<Grid item md={6}>
						<AvatarUploader
							disabled={!editMode}
							control={control}
							name="avatar"
							size={64}
							fontSize={24}
							alt={helpers.getInitials(user.firstname, user.lastname)}
						/>
					</Grid>
					<Grid item md={8}>
						<Grid container gap={3}>
							<Grid item md={4}>
								<Input
									disabled={!editMode}
									sx={{ width: "100%" }}
									control={control}
									name="firstName"
									label={t("details.form.firstname.label")}
								/>
							</Grid>
							<Grid item md={4}>
								<Input
									disabled={!editMode}
									sx={{ width: "100%" }}
									control={control}
									name="lastName"
									label={t("details.form.lastname.label")}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item md={8}>
						<Grid container gap={3}>
							<Grid item md={4}>
								<Input
									disabled={!editMode}
									sx={{ width: "100%" }}
									control={control}
									name="username"
									label={t("details.form.email.label")}
								/>
							</Grid>
							<Grid item md={4}>
								<SelectTimezone
									disabled={!editMode}
									size="small"
									control={control}
									name="timezone"
									label={t("details.form.timezone.label")}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item md={8}>
						<Grid container gap={3}>
							<Grid item md={4}>
								<Input
									sx={{ width: "100%" }}
									disabled
									control={control}
									name="role"
									label={t("details.form.role.label")}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item md={6}>
						<Typography sx={[{ mr: "auto" }, sh.font("primary", 16, "semiBold")]}>
							{t("details.form.notif.label")}
						</Typography>
					</Grid>
					<Grid item md={6} sx={{ ml: 2 }}>
						<Grid container gap={3}>
							<Grid item md={2}>
								<Checkbox
									disabled={!editMode}
									color="success"
									control={control}
									name="device_notifications.alarm"
									label={t("details.form.notif_alarms.label")}
								/>
							</Grid>
							<Grid item md={2}>
								<Checkbox
									disabled={!editMode}
									color="success"
									control={control}
									name="device_notifications.warning"
									label={t("details.form.notif_warnings.label")}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item md={6} sx={{ ml: 2 }}>
						<Grid container gap={3}>
							<Grid item md={4}>
								<Checkbox
									disabled={!editMode}
									color="success"
									control={control}
									name="device_notifications.info"
									label={t("details.form.notif_info.label")}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{editMode && (
					<Button
						startIcon={<SaveAsIcon />}
						type="submit"
						variant="contained"
						onClick={handleSaveEdit}
						sx={{ marginLeft: "auto", mt: 1, display: "flex", alignSelf: "flex-end" }}
					>
						{t("btns.save")}
					</Button>
				)}
			</Box>
		</Section>
	);
};

export default Details;
