import React from "react";
import constants from "@acromove/constants";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";
import SitesViewEditContainer from "containers/sites/admin/view-edit";

const  SitesViewPage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={constants.ALL_ROLES}>
            <SitesViewEditContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default  SitesViewPage;
