import React, { useState } from "react";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";
import DataGridTable from "@acromove/components/data-grid";
import DataGridProvider from "@acromove/components/data-grid/data-grid-provider";

/* Components */
import ProtectedComponent from "@acromove/authentication/components/protected/component";
import { Button } from "@mui/material";
import { columns } from "components/table-columns/clusters";
import ClusterCreateDialog from "components/content/clusters/cluster-dialog";

/* Redux */
import { useFindClustersQuery } from "redux/clusters/clusters.api";

/* Utils */
import { useTranslation } from "react-i18next";
import useItemsTranslation from "@acromove/hooks/use-items-translation";

const ClustersContainer = () => {
	const [open, setOpen] = useState(false)
	const itemsTranslator = useItemsTranslation("clusters");
	const { t } = useTranslation("clusters");

	return (
		<>
			<ClusterCreateDialog open={open} setOpen={setOpen}/>
			<Container>
				<DataGridProvider useHook={useFindClustersQuery} initSort="label">
					<DataGridTable
						title="Clusters"
						columns={itemsTranslator(columns, "table", "field", "headerName")}
						action={
							<ProtectedComponent slug="clusters:add">
								<Button variant="contained" onClick={() => setOpen(true)}>
									{t("add_cluster")}
								</Button>
							</ProtectedComponent>
						}
					/>
				</DataGridProvider>
			</Container>
		</>
	);
};

export default ClustersContainer;
