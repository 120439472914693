import React from "react";
import PropTypes from "prop-types";
import { FileUploader } from "react-drag-drop-files";
import { makeStyles } from "@mui/styles";
import { Box, List, Typography } from "@mui/material";
import DragDropFilePreview from "./preview";

const useStyles = makeStyles((theme) => ({
	dropZone: {
		borderColor: `${theme.palette.text.secondary}!important`,
		width: "100%",
		maxWidth: "100% !important",
		display: "flex",
		flexDirection: "column",
		minHeight: "300px",
		alignItems: "center !important",
		justifyContent: "center",
		"& svg": {
			width: "50px !important",
			minHeight: "50px !important",
			fill: theme.palette.text.active,
			"& path": {
				fill: theme.palette.text.active,
			},
		},
		"& div": {
			flexGrow: 0,
			justifyContent: "center",
			flexDirection: "column",
			alignItems: "center",
			fontSize: "20px",
		},
	},
}));

const DragDrop = (props) => {
	const { fileTypes, onChange, files, multiple, previewFiles, onRemove } = props;
	const classes = useStyles();

	return (
		<>
			<List sx={{ display: "flex", flexDirection: "row", gap: 1, flexWrap: "wrap", alignItems: "center" }}>
				{previewFiles || files
					? [...previewFiles, ...files].map((item) => <DragDropFilePreview file={item} onRemove={onRemove} />)
					: null}
			</List>

			<Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
				<FileUploader
					handleChange={onChange}
					classes={classes.dropZone}
					name="file"
					multiple={multiple}
					types={fileTypes}
				/>
				{files.length ? (
					<Typography sx={{ fontSize: 18, mt: 1 }}>
						{files?.length >= 1 ? `Files: ${files?.map((f) => f.name).join(", ")}` : `File name: ${files.name}`}
					</Typography>
				) : (
					""
				)}
			</Box>
		</>
	);
};

DragDrop.propTypes = {
	fileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func,
	files: PropTypes.shape({
		name: PropTypes.string,
		length: PropTypes.number,
		map: PropTypes.func,
	}),
	previewFiles: PropTypes.arrayOf(PropTypes.shape({})),
	multiple: PropTypes.bool,
};

DragDrop.defaultProps = {
	files: [],
	multiple: false,
	previewFiles: [],
	onRemove: () => {},
};

export default DragDrop;
