import React from "react";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Content Components */
import SitesCreateForm from "components/content/sites/site-create";

/* Utils */
import { useTranslation } from "react-i18next";
import { useCreateSiteDraftMutation } from "redux/sites/sites.api";
import useWithAssetsMutation from "@acromove/hooks/use-with-assets-mutation";

const SitesCreateContainer = () => {
	const { t } = useTranslation("sites_create");

	const [createDraft, { isLoading: isCreateLoading }] = useWithAssetsMutation(useCreateSiteDraftMutation, [
		{
			entity: "site",
			path: "images",
		},
		{
			entity: "space",
			path: "spaces[].images",
		},
		{
			entity: "cage",
			path: "spaces[].cages[].images",
		},
		{
			entity: "power_source",
			path: "spaces[].power_sources[].images",
		},
		{
			entity: "network",
			path: "spaces[].networks[].images",
		},
	]);

	return (
		<Container>
			<Container.Header title={t("title")} back={-1} />
			<SitesCreateForm submitSite={createDraft} loading={isCreateLoading} />
		</Container>
	);
};

export default SitesCreateContainer;
